/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  LicensingBill,
  LicensingBillFromJSON,
  LicensingBillToJSON,
  LicensingPayment,
  LicensingPaymentFromJSON,
  LicensingPaymentToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchBillingResponseLicensing
 */
export interface FetchBillingResponseLicensing {
  /**
   *
   * @type {Array<LicensingBill>}
   * @memberof FetchBillingResponseLicensing
   */
  programs?: Array<LicensingBill>
  /**
   *
   * @type {Array<LicensingPayment>}
   * @memberof FetchBillingResponseLicensing
   */
  history?: Array<LicensingPayment>
}

export function FetchBillingResponseLicensingFromJSON(
  json: any
): FetchBillingResponseLicensing {
  return FetchBillingResponseLicensingFromJSONTyped(json, false)
}

export function FetchBillingResponseLicensingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchBillingResponseLicensing {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programs: !exists(json, 'programs')
      ? undefined
      : (json['programs'] as Array<any>).map(LicensingBillFromJSON),
    history: !exists(json, 'history')
      ? undefined
      : (json['history'] as Array<any>).map(LicensingPaymentFromJSON),
  }
}

export function FetchBillingResponseLicensingToJSON(
  value?: FetchBillingResponseLicensing | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programs:
      value.programs === undefined
        ? undefined
        : (value.programs as Array<any>).map(LicensingBillToJSON),
    history:
      value.history === undefined
        ? undefined
        : (value.history as Array<any>).map(LicensingPaymentToJSON),
  }
}
