import React from 'react'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from '../Card/CardFormHeader'
import { useTranslation } from 'react-i18next'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { FormDivider } from '../Elements/FormDivider'
import { Box, TextField } from '@mui/material'
import CheckboxControl from '../Checkboxes/CheckboxControl'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { useAccountContext } from '../Context/AccountContext'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { useTheme } from '@mui/material/styles'
import { Transcript } from '../../swagger'
import studentKeyToStudentIdForTranscript from '../../utils/studentKeyToStudentIdForTranscript'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { dateToDashStringReinterpretedAsLocal } from '../../utils/dateUtility'

export interface StudentInformationHeaderProps {
  isFieldDisabled?: boolean
  isSameAddress?: boolean
  handleSameAddress: (value: boolean) => void
}

export const StudentInformationHeader: React.FC<
  StudentInformationHeaderProps
> = ({ isFieldDisabled, isSameAddress, handleSameAddress }) => {
  const { t } = useTranslation()
  const showOnDesktop = useShowOnDesktop()
  const {
    updateTranscriptDetails,
    transcriptDetails,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()
  const { students } = useAccountContext()
  const theme = useTheme()

  const textFieldOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target

    // Database limits student email field to 320 characters
    if (name === 'studentEmail' && value.length > 320) {
      return
    }

    updateFormValidity({
      ...transcriptValidity,
      [name]: { input: true, email: true },
    })

    updateTranscriptDetails({
      ...transcriptDetails,
      [name]: value,
    })
  }

  const dateFieldOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value?: string
  ) => {
    /*
     * Datefield should be disabled if there is
     * no studentKey, this should never be true. Thank you typescript XD.
     */
    if (!transcriptDetails.student?.studentKey) return

    updateTranscriptDetails({
      ...transcriptDetails,
      student: {
        ...transcriptDetails.student,
        dateOfBirth: value ? new Date(value) : undefined,
      },
    })

    updateFormValidity({
      ...transcriptValidity,
      dateOfBirth: {
        input: true,
      },
    })
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  return (
    <section aria-labelledby="student-information-header">
      <CardFormHeader
        header={
          <Header
            id="student-information-header"
            component={'h2'}
            variant={HeaderVariant.Card}
            headerName={t(
              'Transcripts.StudentInformationHeader.Header.StudentInformation',
              'Student Information'
            )}
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.25),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: showOnDesktop ? '' : theme.spacing(2.5),
          }}
        >
          <DropDown
            id="studentName"
            menuOptions={students.map((student) => {
              return { name: student.firstName, id: student.studentKey }
            })}
            required
            helperText={
              !transcriptValidity.studentKey.input
                ? t(
                    'Transcripts.StudentInformationHeader.Error.StudentKey',
                    'Student is required.'
                  )
                : undefined
            }
            error={!transcriptValidity.studentKey.input}
            handleSelection={(
              selection: string,
              _: string,
              selectionId: string | number
            ) => {
              updateFormValidity({
                ...transcriptValidity,
                studentKey: { input: true },
              })
              updateTranscriptDetails({
                ...transcriptDetails,
                /**
                 * We update both a CreateTranscript and Transcript
                 * student selection.
                 */
                studentKey: +selectionId,
                student: {
                  firstName: selection,
                  studentKey: +selectionId,
                },
              })
            }}
            disabled={isFieldDisabled}
            label={t(
              'Transcripts.StudentInformationHeader.Field.StudentName',
              'Student Name'
            )}
            variant={DropDownVariant.FormField}
            fullWidth
            /**
             * It will always be as a `Transcript` even though we're not
             * providing the name to the createTranscript call. We can utilize
             * the display name & key on selection.
             */
            value={(transcriptDetails as Transcript).student?.firstName ?? ''}
            formControlProps={{
              marginInline: theme.spacing(2),
            }}
            cssProps={{
              padding: 0,
              height: 'auto',
              width: '100%',
              textAlign: 'left',
            }}
          />
          <TextField
            id="studentID"
            name="studentID"
            label={t(
              'Transcripts.StudentInformationHeader.Field.StudentID',
              'Student ID'
            )}
            variant="filled"
            sx={{
              width: '100%',
            }}
            value={studentKeyToStudentIdForTranscript(
              transcriptDetails.student?.studentKey ??
                transcriptDetails.studentKey
            )}
            fullWidth
            disabled
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: showOnDesktop ? '' : theme.spacing(2.5),
          }}
        >
          <DateField
            sx={{
              marginLeft: theme.spacing(2),
            }}
            id="dateOfBirthEntry"
            inputProps={{
              'aria-label': t(
                'Transcripts.StudentInformationHeader.Field.DateOfBirth',
                'Date of Birth Entry'
              ),
            }}
            disabled={isFieldDisabled || !transcriptDetails.student?.studentKey}
            variant="filled"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={
              transcriptDetails.student?.dateOfBirth
                ? dateToDashStringReinterpretedAsLocal(
                    transcriptDetails.student.dateOfBirth
                  )
                : ''
            }
            name="dateOfBirthEntry"
            onChangeForParent={dateFieldOnChange}
            helperTextForParent={t(
              'Transcripts.StudentInformationHeader.Error.DateOfBirthRequired',
              'Student Date of Birth is Required.'
            )}
            error={!transcriptValidity.dateOfBirth.input}
            dateVariant={DateFieldVariant.DateOfBirth}
            onKeyDown={onKeyDown}
          />
          <TextField
            id="studentEmail"
            name="studentEmail"
            label={t(
              'Transcripts.StudentInformationHeader.Field.Email',
              'Email'
            )}
            variant="filled"
            disabled={isFieldDisabled}
            sx={{
              width: '100%',
              marginLeft: theme.spacing(2),
            }}
            value={transcriptDetails.studentEmail ?? ''}
            onChange={textFieldOnChange}
            helperText={
              !transcriptValidity.studentEmail.email
                ? t(
                    'Transcripts.StudentInformationHeader.Error.StudentEmailNotValid',
                    'Student Email must be a valid email.'
                  )
                : !transcriptValidity.studentEmail.input
                ? t(
                    'Transcripts.StudentInformationHeader.Error.StudentEmailRequired',
                    'Student Email is required.'
                  )
                : null
            }
            FormHelperTextProps={{
              disabled: false,
            }}
            fullWidth
            error={
              !transcriptValidity.studentEmail.input ||
              !transcriptValidity.studentEmail.email
            }
          />
        </Box>
      </Box>
      <FormDivider />
      <CheckboxControl
        disabled={isFieldDisabled}
        label={t(
          'Transcripts.StudentInformationHeader.Checkbox.SameAs',
          'Student Address same as School Address'
        )}
        checkboxValue={!!isSameAddress}
        OnChange={(checked) => handleSameAddress(checked)}
        name={'same-address-as-school'}
      />
    </section>
  )
}

export default StudentInformationHeader
