import React, { PropsWithChildren, useContext, useState } from 'react'
import SnackbarAlert from '../Alerts/SnackbarAlert'
import { useMountEffect } from '../../hooks/useMountEffect'
import { Router } from '@remix-run/router'

export enum SnackbarSeverity {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

/**
 * Snackbar Context default value including
 *
 * open: Boolean value that controls whether the snackbar is shown or hidden (open/close)
 * message: String message to display on the snackbar in an Alert
 * severity: type SnackbarSeverity State of the Alert. Also determines the styling for Success, Error, Warning, and Info.
 *
 * setSnackbarState: setter method for `open`
 * setSnackbarMessage: setter method for `message`
 * setSnackbarSeverity: setter method for `severity`
 *
 * handleClose: method called when the snackbar closes via autoHideDuration
 */
const defaultSnackbarContextValue = {
  open: false,
  message: '',
  severity: SnackbarSeverity.Success,
  setSnackbarState: (state: boolean) => {
    console.warn(
      `The default value of SnackbarContext.setSnackbarState was called with state=${state}. Did you forget to install a SnackbarProvider?`
    )
  },
  setSnackbarMessage: (state: string) => {
    console.warn(
      `The default value of SnackbarContext.setSnackbarMessage was called with state=${state}. Did you forget to install a SnackbarProvider?`
    )
  },
  setSnackbarSeverity: (state: SnackbarSeverity) => {
    console.warn(
      `The default value of SnackbarContext.setSnackbarSeverity was called with state=${state}. Did you forget to install a SnackbarProvider?`
    )
  },
  handleClose: () => {
    console.warn(
      `The default value of SnackbarContext.handleClose was called. Did you forget to install a SnackbarProvider?`
    )
  },
}

/**
 *  Context built by the default values. If the useSnackbarContext is called
 *  without a provider wrapping it the default values will be used and warnings
 *  will show for unimplemented methods.
 *
 */
const SnackbarContext = React.createContext(defaultSnackbarContextValue)
export const useSnackbarContext = (): typeof defaultSnackbarContextValue => {
  return useContext(SnackbarContext)
}

export type TestSnackbarContextConfig = Partial<
  typeof defaultSnackbarContextValue
>

interface SnackbarContextProps extends PropsWithChildren {
  /** Used to subscribe to the navigation and handleClose each time we do navigate */
  router?: Router
  testConfig?: TestSnackbarContextConfig
}

/**
 * Provider for the Snackbar context.
 *
 * Implements and provides an implemented context value for use within the provider.
 *
 * @param param0 children
 * @returns SnackbarContext.Provider JSX wrapping the children
 */
export const SnackbarProvider: React.FC<SnackbarContextProps> = ({
  router,
  testConfig,
  children,
}) => {
  const [open, setOpen] = useState(false)

  const setSnackbarState = React.useCallback((state: boolean) => {
    setOpen(state)
  }, [])

  const [message, setMessage] = useState<string>('')

  const setSnackbarMessage = React.useCallback((msg: string) => {
    setMessage(msg)
  }, [])

  const [severity, setSeverity] = useState(SnackbarSeverity.Success)

  const setSnackbarSeverity = React.useCallback((sev: SnackbarSeverity) => {
    setSeverity(sev)
  }, [])

  const handleClose = () => {
    // Since we are within context scope, use the setter. Setting anything other than open is visually inconsistent.
    setOpen(false)
  }

  const value = {
    open,
    message,
    severity,
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
    handleClose,
    ...testConfig,
  }

  /**
   * When the location changes, do something https://github.com/remix-run/history/blob/dev/docs/api-reference.md#history.listen
   *
   * In this case, listen for a location change and close the snackbar
   *
   * An update as of 26 February 2024:
   * https://stackoverflow.com/questions/70646421/how-to-listen-for-route-change-in-react-router-dom-v6
   */
  useMountEffect(() => {
    router?.subscribe(() => {
      /**
       * For testing purposes, we're setting testConfig.handleClose first to know the
       * close method would call on navigation
       *
       */
      testConfig?.handleClose ? testConfig.handleClose() : handleClose()
    })
  })

  return (
    <SnackbarContext.Provider value={value}>
      <SnackbarAlert />
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
