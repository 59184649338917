/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  ProgramStatus,
  ProgramStatusFromJSON,
  ProgramStatusToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface ProgramUpdate
 */
export interface ProgramUpdate {
  /**
   * The unique identifier assigned by the system when a program is first created.
   * @type {number}
   * @memberof ProgramUpdate
   */
  programsKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof ProgramUpdate
   */
  type?: ProgramType
  /**
   *
   * @type {ProgramStatus}
   * @memberof ProgramUpdate
   */
  status: ProgramStatus
  /**
   * **DEPRECATED - Use semesterOneStartDate** The starting date for the program in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramUpdate
   */
  startDate?: Date
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramUpdate
   */
  semesterOneStartDate: Date
  /**
   * The starting date for the program's second semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramUpdate
   */
  semesterTwoStartDate: Date
  /**
   * The starting time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof ProgramUpdate
   */
  startTime: string
  /**
   * The ending time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof ProgramUpdate
   */
  endTime: string
  /**
   * The day of the week the program is available. Where 1 is Monday and 7 is Sunday
   * @type {number}
   * @memberof ProgramUpdate
   */
  dayOfTheWeek: number
  /**
   * Unique identifier of the actor for the Program Director
   * @type {number}
   * @memberof ProgramUpdate
   */
  directorActorId?: number
  /**
   * Unique identifier of the actor for the program's assigned tutor.
   * @type {number}
   * @memberof ProgramUpdate
   */
  tutorActorId?: number
  /**
   *
   * @type {Array<number>}
   * @memberof ProgramUpdate
   */
  tutorActorIds?: Array<number>
  /**
   * The maximum number of students allowed in a program.
   * @type {number}
   * @memberof ProgramUpdate
   */
  capacity?: number
  /**
   * **DEPRECATED** Program membership fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  membershipFee?: number
  /**
   * Program application fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  applicationFee?: number
  /**
   * Program enrollment fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  enrollmentFee?: number
  /**
   * Program supply first student fee.
   * @type {number}
   * @memberof ProgramUpdate
   */
  supplyFeeFirstStudent?: number
  /**
   * Program supply additional students fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  supplyFeeAdditionalStudent?: number
  /**
   * Program tuition fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  tuition?: number
  /**
   * Program local first student fee.
   * @type {number}
   * @memberof ProgramUpdate
   */
  localFeeFirstStudent?: number
  /**
   * Program facility first student fee.
   * @type {number}
   * @memberof ProgramUpdate
   */
  facilityFeeFirstStudent?: number
  /**
   * Any other miscellaneous program First student fee.
   * @type {number}
   * @memberof ProgramUpdate
   */
  miscFeeFirstStudent?: number
  /**
   * Program local Additional students fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  localFeeAdditionalStudent?: number
  /**
   * Program facility Additional students fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  facilityFeeAdditionalStudent?: number
  /**
   * Any other miscellaneous program Additional student fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  miscFeeAdditionalStudent?: number
  /**
   * **DEPRECATED** Any other miscellaneous program fees.
   * @type {number}
   * @memberof ProgramUpdate
   */
  miscFee2?: number
  /**
   * The percentage of fees given to the tutor. Must be a number from 0 to 100.
   * @type {number}
   * @memberof ProgramUpdate
   */
  subLicensedTutorPercentage?: number
  /**
   * A discount to subsequent application fees when more than one student applies to the same program.
   * @type {number}
   * @memberof ProgramUpdate
   */
  multiStudentApplicationDiscount?: number
  /**
   * The fee a director pays CC Home Office per student for the first semester for the first student in their program.
   * @type {number}
   * @memberof ProgramUpdate
   */
  semesterOneLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the first semester for the second+ students in their program.
   * @type {number}
   * @memberof ProgramUpdate
   */
  discountSemesterOneLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the second semester for the first student in their program..
   * @type {number}
   * @memberof ProgramUpdate
   */
  semesterTwoLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the second semester for the second+ students in their program.
   * @type {number}
   * @memberof ProgramUpdate
   */
  discountSemesterTwoLicensingFee?: number
  /**
   * The invitation fee per student invitation
   * @type {number}
   * @memberof ProgramUpdate
   */
  invitationFee?: number
  /**
   * The discount invitation fee per applied to invitation fee
   * @type {number}
   * @memberof ProgramUpdate
   */
  discountInvitationFee?: number
  /**
   * Id of the community in which this program is offered.
   * @type {number}
   * @memberof ProgramUpdate
   */
  communityId: number
  /**
   *
   * @type {Address}
   * @memberof ProgramUpdate
   */
  address: Address
  /**
   * Phone number for the director of the program.
   * @type {string}
   * @memberof ProgramUpdate
   */
  directorPhone?: string
}

export function ProgramUpdateFromJSON(json: any): ProgramUpdate {
  return ProgramUpdateFromJSONTyped(json, false)
}

export function ProgramUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programsKey: json['programsKey'],
    type: !exists(json, 'type') ? undefined : ProgramTypeFromJSON(json['type']),
    status: ProgramStatusFromJSON(json['status']),
    startDate: !exists(json, 'startDate')
      ? undefined
      : new Date(json['startDate']),
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    semesterTwoStartDate: new Date(json['semesterTwoStartDate']),
    startTime: json['startTime'],
    endTime: json['endTime'],
    dayOfTheWeek: json['dayOfTheWeek'],
    directorActorId: !exists(json, 'directorActorId')
      ? undefined
      : json['directorActorId'],
    tutorActorId: !exists(json, 'tutorActorId')
      ? undefined
      : json['tutorActorId'],
    tutorActorIds: !exists(json, 'tutorActorIds')
      ? undefined
      : json['tutorActorIds'],
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    membershipFee: !exists(json, 'membershipFee')
      ? undefined
      : json['membershipFee'],
    applicationFee: !exists(json, 'applicationFee')
      ? undefined
      : json['applicationFee'],
    enrollmentFee: !exists(json, 'enrollmentFee')
      ? undefined
      : json['enrollmentFee'],
    supplyFeeFirstStudent: !exists(json, 'supplyFeeFirstStudent')
      ? undefined
      : json['supplyFeeFirstStudent'],
    supplyFeeAdditionalStudent: !exists(json, 'supplyFeeAdditionalStudent')
      ? undefined
      : json['supplyFeeAdditionalStudent'],
    tuition: !exists(json, 'tuition') ? undefined : json['tuition'],
    localFeeFirstStudent: !exists(json, 'localFeeFirstStudent')
      ? undefined
      : json['localFeeFirstStudent'],
    facilityFeeFirstStudent: !exists(json, 'facilityFeeFirstStudent')
      ? undefined
      : json['facilityFeeFirstStudent'],
    miscFeeFirstStudent: !exists(json, 'miscFeeFirstStudent')
      ? undefined
      : json['miscFeeFirstStudent'],
    localFeeAdditionalStudent: !exists(json, 'localFeeAdditionalStudent')
      ? undefined
      : json['localFeeAdditionalStudent'],
    facilityFeeAdditionalStudent: !exists(json, 'facilityFeeAdditionalStudent')
      ? undefined
      : json['facilityFeeAdditionalStudent'],
    miscFeeAdditionalStudent: !exists(json, 'miscFeeAdditionalStudent')
      ? undefined
      : json['miscFeeAdditionalStudent'],
    miscFee2: !exists(json, 'miscFee2') ? undefined : json['miscFee2'],
    subLicensedTutorPercentage: !exists(json, 'subLicensedTutorPercentage')
      ? undefined
      : json['subLicensedTutorPercentage'],
    multiStudentApplicationDiscount: !exists(
      json,
      'multiStudentApplicationDiscount'
    )
      ? undefined
      : json['multiStudentApplicationDiscount'],
    semesterOneLicensingFee: !exists(json, 'semesterOneLicensingFee')
      ? undefined
      : json['semesterOneLicensingFee'],
    discountSemesterOneLicensingFee: !exists(
      json,
      'discountSemesterOneLicensingFee'
    )
      ? undefined
      : json['discountSemesterOneLicensingFee'],
    semesterTwoLicensingFee: !exists(json, 'semesterTwoLicensingFee')
      ? undefined
      : json['semesterTwoLicensingFee'],
    discountSemesterTwoLicensingFee: !exists(
      json,
      'discountSemesterTwoLicensingFee'
    )
      ? undefined
      : json['discountSemesterTwoLicensingFee'],
    invitationFee: !exists(json, 'invitationFee')
      ? undefined
      : json['invitationFee'],
    discountInvitationFee: !exists(json, 'discountInvitationFee')
      ? undefined
      : json['discountInvitationFee'],
    communityId: json['communityId'],
    address: AddressFromJSON(json['address']),
    directorPhone: !exists(json, 'directorPhone')
      ? undefined
      : json['directorPhone'],
  }
}

export function ProgramUpdateToJSON(value?: ProgramUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programsKey: value.programsKey,
    type: ProgramTypeToJSON(value.type),
    status: ProgramStatusToJSON(value.status),
    startDate:
      value.startDate === undefined
        ? undefined
        : value.startDate.toISOString().substr(0, 10),
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    semesterTwoStartDate: value.semesterTwoStartDate
      .toISOString()
      .substr(0, 10),
    startTime: value.startTime,
    endTime: value.endTime,
    dayOfTheWeek: value.dayOfTheWeek,
    directorActorId: value.directorActorId,
    tutorActorId: value.tutorActorId,
    tutorActorIds: value.tutorActorIds,
    capacity: value.capacity,
    membershipFee: value.membershipFee,
    applicationFee: value.applicationFee,
    enrollmentFee: value.enrollmentFee,
    supplyFeeFirstStudent: value.supplyFeeFirstStudent,
    supplyFeeAdditionalStudent: value.supplyFeeAdditionalStudent,
    tuition: value.tuition,
    localFeeFirstStudent: value.localFeeFirstStudent,
    facilityFeeFirstStudent: value.facilityFeeFirstStudent,
    miscFeeFirstStudent: value.miscFeeFirstStudent,
    localFeeAdditionalStudent: value.localFeeAdditionalStudent,
    facilityFeeAdditionalStudent: value.facilityFeeAdditionalStudent,
    miscFeeAdditionalStudent: value.miscFeeAdditionalStudent,
    miscFee2: value.miscFee2,
    subLicensedTutorPercentage: value.subLicensedTutorPercentage,
    multiStudentApplicationDiscount: value.multiStudentApplicationDiscount,
    semesterOneLicensingFee: value.semesterOneLicensingFee,
    discountSemesterOneLicensingFee: value.discountSemesterOneLicensingFee,
    semesterTwoLicensingFee: value.semesterTwoLicensingFee,
    discountSemesterTwoLicensingFee: value.discountSemesterTwoLicensingFee,
    invitationFee: value.invitationFee,
    discountInvitationFee: value.discountInvitationFee,
    communityId: value.communityId,
    address: AddressToJSON(value.address),
    directorPhone: value.directorPhone,
  }
}
