/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PaymentOptionsEnrollments,
  PaymentOptionsEnrollmentsFromJSON,
  PaymentOptionsEnrollmentsToJSON,
  PaymentOptionsPrograms,
  PaymentOptionsProgramsFromJSON,
  PaymentOptionsProgramsToJSON,
} from './'

/**
 *
 * @export
 * @interface PaymentOptions
 */
export interface PaymentOptions {
  /**
   * All of the programs the user directs.
   * @type {Array<PaymentOptionsPrograms>}
   * @memberof PaymentOptions
   */
  programs: Array<PaymentOptionsPrograms>
  /**
   * Active enrollments for the specified user.
   * @type {Array<PaymentOptionsEnrollments>}
   * @memberof PaymentOptions
   */
  enrollments: Array<PaymentOptionsEnrollments>
}

export function PaymentOptionsFromJSON(json: any): PaymentOptions {
  return PaymentOptionsFromJSONTyped(json, false)
}

export function PaymentOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programs: (json['programs'] as Array<any>).map(
      PaymentOptionsProgramsFromJSON
    ),
    enrollments: (json['enrollments'] as Array<any>).map(
      PaymentOptionsEnrollmentsFromJSON
    ),
  }
}

export function PaymentOptionsToJSON(value?: PaymentOptions | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programs: (value.programs as Array<any>).map(PaymentOptionsProgramsToJSON),
    enrollments: (value.enrollments as Array<any>).map(
      PaymentOptionsEnrollmentsToJSON
    ),
  }
}
