/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface StarRatingBody
 */
export interface StarRatingBody {
  /**
   * Content asset unique identifier
   * @type {number}
   * @memberof StarRatingBody
   */
  widenAssetKey: number
  /**
   * User's rating for an asset. Rating is a number 1-5.
   * @type {number}
   * @memberof StarRatingBody
   */
  starRating: number
}

export function StarRatingBodyFromJSON(json: any): StarRatingBody {
  return StarRatingBodyFromJSONTyped(json, false)
}

export function StarRatingBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StarRatingBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    widenAssetKey: json['widenAssetKey'],
    starRating: json['starRating'],
  }
}

export function StarRatingBodyToJSON(value?: StarRatingBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    widenAssetKey: value.widenAssetKey,
    starRating: value.starRating,
  }
}
