/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementTemplateSelectOption,
  AgreementTemplateSelectOptionFromJSON,
  AgreementTemplateSelectOptionToJSON,
} from './'

/**
 *
 * @export
 * @interface RoleAgreementTemplateSelectOption
 */
export interface RoleAgreementTemplateSelectOption {
  /**
   * The name of the role.
   * @type {string}
   * @memberof RoleAgreementTemplateSelectOption
   */
  roleName: string
  /**
   * Unique identifier for the role.
   * @type {number}
   * @memberof RoleAgreementTemplateSelectOption
   */
  roleKey: number
  /**
   * **DEPRECATED** A list of agreement templates that pertain to the role.
   * @type {Array<AgreementTemplateSelectOption>}
   * @memberof RoleAgreementTemplateSelectOption
   */
  agreementTemplates?: Array<AgreementTemplateSelectOption>
}

export function RoleAgreementTemplateSelectOptionFromJSON(
  json: any
): RoleAgreementTemplateSelectOption {
  return RoleAgreementTemplateSelectOptionFromJSONTyped(json, false)
}

export function RoleAgreementTemplateSelectOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RoleAgreementTemplateSelectOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleName: json['roleName'],
    roleKey: json['roleKey'],
    agreementTemplates: !exists(json, 'agreementTemplates')
      ? undefined
      : (json['agreementTemplates'] as Array<any>).map(
          AgreementTemplateSelectOptionFromJSON
        ),
  }
}

export function RoleAgreementTemplateSelectOptionToJSON(
  value?: RoleAgreementTemplateSelectOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleName: value.roleName,
    roleKey: value.roleKey,
    agreementTemplates:
      value.agreementTemplates === undefined
        ? undefined
        : (value.agreementTemplates as Array<any>).map(
            AgreementTemplateSelectOptionToJSON
          ),
  }
}
