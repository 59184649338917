/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 *
 * @export
 * @interface LicensingFeePerRateBreakdown
 */
export interface LicensingFeePerRateBreakdown {
  /**
   *
   * @type {string}
   * @memberof LicensingFeePerRateBreakdown
   */
  fee?: LicensingFeePerRateBreakdownFeeEnum
  /**
   *
   * @type {number}
   * @memberof LicensingFeePerRateBreakdown
   */
  count: number
  /**
   *
   * @type {Array<number>}
   * @memberof LicensingFeePerRateBreakdown
   */
  appliesToStudents?: Array<number>
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingFeePerRateBreakdown
   */
  amount: CashAmount
}

/**
 * @export
 * @enum {string}
 */
export enum LicensingFeePerRateBreakdownFeeEnum {
  FirstSpotInvitation = 'firstSpotInvitation',
  AdditionalSpotsInvitation = 'additionalSpotsInvitation',
  Sem1FirstStudent = 'sem1FirstStudent',
  Sem1AdditionalStudent = 'sem1AdditionalStudent',
  Sem2FirstStudent = 'sem2FirstStudent',
  Sem2MemberHalfFirstStudent = 'sem2MemberHalfFirstStudent',
  Sem2AdditionalStudent = 'sem2AdditionalStudent',
  FullPriceApplication = 'fullPriceApplication',
  DiscountedApplication = 'discountedApplication',
}

export function LicensingFeePerRateBreakdownFromJSON(
  json: any
): LicensingFeePerRateBreakdown {
  return LicensingFeePerRateBreakdownFromJSONTyped(json, false)
}

export function LicensingFeePerRateBreakdownFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingFeePerRateBreakdown {
  if (json === undefined || json === null) {
    return json
  }
  return {
    fee: !exists(json, 'fee') ? undefined : json['fee'],
    count: json['count'],
    appliesToStudents: !exists(json, 'appliesToStudents')
      ? undefined
      : json['appliesToStudents'],
    amount: CashAmountFromJSON(json['amount']),
  }
}

export function LicensingFeePerRateBreakdownToJSON(
  value?: LicensingFeePerRateBreakdown | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    fee: value.fee,
    count: value.count,
    appliesToStudents: value.appliesToStudents,
    amount: CashAmountToJSON(value.amount),
  }
}
