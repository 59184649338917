/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface CommunityManager
 */
export interface CommunityManager {
  /**
   * Unique identifier for a manager.
   * @type {number}
   * @memberof CommunityManager
   */
  id: number
  /**
   * The role played by the manager of the community.
   * @type {string}
   * @memberof CommunityManager
   */
  role: string
  /**
   * Unique identifier for the supervisor of the manager
   * @type {number}
   * @memberof CommunityManager
   */
  supervisorId?: number
  /**
   * Unique identifier referencing a user containing properties like firstName, lastName, userName, and email.
   * @type {number}
   * @memberof CommunityManager
   */
  userId: number
}

export function CommunityManagerFromJSON(json: any): CommunityManager {
  return CommunityManagerFromJSONTyped(json, false)
}

export function CommunityManagerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunityManager {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    role: json['role'],
    supervisorId: !exists(json, 'supervisorId')
      ? undefined
      : json['supervisorId'],
    userId: json['userId'],
  }
}

export function CommunityManagerToJSON(value?: CommunityManager | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    role: value.role,
    supervisorId: value.supervisorId,
    userId: value.userId,
  }
}
