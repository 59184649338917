/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
} from './'

/**
 * An enrollment of a student to a program.
 * @export
 * @interface ProgramEnrollment
 */
export interface ProgramEnrollment {
  /**
   * Unique identifier of the student tied to this enrollment.
   * @type {number}
   * @memberof ProgramEnrollment
   */
  studentKey: number
  /**
   * First name of the student tied to this enrollment.
   * @type {string}
   * @memberof ProgramEnrollment
   */
  studentFirstName: string
  /**
   * Unique identifier for the program the student is enrolled in.
   * @type {number}
   * @memberof ProgramEnrollment
   */
  programKey: number
  /**
   *
   * @type {EnrollmentStatus}
   * @memberof ProgramEnrollment
   */
  status: EnrollmentStatus
}

export function ProgramEnrollmentFromJSON(json: any): ProgramEnrollment {
  return ProgramEnrollmentFromJSONTyped(json, false)
}

export function ProgramEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramEnrollment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    studentFirstName: json['studentFirstName'],
    programKey: json['programKey'],
    status: EnrollmentStatusFromJSON(json['status']),
  }
}

export function ProgramEnrollmentToJSON(value?: ProgramEnrollment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    studentFirstName: value.studentFirstName,
    programKey: value.programKey,
    status: EnrollmentStatusToJSON(value.status),
  }
}
