/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface ProgramTutors
 */
export interface ProgramTutors {
  /**
   * Unique identifier of the actor for the program's assigned tutor.
   * @type {number}
   * @memberof ProgramTutors
   */
  actorKey?: number
  /**
   * Name of the program's assigned tutor.
   * @type {string}
   * @memberof ProgramTutors
   */
  tutorName?: string
  /**
   * Email address for the program's assigned tutor.
   * @type {string}
   * @memberof ProgramTutors
   */
  tutorEmail?: string
}

export function ProgramTutorsFromJSON(json: any): ProgramTutors {
  return ProgramTutorsFromJSONTyped(json, false)
}

export function ProgramTutorsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramTutors {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    tutorName: !exists(json, 'tutorName') ? undefined : json['tutorName'],
    tutorEmail: !exists(json, 'tutorEmail') ? undefined : json['tutorEmail'],
  }
}

export function ProgramTutorsToJSON(value?: ProgramTutors | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    tutorName: value.tutorName,
    tutorEmail: value.tutorEmail,
  }
}
