/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AgreementWebhookRequestBodyAgreementVaultingInfo
 */
export interface AgreementWebhookRequestBodyAgreementVaultingInfo {
  /**
   *
   * @type {boolean}
   * @memberof AgreementWebhookRequestBodyAgreementVaultingInfo
   */
  enabled?: boolean
}

export function AgreementWebhookRequestBodyAgreementVaultingInfoFromJSON(
  json: any
): AgreementWebhookRequestBodyAgreementVaultingInfo {
  return AgreementWebhookRequestBodyAgreementVaultingInfoFromJSONTyped(
    json,
    false
  )
}

export function AgreementWebhookRequestBodyAgreementVaultingInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementWebhookRequestBodyAgreementVaultingInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
  }
}

export function AgreementWebhookRequestBodyAgreementVaultingInfoToJSON(
  value?: AgreementWebhookRequestBodyAgreementVaultingInfo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enabled: value.enabled,
  }
}
