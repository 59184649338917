import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpIcon from '@mui/icons-material/Help'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import type { TFunction } from 'i18next'
import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { PaginationResponse, Permission } from '../../../api/swagger'
import { meta, extractedErrorObject } from '../../../api/swagger'
import type { Role } from '../../../swagger/models/Role'
import { GrantScopeCodeEnum } from '../../../swagger/models/Grant'
import { Page } from '../../Elements/PageMargins'
import SearchBar from '../../Search/SearchBar'
import { escapeString } from '../../../utils/stringUtility'
import Box from '@mui/material/Box'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import { Tooltip, useTheme } from '@mui/material'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import LoadingProgress from '../../Elements/LoadingProgress'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import EmptyRoles from './EmptyRoles'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../../Table/ActionableTable'
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid'

interface RoleWithGrantCode extends Role {
  roleCode: GrantIconEnum
}
interface PermissionWithRoles extends Permission {
  title: { title: string; description?: string }
  [roleKey: number]: RoleWithGrantCode
}

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
// For the naming see: Barney Stinson https://www.youtube.com/watch?v=Dqf1BmN4Dag
export const LegenWaitForItDary = styled(Paper)(({ theme }) => ({
  height: 36,
  margin: theme.spacing(0, 0, 0, 3),
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    margin: theme.spacing(0, 0, 3),
  },
})) as typeof Paper

export const BlueHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.customBackground.onPrimary,
  margin: theme.spacing(1),
  marginInlineStart: `${theme.spacing(1.5)}`,
}))

const LegendListItem = styled('li')(({ theme }) => ({
  marginInlineStart: `${theme.spacing(3)}`,
}))

const UnorderedList = styled('ul')<{ showOnDesktop: boolean }>(
  ({ showOnDesktop }) => ({
    display: showOnDesktop ? 'inline' : 'block',
    '& li': {
      display: showOnDesktop ? 'inline' : 'block',
    },
  })
)

export const groupPermissionsForDisplay = (
  permissions: Permission[]
): Permission[] => {
  const groupedByCategory = permissions.reduce<Record<string, Permission[]>>(
    (acc, permission) => {
      const { category } = permission
      if (!acc[category ?? '']) {
        acc[category ?? ''] = []
      }
      acc[category ?? ''].push(permission)
      return acc
    },
    {}
  )

  // Sort By permission name
  Object.keys(groupedByCategory).forEach((category) => {
    groupedByCategory[category].sort((a, b) => {
      // If name is undefined, use an empty string but it should never happen
      const nameA = a.name || ''
      const nameB = b.name || ''

      return nameA.localeCompare(nameB)
    })
  })

  return Object.values(groupedByCategory).flat()
}

enum GrantIconEnum {
  EnabledGlobally = 'Enabled Globally',
  RestrictedToHierarchy = 'Restricted to Hierarchy',
  Disabled = 'Disabled',
}

const grantForPermissionByRole = (
  permission: Permission,
  role: Role
): GrantIconEnum => {
  const grant = role.grants.find(
    (it) =>
      it.resourceCode === permission.resourceCode &&
      it.actionCode === permission.actionCode
  )
  switch (grant?.scopeCode) {
    case GrantScopeCodeEnum.Any:
      return GrantIconEnum.EnabledGlobally
    case GrantScopeCodeEnum.TheirTeams:
      return GrantIconEnum.RestrictedToHierarchy
    default:
      return GrantIconEnum.Disabled
  }
}

const GrantIcon: React.FunctionComponent<{
  grant: GrantIconEnum
  titleAccess?: string
}> = ({ grant, titleAccess }) => {
  const { t } = useTranslation()
  const label = titleAccess ?? labelForGrant(grant, t)
  switch (grant) {
    case GrantIconEnum.EnabledGlobally:
      return <CheckCircleIcon titleAccess={label} color="secondary" />
    case GrantIconEnum.RestrictedToHierarchy:
      return <RemoveCircleIcon titleAccess={label} color={'warning'} />
    case GrantIconEnum.Disabled: /* FALL THROUGH */
    default:
      return <NotInterestedIcon titleAccess={label} color="action" />
  }
}

const labelForGrant = (grant: GrantIconEnum, t: TFunction) => {
  switch (grant) {
    case GrantIconEnum.EnabledGlobally:
      return t('Roles.Grant.EnabledGlobally', 'Enabled Globally')
    case GrantIconEnum.RestrictedToHierarchy:
      return t('Roles.Grant.RestrictedToHierarchy', 'Restricted to Hierarchy')
    case GrantIconEnum.Disabled: /* FALL THROUGH */
    default:
      return t('Roles.Grant.Disabled', 'Disabled')
  }
}
interface RolesProps {
  roles: Role[]
}

const searchRoles = (args: {
  rolesToSearch: Role[]
  searchInput: string
}): Role[] => {
  const filterRegex = new RegExp(escapeString(args.searchInput).trim(), 'i')
  const sortedRoles = args.rolesToSearch?.filter((role) => {
    return filterRegex.test(role.name ?? '')
  })
  return sortedRoles
}

export const Roles: React.FC<RolesProps> = (props) => {
  const { roles } = props
  const navigate = useNavigate()
  const showOnDesktop = useShowOnDesktop()

  const [permissions, setPermissions] = useState(Array<Permission>)

  const pageSize = 10
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSize)
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const { Meta } = useLoadingIds()
  const [isLoading, setIsLoading] = useState(true)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()
  const theme = useTheme()

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = (searchText: string) => {
    setSearchQuery(searchText)
  }
  /**
   * Fetch permissions
   */
  const fetchPermissions = async () => {
    try {
      const fetchedPermissions: Permission[] = await meta.fetchPermissions({})

      const orderedPermissions = groupPermissionsForDisplay(fetchedPermissions)
      setPermissions(orderedPermissions)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ?? 'Failed to fetch permissions.',
      }
      setErrorMessage(errorObject.message)
    } finally {
      setIsLoading(false)
    }
  }
  /** Hooks */
  useLoadingContext({
    asyncFunction: fetchPermissions,
    loadingId: Meta.fetchPermissions,
  })

  useMountEffect(() => {
    addLoadingIds([Meta.fetchPermissions])
  })

  /*
   * If error fetching permissions, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const filteredRoles = (searchQuery: string, roles: Role[]) => {
    const searchResults = (args: { searchQuery: string }): Role[] => {
      const searchedRoles = searchRoles({
        rolesToSearch: roles ?? [],
        searchInput: args.searchQuery,
      })
      return searchedRoles
    }

    const results = searchResults({
      searchQuery,
    })

    return results
  }

  const searchedRoles = useMemo(
    () => filteredRoles(searchQuery, roles ?? []),
    [searchQuery, roles]
  )

  const handleToEditRole = (roleKey: number) => {
    navigate(
      {
        pathname: `/admin/roles/role-details/${roleKey}`,
      },
      {
        /** Navigation Options */
      }
    )
  }
  if (loadingIds.has(Meta.fetchPermissions)) {
    return <LoadingProgress />
  }

  const handleChangePage = (pagination: PaginationResponse) => {
    setPage(pagination.page)
    setRowsPerPage(pagination.pageSize)
  }
  const rolesHeaders = searchedRoles
    .map((role: Role) => {
      if (role.roleKey) {
        return {
          fieldName: role.roleKey.toString(),
          renderHeader: () => (
            <Typography
              variant="subtitle2"
              component="p"
              onClick={() => handleToEditRole(role.roleKey ?? -1)}
              style={{
                cursor: 'pointer',
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
            >
              {t(
                'RoleTable.TableHeader.RoleName.{{roleName}}',
                '{{roleName}}',
                {
                  roleName: role.name,
                }
              )}
            </Typography>
          ),
          width: 100,
          minWidth: 100,
          align: 'center' as GridAlignment,
          headerClassName: 'multiDataGrid-role-ColumnHeader',
          renderCell: (params: GridRenderCellParams<RoleWithGrantCode>) => {
            const grant = params?.value?.roleCode as unknown as GrantIconEnum

            if (grant) {
              return <GrantIcon grant={grant} />
            }
          },
        }
      }
    })
    .filter((header) => !!header)

  const columnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'title',
      renderHeader: () => (
        <Typography variant="subtitle2" component="h2" display={'inline'}>
          {t('Roles.Table.Header.Name', 'Permissions')}
        </Typography>
      ),
      width: 200,
      minWidth: 200,
      headerClassName: 'multiDataGrid-permissions-columnHeader',
      groupable: true,
      cellClassName: 'multiDataGrid-permissions-rowColumn',
      renderCell: (
        params: GridRenderCellParams<{ title: string; description?: string }>
      ) => {
        const category = params?.value.title.split(':')
        if (category.length > 1) {
          // category grouping tag
          return (
            <Typography variant="subtitle2" component="h3">
              {category[1]}
            </Typography>
          )
        }
        // permission name and tooltip with the description
        return (
          <Tooltip title={params?.value?.description ?? ''}>
            <Typography variant="caption">{params?.value.title}</Typography>
          </Tooltip>
        )
      },
    },
    ...(rolesHeaders as unknown as ActionableTableColumn[]),
  ]

  const tableData = (
    rowsPerPage > 0
      ? permissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : permissions
  ) as (Pick<PermissionWithRoles, 'title'> | PermissionWithRoles)[]

  const seenCategory = new Set<string>()
  const result: (Pick<PermissionWithRoles, 'title'> | PermissionWithRoles)[] =
    []

  tableData.forEach((permission) => {
    if ('name' in permission) {
      permission.title = {
        title: permission.name ?? permission.actionCode,
        description: permission.description ?? '',
      }
      searchedRoles.forEach((role) => {
        if (role.roleKey) {
          permission[role.roleKey] = {
            ...role,
            roleCode: grantForPermissionByRole(permission, role),
          }
        }
      })
    }

    // Check if category exists and hasn't been seen yet
    if (
      'category' in permission &&
      !seenCategory.has(permission.category ?? '') &&
      permission.category
    ) {
      // Add a new object with only the `category` property
      result.push({ title: { title: `category:${permission.category}` } })
      seenCategory.add(permission.category)
    }
    if (permission.title) {
      //Add the original item
      result.push(permission)
    }
  })

  return (
    <Page withinTab>
      {!permissions.length ? (
        <EmptyRoles isLoading={isLoading} />
      ) : (
        <>
          <Box display="flex" flexDirection={!showOnDesktop ? 'column' : 'row'}>
            <SearchBar handleSearch={handleSearch} />
            <LegenWaitForItDary
              component="section"
              aria-labelledby="legend-label"
            >
              <Typography
                variant="body2"
                id="legend-label"
                component="h2"
                display="inline"
              >
                <BlueHelpIcon />
                {t('Roles.Table.Legend.Title', 'KEY:')}
              </Typography>
              <UnorderedList
                showOnDesktop={showOnDesktop}
                aria-labelledby="legend-label"
              >
                {Object.values(GrantIconEnum).map((grant) => (
                  <LegendListItem key={grant}>
                    <GrantIcon grant={grant} titleAccess="" />
                    <Typography
                      variant="body2"
                      component="span"
                      margin={theme.spacing(1)}
                    >
                      {labelForGrant(grant, t)}
                    </Typography>
                  </LegendListItem>
                ))}
              </UnorderedList>
            </LegenWaitForItDary>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up('md')]: {
                height: 600,
                width: '75%',
              },
              [theme.breakpoints.down('sm')]: {
                height: 600,
                width: 'auto',
              },
            }}
          >
            <ActionableTable
              columns={columnsHeader}
              rows={searchedRoles.length <= 0 ? [] : result}
              noResultsMessage={t(
                'Roles.EmptyState.Instructions',
                'No Role information available.'
              )}
              columnHeaderHeight={200}
              rowHeight={50}
              pagination={{
                page,
                pageSize,
                totalCount: searchedRoles.length <= 0 ? 0 : permissions.length,
                orderBy: [{}],
              }}
              handlePaginationChange={handleChangePage}
            />
          </Box>
        </>
      )}
    </Page>
  )
}

export default Roles
