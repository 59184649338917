import React, { PropsWithChildren, useState } from 'react'
import {
  Categories,
  FilterFileType,
  Roles,
} from '../../utils/searchAndFilterEnums'

export const defaultBusinessContextValue = {
  search: '',
  updateSearch: (search: string): void => {
    console.warn(
      `The BusinessContext.updateSearch was called with value ${search}. Did you forget to use a BusinessProvider?`
    )
  },
  role: Roles.AllRoles,
  updateRole: (role: Roles): void => {
    console.warn(
      `The BusinessContext.updateRole was called with value ${role}. Did you forget to use a BusinessProvider?`
    )
  },
  type: FilterFileType.AllTypes,
  updateType: (type: FilterFileType): void => {
    console.warn(
      `The BusinessContext.updateType was called with value ${type}. Did you forget to use a BusinessProvider?`
    )
  },
  category: Categories.AllCategories,
  updateCategory: (category: Categories): void => {
    console.warn(
      `The BusinessContext.updateCategory was called with value ${category}. Did you forget to use a BusinessProvider?`
    )
  },
  resetContextToDefaults: (): void => {
    console.warn(
      `The BusinessContext.resetContextToDefaults was called by default. Did you forget to use a BusinessProvider?`
    )
  },
}

const BusinessContext = React.createContext(defaultBusinessContextValue)

export const useBusinessContext = (): typeof defaultBusinessContextValue =>
  React.useContext(BusinessContext)

export type TestBusinessContextConfig = typeof defaultBusinessContextValue

interface BusinessProviderProps extends PropsWithChildren {
  /** Useful when needing to override and spy on values or setters in testing. */
  testConfig?: Partial<TestBusinessContextConfig>
}

const BusinessProvider: React.FC<BusinessProviderProps> = ({
  testConfig,
  children,
}) => {
  const [search, setSearch] = useState(defaultBusinessContextValue.search)
  const [type, setType] = useState(defaultBusinessContextValue.type)
  const [role, setRole] = useState(defaultBusinessContextValue.role)
  const [category, setCategory] = useState(defaultBusinessContextValue.category)

  const updateSearch = (newSearch: string) => {
    setSearch(newSearch)
  }

  const updateRole = (newRole: Roles) => {
    setRole(newRole)
  }

  const updateType = (newType: FilterFileType) => {
    setType(newType)
  }

  const updateCategory = (newCategory: Categories) => {
    setCategory(newCategory)
  }

  const resetContextToDefaults = () => {
    updateType(defaultBusinessContextValue.type)
    updateSearch(defaultBusinessContextValue.search)
    updateCategory(defaultBusinessContextValue.category)
    updateRole(defaultBusinessContextValue.role)
  }

  const value = {
    search,
    type,
    role,
    category,
    updateSearch,
    updateRole,
    updateType,
    updateCategory,
    resetContextToDefaults,
    ...testConfig,
  }

  return (
    <BusinessContext.Provider value={value}>
      {children}
    </BusinessContext.Provider>
  )
}

export default BusinessProvider
