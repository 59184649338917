/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface ProgramInviteFamilyOptionsResponsePrograms
 */
export interface ProgramInviteFamilyOptionsResponsePrograms {
  /**
   * Unique identifier for a program
   * @type {number}
   * @memberof ProgramInviteFamilyOptionsResponsePrograms
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof ProgramInviteFamilyOptionsResponsePrograms
   */
  programType: ProgramType
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof ProgramInviteFamilyOptionsResponsePrograms
   */
  semesterOneStartDate: Date
  /**
   * The amount of spots available in a program to invite new children
   * @type {number}
   * @memberof ProgramInviteFamilyOptionsResponsePrograms
   */
  availableSpots: number
}

export function ProgramInviteFamilyOptionsResponseProgramsFromJSON(
  json: any
): ProgramInviteFamilyOptionsResponsePrograms {
  return ProgramInviteFamilyOptionsResponseProgramsFromJSONTyped(json, false)
}

export function ProgramInviteFamilyOptionsResponseProgramsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramInviteFamilyOptionsResponsePrograms {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    availableSpots: json['availableSpots'],
  }
}

export function ProgramInviteFamilyOptionsResponseProgramsToJSON(
  value?: ProgramInviteFamilyOptionsResponsePrograms | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    availableSpots: value.availableSpots,
  }
}
