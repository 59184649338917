/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollments,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON,
} from './'

/**
 * Returns the number of rows affected by the deletion and if an item was not deleted, it should return those records for the user to verify.
 * @export
 * @interface RemoveLicensingEnrollmentRequestResponse
 */
export interface RemoveLicensingEnrollmentRequestResponse {
  /**
   * The number of rows affected by the deletion.
   * @type {number}
   * @memberof RemoveLicensingEnrollmentRequestResponse
   */
  removedRows?: number
  /**
   * An array of objects with the identifiers studentKey, programKey and feeTypeKey.
   * @type {Array<RemoveLicensingEnrollmentRequestBodyLicensingEnrollments>}
   * @memberof RemoveLicensingEnrollmentRequestResponse
   */
  recordsNotRemoved?: Array<RemoveLicensingEnrollmentRequestBodyLicensingEnrollments>
}

export function RemoveLicensingEnrollmentRequestResponseFromJSON(
  json: any
): RemoveLicensingEnrollmentRequestResponse {
  return RemoveLicensingEnrollmentRequestResponseFromJSONTyped(json, false)
}

export function RemoveLicensingEnrollmentRequestResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveLicensingEnrollmentRequestResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    removedRows: !exists(json, 'removedRows') ? undefined : json['removedRows'],
    recordsNotRemoved: !exists(json, 'recordsNotRemoved')
      ? undefined
      : (json['recordsNotRemoved'] as Array<any>).map(
          RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON
        ),
  }
}

export function RemoveLicensingEnrollmentRequestResponseToJSON(
  value?: RemoveLicensingEnrollmentRequestResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    removedRows: value.removedRows,
    recordsNotRemoved:
      value.recordsNotRemoved === undefined
        ? undefined
        : (value.recordsNotRemoved as Array<any>).map(
            RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON
          ),
  }
}
