/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * The body to update registrant receives an object with the following required properties: firstName, lastName, email, attending and registrantKey.
 * @export
 * @interface UpdateRegistrantRequestBody
 */
export interface UpdateRegistrantRequestBody {
  /**
   * The unique identifier of the registrant.
   * @type {number}
   * @memberof UpdateRegistrantRequestBody
   */
  registrantKey: number
  /**
   * First name of the registrant.
   * @type {string}
   * @memberof UpdateRegistrantRequestBody
   */
  firstName: string
  /**
   * Last name of the registrant.
   * @type {string}
   * @memberof UpdateRegistrantRequestBody
   */
  lastName: string
  /**
   * The Email address of the registrant.
   * @type {string}
   * @memberof UpdateRegistrantRequestBody
   */
  email: string
  /**
   * The phone number of the registrant.
   * @type {string}
   * @memberof UpdateRegistrantRequestBody
   */
  phone?: string
  /**
   * The attending determines whether the registrant will attend.
   * @type {boolean}
   * @memberof UpdateRegistrantRequestBody
   */
  attending: boolean
}

export function UpdateRegistrantRequestBodyFromJSON(
  json: any
): UpdateRegistrantRequestBody {
  return UpdateRegistrantRequestBodyFromJSONTyped(json, false)
}

export function UpdateRegistrantRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateRegistrantRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    registrantKey: json['registrantKey'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    attending: json['attending'],
  }
}

export function UpdateRegistrantRequestBodyToJSON(
  value?: UpdateRegistrantRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    registrantKey: value.registrantKey,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phone: value.phone,
    attending: value.attending,
  }
}
