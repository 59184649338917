import React from 'react'
import { Box, Theme, Typography, SxProps } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'

const ShowFullScreenNavLink = styled(NavLink)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.5, 0),
  },
  color: 'white',
  textDecoration: 'none',
  backgroundColor: '#57ab82',
  padding: theme.spacing(1, 2),
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  borderRadius: theme.spacing(0.5),
  textTransform: 'uppercase',
}))

interface ShowFullScreenButtonProps {
  assetKey: number
  buttonContainerStyles?: SxProps<Theme>
}

const ShowFullScreenButton: React.FC<ShowFullScreenButtonProps> = ({
  assetKey,
  buttonContainerStyles,
}) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="flex-end" sx={buttonContainerStyles}>
      <ShowFullScreenNavLink
        to={{ pathname: `/content/asset/fullScreen/${assetKey}` }}
        target="_blank"
      >
        <Typography fontWeight="bold" fontFamily="sweet-sans-pro, sans-serif">
          {t(
            'ShowFullScreenButton.Label.LaunchFullscreen',
            'Launch Fullscreen'
          )}
        </Typography>
      </ShowFullScreenNavLink>
    </Box>
  )
}
export default ShowFullScreenButton
