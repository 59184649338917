/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface Enrollment
 */
export interface Enrollment {
  /**
   * Unique identifier for the community that holds the program the student is enrolled in.
   * @type {number}
   * @memberof Enrollment
   */
  communityKey: number
  /**
   * Name of the community that holds the program the student is enrolled in.
   * @type {string}
   * @memberof Enrollment
   */
  communityName: string
  /**
   * Unique identifier for the program the student is enrolled in.
   * @type {number}
   * @memberof Enrollment
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof Enrollment
   */
  programType: ProgramType
  /**
   * Last name of the family the enrollment was sent to.
   * @type {string}
   * @memberof Enrollment
   */
  familyName: string
  /**
   * User key of the parent that is the primary contact for the family.
   * @type {number}
   * @memberof Enrollment
   */
  primaryContactId: number
  /**
   * The name of the student the enrollment into the program is for.
   * @type {string}
   * @memberof Enrollment
   */
  studentName: string
  /**
   * The unique identifier of the student the enrollment into the program is for.
   * @type {number}
   * @memberof Enrollment
   */
  studentKey: number
  /**
   * The date of birth of the student the enrollment into the program is for.
   * @type {Date}
   * @memberof Enrollment
   */
  studentDOB: Date
  /**
   * The number indicating student's month of birth. Indexed from 0 to 11.
   * @type {number}
   * @memberof Enrollment
   */
  birthMonth?: number
  /**
   * The number indicating student's year of birth.
   * @type {number}
   * @memberof Enrollment
   */
  birthYear?: number
  /**
   * The date the enrollment was sent out as an invite.
   * @type {Date}
   * @memberof Enrollment
   */
  inviteDate: Date
  /**
   *
   * @type {EnrollmentStatus}
   * @memberof Enrollment
   */
  status: EnrollmentStatus
  /**
   * The date the enrollment is valid from.
   * @type {Date}
   * @memberof Enrollment
   */
  enrollmentStatusValidFrom: Date
  /**
   * Has the student had any enrollments in past academic years for programs in said community
   * @type {boolean}
   * @memberof Enrollment
   */
  isReturningStudent: boolean
  /**
   * Email of the family the enrollment was sent to.
   * @type {string}
   * @memberof Enrollment
   */
  familyEmail: string
  /**
   * ActorKey for the tutor assigned to this enrollment
   * @type {number}
   * @memberof Enrollment
   */
  tutorActorKey?: number
  /**
   * if true, Tuition Paid Semester One is checked
   * @type {boolean}
   * @memberof Enrollment
   */
  semesterOneTuitionPaid?: boolean
  /**
   * if true, Tuition Paid Semester Two is checked, is returned only for Challenge Programs
   * @type {boolean}
   * @memberof Enrollment
   */
  semesterTwoTuitionPaid?: boolean
  /**
   * if true, Licensing Owed Semester One is checked
   * @type {boolean}
   * @memberof Enrollment
   */
  semesterOneLicensingOwed?: boolean
  /**
   * if true, Licensing Owed Semester Two is checked, is returned only for Challenge Programs
   * @type {boolean}
   * @memberof Enrollment
   */
  semesterTwoLicensingOwed?: boolean
}

export function EnrollmentFromJSON(json: any): Enrollment {
  return EnrollmentFromJSONTyped(json, false)
}

export function EnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Enrollment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: json['communityKey'],
    communityName: json['communityName'],
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    familyName: json['familyName'],
    primaryContactId: json['primaryContactId'],
    studentName: json['studentName'],
    studentKey: json['studentKey'],
    studentDOB: new Date(json['studentDOB']),
    birthMonth: !exists(json, 'birthMonth') ? undefined : json['birthMonth'],
    birthYear: !exists(json, 'birthYear') ? undefined : json['birthYear'],
    inviteDate: new Date(json['inviteDate']),
    status: EnrollmentStatusFromJSON(json['status']),
    enrollmentStatusValidFrom: new Date(json['enrollmentStatusValidFrom']),
    isReturningStudent: json['isReturningStudent'],
    familyEmail: json['familyEmail'],
    tutorActorKey: !exists(json, 'tutorActorKey')
      ? undefined
      : json['tutorActorKey'],
    semesterOneTuitionPaid: !exists(json, 'semesterOneTuitionPaid')
      ? undefined
      : json['semesterOneTuitionPaid'],
    semesterTwoTuitionPaid: !exists(json, 'semesterTwoTuitionPaid')
      ? undefined
      : json['semesterTwoTuitionPaid'],
    semesterOneLicensingOwed: !exists(json, 'semesterOneLicensingOwed')
      ? undefined
      : json['semesterOneLicensingOwed'],
    semesterTwoLicensingOwed: !exists(json, 'semesterTwoLicensingOwed')
      ? undefined
      : json['semesterTwoLicensingOwed'],
  }
}

export function EnrollmentToJSON(value?: Enrollment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    communityName: value.communityName,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    familyName: value.familyName,
    primaryContactId: value.primaryContactId,
    studentName: value.studentName,
    studentKey: value.studentKey,
    studentDOB: value.studentDOB.toISOString().substr(0, 10),
    birthMonth: value.birthMonth,
    birthYear: value.birthYear,
    inviteDate: value.inviteDate.toISOString().substr(0, 10),
    status: EnrollmentStatusToJSON(value.status),
    enrollmentStatusValidFrom: value.enrollmentStatusValidFrom
      .toISOString()
      .substr(0, 10),
    isReturningStudent: value.isReturningStudent,
    familyEmail: value.familyEmail,
    tutorActorKey: value.tutorActorKey,
    semesterOneTuitionPaid: value.semesterOneTuitionPaid,
    semesterTwoTuitionPaid: value.semesterTwoTuitionPaid,
    semesterOneLicensingOwed: value.semesterOneLicensingOwed,
    semesterTwoLicensingOwed: value.semesterTwoLicensingOwed,
  }
}
