/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  PaymentInfo,
  PaymentInfoFromJSON,
  PaymentInfoToJSON,
  SentHistoryInfo,
  SentHistoryInfoFromJSON,
  SentHistoryInfoToJSON,
} from './'

/**
 * Object that encapsulates the information needed for each invoice.
 * @export
 * @interface InvoiceInfo
 */
export interface InvoiceInfo {
  /**
   * The unique identifier of the invoice sent by the director or tutor.
   * @type {number}
   * @memberof InvoiceInfo
   */
  invoiceKey: number
  /**
   *
   * @type {CashAmount}
   * @memberof InvoiceInfo
   */
  amountOwed: CashAmount
  /**
   * List of payments made towards the invoice.
   * @type {Array<PaymentInfo>}
   * @memberof InvoiceInfo
   */
  payments?: Array<PaymentInfo>
  /**
   * The lastSentEmailDate represents the date when the most recent invoice email was sent.
   * @type {Date}
   * @memberof InvoiceInfo
   */
  lastSentEmailDate?: Date
  /**
   * The last modification date of the invoice line.
   * @type {Date}
   * @memberof InvoiceInfo
   */
  lastInvoiceLineModifiedDate?: Date
  /**
   * A list of dates when the invoice has been sent.
   * @type {Array<SentHistoryInfo>}
   * @memberof InvoiceInfo
   */
  sentHistory?: Array<SentHistoryInfo>
}

export function InvoiceInfoFromJSON(json: any): InvoiceInfo {
  return InvoiceInfoFromJSONTyped(json, false)
}

export function InvoiceInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InvoiceInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceKey: json['invoiceKey'],
    amountOwed: CashAmountFromJSON(json['amountOwed']),
    payments: !exists(json, 'payments')
      ? undefined
      : (json['payments'] as Array<any>).map(PaymentInfoFromJSON),
    lastSentEmailDate: !exists(json, 'lastSentEmailDate')
      ? undefined
      : new Date(json['lastSentEmailDate']),
    lastInvoiceLineModifiedDate: !exists(json, 'lastInvoiceLineModifiedDate')
      ? undefined
      : new Date(json['lastInvoiceLineModifiedDate']),
    sentHistory: !exists(json, 'sentHistory')
      ? undefined
      : (json['sentHistory'] as Array<any>).map(SentHistoryInfoFromJSON),
  }
}

export function InvoiceInfoToJSON(value?: InvoiceInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceKey: value.invoiceKey,
    amountOwed: CashAmountToJSON(value.amountOwed),
    payments:
      value.payments === undefined
        ? undefined
        : (value.payments as Array<any>).map(PaymentInfoToJSON),
    lastSentEmailDate:
      value.lastSentEmailDate === undefined
        ? undefined
        : value.lastSentEmailDate.toISOString(),
    lastInvoiceLineModifiedDate:
      value.lastInvoiceLineModifiedDate === undefined
        ? undefined
        : value.lastInvoiceLineModifiedDate.toISOString(),
    sentHistory:
      value.sentHistory === undefined
        ? undefined
        : (value.sentHistory as Array<any>).map(SentHistoryInfoToJSON),
  }
}
