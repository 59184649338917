/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PaymentOptionsEnrollments
 */
export interface PaymentOptionsEnrollments {
  /**
   * The student's first and last name.
   * @type {string}
   * @memberof PaymentOptionsEnrollments
   */
  studentFirstName: string
  /**
   * The type of the program the user's student is enrolled in.
   * @type {string}
   * @memberof PaymentOptionsEnrollments
   */
  programType: string
  /**
   * The name of the community the program is in.
   * @type {string}
   * @memberof PaymentOptionsEnrollments
   */
  communityName: string
  /**
   * The date the first semester begins for the program.
   * @type {Date}
   * @memberof PaymentOptionsEnrollments
   */
  semesterOneStartDate: Date
  /**
   * Unique identifier for the program.
   * @type {number}
   * @memberof PaymentOptionsEnrollments
   */
  programKey: number
  /**
   * The unique identifier for the student the enrollment is for.
   * @type {number}
   * @memberof PaymentOptionsEnrollments
   */
  studentKey: number
}

export function PaymentOptionsEnrollmentsFromJSON(
  json: any
): PaymentOptionsEnrollments {
  return PaymentOptionsEnrollmentsFromJSONTyped(json, false)
}

export function PaymentOptionsEnrollmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentOptionsEnrollments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentFirstName: json['studentFirstName'],
    programType: json['programType'],
    communityName: json['communityName'],
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    programKey: json['programKey'],
    studentKey: json['studentKey'],
  }
}

export function PaymentOptionsEnrollmentsToJSON(
  value?: PaymentOptionsEnrollments | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentFirstName: value.studentFirstName,
    programType: value.programType,
    communityName: value.communityName,
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    programKey: value.programKey,
    studentKey: value.studentKey,
  }
}
