/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface SendTuitionInvoiceRequestBody
 */
export interface SendTuitionInvoiceRequestBody {
  /**
   * Flag to indicate if the invoice is being sent from or for a tutor or director. If not provided, the invoice is assumed to be sent by or for a director.
   * @type {boolean}
   * @memberof SendTuitionInvoiceRequestBody
   */
  isTutorInvoice?: boolean
  /**
   * Any payment instructions or notes for the family to see when they receive the invoice.
   * @type {string}
   * @memberof SendTuitionInvoiceRequestBody
   */
  comment?: string
  /**
   * Unique key of an invoice.
   * @type {number}
   * @memberof SendTuitionInvoiceRequestBody
   */
  invoiceKey: number
}

export function SendTuitionInvoiceRequestBodyFromJSON(
  json: any
): SendTuitionInvoiceRequestBody {
  return SendTuitionInvoiceRequestBodyFromJSONTyped(json, false)
}

export function SendTuitionInvoiceRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SendTuitionInvoiceRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isTutorInvoice: !exists(json, 'isTutorInvoice')
      ? undefined
      : json['isTutorInvoice'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    invoiceKey: json['invoiceKey'],
  }
}

export function SendTuitionInvoiceRequestBodyToJSON(
  value?: SendTuitionInvoiceRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    isTutorInvoice: value.isTutorInvoice,
    comment: value.comment,
    invoiceKey: value.invoiceKey,
  }
}
