import React, { PropsWithChildren, useState } from 'react'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { AuthFormInfo } from '../Login/AuthFormCard'
import { InvitationType, InviteValidity } from '../../swagger'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { OperationIds } from '../../swagger/operationIdEnum'
import { agreements, extractedErrorObject, invitesApi } from '../../api/swagger'
import { useTranslation } from 'react-i18next'
import { LoadingContext } from './LoadingContext'
import { useAuth } from '../Routes/AuthProvider'

export enum InviteStep {
  SignUp,
  SignUpInformation,
  EmailVerification,
  AccountInfo,
}

interface UserInfo {
  firstName: string
  lastName: string
  phone: string
}

interface UserInfoValidity {
  firstName: FieldValidity
  lastName: FieldValidity
  phone: FieldValidity
}

const emailVerificationCodeLength = 16

export const defaultInviteContextValue = {
  /** The current step of the Invite flow.  */
  step: InviteStep.SignUp,
  /** Navigate to the provided step or the previous one if not provided */
  /** https://www.youtube.com/watch?v=wZv62ShoStY&t=54s */
  goToPrevStep: (newStep?: InviteStep): void => {
    console.warn(
      `The InviteContext.goToPrevStep was called with value ${newStep}. Did you forget to use an InviteProvider?`
    )
  },
  /** Navigate to the provided step or the next one if not provided */
  goToNextStep: (newStep?: InviteStep): void => {
    console.warn(
      `The InviteContext.goToNextStep was called with value ${newStep}. Did you forget to use a InviteProvider?`
    )
  },
  /** Determines if the verification code was resent to display a message */
  verificationCodeHasBeenResent: false,
  /** Update verification code has been sent, or reset */
  updateVerificationCodeHasBeenResent: (value: boolean): void => {
    console.warn(
      `The InviteContext.updateVerificationCodeHasBeenResent was called with value ${value}. Did you forget to use a InviteProvider?`
    )
  },
  /** The presence of the error beyond empty means it ought to be displayed */
  error: '',
  /** Update the error to be new. Used in useInvitationMethods to reset the error */
  updateError: (newError: string): void => {
    console.warn(
      `The InviteContext.updateError was called with value ${newError}. Did you forget to use a InviteProvider?`
    )
  },
  /** Verification code 16 characters in length */
  emailVerificationToken: '',
  /** Update method for emailVerificationToken */
  updateEmailVerificationToken: (newToken: string): void => {
    console.warn(
      `The InviteContext.updateEmailVerificationToken was called with value ${newToken}. Did you forget to use a InviteProvider?`
    )
  },
  /**
   * Authentication information provided on signup. The update is indexed
   * by the id of the field which ought to be a property of the authenticationInfo
   */
  authenticationInfo: {
    email: '',
    password: '',
    passwordConfirm: '',
  } as AuthFormInfo,
  updateAuthInfo: (updatedAuthInfo: AuthFormInfo): void => {
    console.warn(
      `The InviteContext.handleAuthInfoUpdate was called with value ${updatedAuthInfo}. Did you forget to use a InviteProvider?`
    )
  },
  /** Update method for authentication information changes */
  handleAuthInfoUpdate: (id: string, value: string): void => {
    console.warn(
      `The InviteContext.handleAuthInfoUpdate was called with values ${id} and ${value}. Did you forget to use a InviteProvider?`
    )
  },
  /** Information related to the invitation flow.
   *
   * FirstName, lastName, and phone used during the SignUpInformation step
   */
  userInfo: {
    firstName: '',
    lastName: '',
    phone: '',
  } as UserInfo,
  /** Update method for userInfo. */
  updateUserInfo: (updatedUserInfo: UserInfo): void => {
    console.warn(
      `The InviteContext.updateUserInfo was called with value ${updatedUserInfo}. Did you forget to use a InviteProvider?`
    )
  },
  /**
   * Determined by a length comparison of the emailVerificationToken to the
   * emailVerificationCodeLength
   *
   * The verification code is initially empty, so this ought to be false
   */
  isVerificationInputLongEnough: false,
  /**
   * A validity object for each field within the invite flow
   */
  userInfoValidity: {
    firstName: { input: true },
    lastName: { input: true },
    phone: { input: true },
  } as UserInfoValidity,
  updateUserInfoValidity: (newValidity: UserInfoValidity): void => {
    console.warn(
      `The InviteContext.updateUserInfoValidity was called with values ${newValidity}. Did you forget to use a InviteProvider?`
    )
  },
  handleUserInfoChange: (id: string, value: string): void => {
    console.warn(
      `The InviteContext.handleUserInfoChange was called with values ${id} and ${value}. Did you forget to use a InviteProvider?`
    )
  },
  /**
   * Update the step to the first in the flow and reset the context values
   * to their defaults.
   */
  resetContextToDefaults: (): void => {
    console.warn(
      `The InviteContext.resetContextToDefaults was called. Did you forget to use a InviteProvider?`
    )
  },
  /** validity from fetchInviteValidity */
  inviteValidity: InviteValidity.Valid,
  /** invitationType from fetchInviteValidity */
  invitationType: undefined as InvitationType | undefined,
  /**
   * The acceptedEmail from fetchInviteValidity. emailForInvitation sounds more
   * applicable.
   */
  emailForInvitation: '',
  updateInvitationValidity: (validity: InviteValidity): void => {
    console.warn(
      `The InviteContext.updateInvitationValidity was called with value ${validity}. Did you forget to use a InviteProvider?`
    )
  },
  updateInvitationType: (type: InvitationType | undefined): void => {
    console.warn(
      `The InviteContext.updateInvitationType was called with value ${type}. Did you forget to use a InviteProvider?`
    )
  },
  updateInvitationEmail: (email: string): void => {
    console.warn(
      `The InviteContext.updateInvitationEmail was called with value ${email}. Did you forget to use a InviteProvider?`
    )
  },

  fetchInviteValidityLoadingId: OperationIds.FetchInviteValidity as string,
  fetchInviteValidity: async (inviteKey: string): Promise<void> => {
    console.warn(
      `The InviteContext.fetchInviteValidity was called with value ${inviteKey}. Did you forget to use a InviteProvider?`
    )
  },
  completeAgreementLoadingId: OperationIds.CompleteAgreement as string,
  completeAgreement: async (uuid: string): Promise<void> => {
    console.warn(
      `The InviteContext.completeAgreement was called with value ${uuid}. Did you forget to use a InviteProvider?`
    )
  },
}

const InviteContext = React.createContext(defaultInviteContextValue)

export const useInviteContext = (): typeof defaultInviteContextValue =>
  React.useContext(InviteContext)

const InviteProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { InviteContext: InviteContextLoadingIds } = useLoadingIds()
  const { t } = useTranslation()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const { updateActingAs } = useAuth()
  const errorMessage = t(
    'InviteContext.Error',
    'An unknown error occurred while attempting validating the invite.'
  )

  const [step, setStep] = useState(InviteStep.SignUp)

  const [verificationCodeHasBeenResent, setVerificationCodeHasBeenResent] =
    useState(false)

  const updateVerificationCodeHasBeenResent = (value: boolean) => {
    setVerificationCodeHasBeenResent(value)
  }

  const goToPrevStep = (newStep?: InviteStep) => {
    /** Get rid of errors when going back */
    setError('')
    /** Possibly update the current state to default */
    setVerificationCodeHasBeenResent(false)
    setStep((prevStep) => newStep || prevStep - 1)
  }

  const goToNextStep = (newStep?: InviteStep) => {
    setStep((prevStep) => newStep || prevStep + 1)
  }

  const [error, setError] = useState('')
  const [emailVerificationToken, setEmailVerificationToken] = useState('')

  const [authenticationInfo, setAuthenticationInfo] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  })

  const updateAuthInfo = (updatedAuthInfo: AuthFormInfo) => {
    setAuthenticationInfo(updatedAuthInfo)
  }

  const handleAuthInfoUpdate = (id: string, value: string) => {
    setAuthenticationInfo({
      ...authenticationInfo,
      [id]: value,
    })
  }

  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: '',
    lastName: '',
    phone: '',
  })

  const [userInfoValidity, setUserInfoValidity] = useState<UserInfoValidity>({
    firstName: {
      input: true,
    },
    lastName: {
      input: true,
    },
    phone: {
      input: true,
    },
  })

  const updateUserInfoValidity = (newValidity: UserInfoValidity) => {
    setUserInfoValidity(newValidity)
  }

  const handleUserInfoChange = (id: string, value: string) => {
    if (id === 'phone') {
      value = value.replace(/[A-Z]/gi, '')
    }
    updateUserInfo({
      ...userInfo,
      [id]: value,
    })
  }

  const updateError = (newError: string) => {
    setError(newError)
  }

  const updateEmailVerificationToken = (token: string) => {
    const trimmedToken = token.trim()
    setEmailVerificationToken(trimmedToken)
  }

  const updateUserInfo = (updatedUserInfo: UserInfo) => {
    setUserInfo(updatedUserInfo)
  }

  const isVerificationInputLongEnough =
    emailVerificationToken.length >= emailVerificationCodeLength

  const [invitationType, setInvitationType] = useState<
    InvitationType | undefined
  >(undefined)
  const [emailForInvitation, setEmailForInvitation] = useState('')
  const [inviteValidity, setInviteValidity] = useState(InviteValidity.Valid)

  const updateInvitationValidity = (validity: InviteValidity) => {
    setInviteValidity(validity)
  }
  const updateInvitationType = (type: InvitationType | undefined) => {
    setInvitationType(type)
  }
  const updateInvitationEmail = (email: string) => {
    setEmailForInvitation(email)
  }

  /** We should only need to load this once per invitation, so we'll call in a useMountEffect */
  const fetchInviteValidity = async (inviteKey: string) => {
    try {
      const { validity, invitationType, acceptedEmail } =
        await invitesApi.fetchInviteValidity({
          inviteUUID: inviteKey,
        })

      updateInvitationEmail(acceptedEmail ?? '')
      updateInvitationType(invitationType)
      updateInvitationValidity(validity)
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      updateError(errorObj.message)
    }
  }

  const completeAgreementLoadingId = InviteContextLoadingIds.completeAgreement
  const completeAgreement = async (uuid: string) => {
    try {
      const { actorKey, userKey } = await agreements.completeAgreement({
        uuid,
      })
      await updateActingAs(actorKey)
      void userKey
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      updateError(errorObj.message)
    }
  }

  const resetContextToDefaults = () => {
    /** No more errors if resetting */
    updateError('')
    /** Step ought to be the first one */
    setStep(defaultInviteContextValue.step)
    /** And all previous default values */
    setEmailVerificationToken(defaultInviteContextValue.emailVerificationToken)
    setUserInfo(defaultInviteContextValue.userInfo)
    setVerificationCodeHasBeenResent(
      defaultInviteContextValue.verificationCodeHasBeenResent
    )
    setUserInfoValidity(defaultInviteContextValue.userInfoValidity)
    setAuthenticationInfo(defaultInviteContextValue.authenticationInfo)

    updateInvitationValidity(defaultInviteContextValue.inviteValidity)
    updateInvitationType(defaultInviteContextValue.invitationType)
    updateInvitationEmail(defaultInviteContextValue.emailForInvitation)

    /** And signout since we might be signed in */
    addLoadingIds([InviteContextLoadingIds.logout])
  }

  const value = {
    step,
    goToPrevStep,
    goToNextStep,
    verificationCodeHasBeenResent,
    updateVerificationCodeHasBeenResent,
    error,
    updateError,
    emailVerificationToken,
    updateEmailVerificationToken,
    userInfo,
    updateUserInfo,
    isVerificationInputLongEnough,
    userInfoValidity,
    updateUserInfoValidity,
    handleUserInfoChange,
    resetContextToDefaults,
    authenticationInfo,
    updateAuthInfo,
    handleAuthInfoUpdate,
    invitationType,
    emailForInvitation,
    inviteValidity,
    updateInvitationValidity,
    updateInvitationType,
    updateInvitationEmail,
    fetchInviteValidityLoadingId: InviteContextLoadingIds.fetchInviteValidity,
    fetchInviteValidity,
    completeAgreementLoadingId,
    completeAgreement,
  }

  return (
    <InviteContext.Provider value={value}>{children}</InviteContext.Provider>
  )
}

export default InviteProvider
