/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface TuitionPaymentInfo
 */
export interface TuitionPaymentInfo {
  /**
   * The unique identifier of the tuition payment.
   * @type {number}
   * @memberof TuitionPaymentInfo
   */
  tuitionPaymentKey: number
  /**
   * The unique identifier of the student whom a payment was made on behalf of.
   * @type {number}
   * @memberof TuitionPaymentInfo
   */
  studentKey: number
  /**
   * The unique identifier of the program the student is enrolled in.
   * @type {number}
   * @memberof TuitionPaymentInfo
   */
  programKey: number
  /**
   * The student's first name.
   * @type {string}
   * @memberof TuitionPaymentInfo
   */
  studentName: string
  /**
   *
   * @type {ProgramType}
   * @memberof TuitionPaymentInfo
   */
  programType: ProgramType
  /**
   * The amount applied for this item.
   * @type {number}
   * @memberof TuitionPaymentInfo
   */
  amount: number
  /**
   * If true, this payment is considered a discount that was applied by the director. A discount is treated the same way as a payment, but should be labeled as 'Discount' within the UI.
   * @type {boolean}
   * @memberof TuitionPaymentInfo
   */
  isDirectorDiscount: boolean
}

export function TuitionPaymentInfoFromJSON(json: any): TuitionPaymentInfo {
  return TuitionPaymentInfoFromJSONTyped(json, false)
}

export function TuitionPaymentInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPaymentInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    tuitionPaymentKey: json['tuitionPaymentKey'],
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    studentName: json['studentName'],
    programType: ProgramTypeFromJSON(json['programType']),
    amount: json['amount'],
    isDirectorDiscount: json['isDirectorDiscount'],
  }
}

export function TuitionPaymentInfoToJSON(
  value?: TuitionPaymentInfo | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    tuitionPaymentKey: value.tuitionPaymentKey,
    studentKey: value.studentKey,
    programKey: value.programKey,
    studentName: value.studentName,
    programType: ProgramTypeToJSON(value.programType),
    amount: value.amount,
    isDirectorDiscount: value.isDirectorDiscount,
  }
}
