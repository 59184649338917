/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PublicContact,
  PublicContactFromJSON,
  PublicContactToJSON,
  PublicInternationalCommunityLocation,
  PublicInternationalCommunityLocationFromJSON,
  PublicInternationalCommunityLocationToJSON,
  PublicProgram1,
  PublicProgram1FromJSON,
  PublicProgram1ToJSON,
} from './'

/**
 *
 * @export
 * @interface PublicInternationalCommunity
 */
export interface PublicInternationalCommunity {
  /**
   *
   * @type {string}
   * @memberof PublicInternationalCommunity
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof PublicInternationalCommunity
   */
  id: string
  /**
   *
   * @type {PublicContact}
   * @memberof PublicInternationalCommunity
   */
  contact: PublicContact
  /**
   *
   * @type {PublicInternationalCommunityLocation}
   * @memberof PublicInternationalCommunity
   */
  location: PublicInternationalCommunityLocation
  /**
   *
   * @type {Array<PublicProgram1>}
   * @memberof PublicInternationalCommunity
   */
  programs: Array<PublicProgram1>
}

export function PublicInternationalCommunityFromJSON(
  json: any
): PublicInternationalCommunity {
  return PublicInternationalCommunityFromJSONTyped(json, false)
}

export function PublicInternationalCommunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicInternationalCommunity {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    id: json['id'],
    contact: PublicContactFromJSON(json['contact']),
    location: PublicInternationalCommunityLocationFromJSON(json['location']),
    programs: (json['programs'] as Array<any>).map(PublicProgram1FromJSON),
  }
}

export function PublicInternationalCommunityToJSON(
  value?: PublicInternationalCommunity | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    id: value.id,
    contact: PublicContactToJSON(value.contact),
    location: PublicInternationalCommunityLocationToJSON(value.location),
    programs: (value.programs as Array<any>).map(PublicProgram1ToJSON),
  }
}
