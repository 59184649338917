/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  UpdateLicensingEnrollmentRequestBodyLicensingEnrollments,
  UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON,
  UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON,
} from './'

/**
 * The body to update licensing enrollment, receives an array of objects with the required identifiers: studentKey, programKey and feeTypeKey that will determine the records to be updated.
 * @export
 * @interface UpdateLicensingEnrollmentRequestBody
 */
export interface UpdateLicensingEnrollmentRequestBody {
  /**
   * An array of objects of type licensingEnrollment
   * @type {Array<UpdateLicensingEnrollmentRequestBodyLicensingEnrollments>}
   * @memberof UpdateLicensingEnrollmentRequestBody
   */
  licensingEnrollments: Array<UpdateLicensingEnrollmentRequestBodyLicensingEnrollments>
}

export function UpdateLicensingEnrollmentRequestBodyFromJSON(
  json: any
): UpdateLicensingEnrollmentRequestBody {
  return UpdateLicensingEnrollmentRequestBodyFromJSONTyped(json, false)
}

export function UpdateLicensingEnrollmentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateLicensingEnrollmentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    licensingEnrollments: (json['licensingEnrollments'] as Array<any>).map(
      UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON
    ),
  }
}

export function UpdateLicensingEnrollmentRequestBodyToJSON(
  value?: UpdateLicensingEnrollmentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    licensingEnrollments: (value.licensingEnrollments as Array<any>).map(
      UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON
    ),
  }
}
