/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * A recipient for the agreement.
 * @export
 * @interface AgreementRecipient
 */
export interface AgreementRecipient {
  /**
   * Label used to identify the recipient, typically by the recipient's role in Classical Conversations.
   * @type {string}
   * @memberof AgreementRecipient
   */
  label: string
  /**
   * Email address of the agreement recipient.
   * @type {string}
   * @memberof AgreementRecipient
   */
  email: string
}

export function AgreementRecipientFromJSON(json: any): AgreementRecipient {
  return AgreementRecipientFromJSONTyped(json, false)
}

export function AgreementRecipientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementRecipient {
  if (json === undefined || json === null) {
    return json
  }
  return {
    label: json['label'],
    email: json['email'],
  }
}

export function AgreementRecipientToJSON(
  value?: AgreementRecipient | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    label: value.label,
    email: value.email,
  }
}
