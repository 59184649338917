import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import BasicModal from '../Modals/BasicModal'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { useTheme } from '@mui/material'

interface ViewBillingInfoModalProps {
  transactionType: string
  date: Date
  status: string
  amount: string
  orderId: string
  isOpen: boolean
  students: string[]
  onClose: () => void
}

const ViewBillingInfoModal: React.FunctionComponent<
  ViewBillingInfoModalProps
> = ({
  transactionType,
  date,
  status,
  amount,
  orderId,
  isOpen,
  students,
  onClose,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <BasicModal
      isOpen={isOpen}
      dialogTitle={t(
        'Account.Billing.Transaction.Title',
        '{{- transactionType}} on {{- date}}',
        {
          transactionType,
          date,
        }
      )}
      handleFormSubmit={() => null}
      ariaLabel="Billing History View Modal"
      maxWidth="sm"
      dialogContent={
        <>
          <Typography
            color={theme.palette.primary.main}
            variant={'body1'}
            component={'h4'}
          >
            <Box mb={0.5}>
              <AdjacentLabels
                leftLabel={t(
                  'Account.Billing.Transaction.PaymentMethod',
                  'Payment Method'
                )}
                rightLabel={transactionType}
              />
            </Box>
            <Box mb={0.5}>
              <AdjacentLabels
                leftLabel={t('Account.Billing.Transaction.Amount', 'Amount')}
                rightLabel={amount}
              />
            </Box>
            <Box mb={0.5}>
              <AdjacentLabels
                leftLabel={t('Account.Billing.Transaction.Status', 'Status')}
                rightLabel={status}
              />
            </Box>
            <Box mb={1.5}>
              <AdjacentLabels
                leftLabel={t(
                  'Account.Billing.Transaction.OrderNumber',
                  'Order Number'
                )}
                rightLabel={orderId ? orderId : 'No Order Number'}
              />
            </Box>

            <Divider />

            {/* Commenting out for now until we have NSF fees figured out.  See CCP1-2778
              
              <Box mt={1.5} mb={0.5}>
              <AdjacentLabels
                  leftLabel={t('Account.Billing.Transaction.AppliedTo', 'Applied To')}
                  rightLabel={transactionType}
                />
              </Box> */}
            <Box mt={1.5} mb={0.5}>
              <AdjacentLabels
                leftLabel={
                  students.length === 1
                    ? t('Account.Billing.Transaction.Student', 'Student')
                    : t('Account.Billing.Transaction.Students', 'Students')
                }
                rightLabel={
                  students.length === 1
                    ? students[0]
                    : getCommaSeparatedList(students)
                }
              />
            </Box>
          </Typography>
        </>
      }
      dialogActions={
        <ContainedButton
          id="billingHistoryViewModalClose"
          variant={ContainedButtonVariant.Close}
          onClick={onClose}
        />
      }
    />
  )
}

export default ViewBillingInfoModal
