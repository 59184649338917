/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ChangeEmailConfirmTokenRequestBody,
  ChangeEmailConfirmTokenRequestBodyToJSON,
  ChangeEmailRequestBody,
  ChangeEmailRequestBodyToJSON,
  ChangePasswordRequestBody,
  ChangePasswordRequestBodyToJSON,
  Credentials,
  FetchShopifyMultipassUrlResponse,
  FetchShopifyMultipassUrlResponseFromJSON,
  LoginForInviteRequestBody,
  LoginForInviteRequestBodyToJSON,
  LoginResponse,
  LoginResponseFromJSON,
  LoginUpdateRequestBody,
  LoginUpdateRequestBodyToJSON,
  ResetPasswordRequestBody,
  ResetPasswordRequestBodyToJSON,
  SignUpConfirmTokenRequestBody,
  SignUpConfirmTokenRequestBodyToJSON,
  SignUpSendTokenRequestBody,
  SignUpSendTokenRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface ChangeEmailRequest extends runtime.BaseRequestParameters {
  body?: ChangeEmailRequestBody
}

export interface ChangePasswordRequest extends runtime.BaseRequestParameters {
  body?: ChangePasswordRequestBody
}

export interface ConfirmTokenRequest extends runtime.BaseRequestParameters {
  body?: ChangeEmailConfirmTokenRequestBody
}

export interface ConfirmTokenForSignupRequest
  extends runtime.BaseRequestParameters {
  body?: SignUpConfirmTokenRequestBody
}

export interface LoginRequest extends runtime.BaseRequestParameters {
  body?: Credentials
}

export interface LoginForInviteRequest extends runtime.BaseRequestParameters {
  body?: LoginForInviteRequestBody
}

export interface LogoutAllSessionsRequest
  extends runtime.BaseRequestParameters {
  userId: string
}

export interface ResetPasswordRequest extends runtime.BaseRequestParameters {
  body?: ResetPasswordRequestBody
}

export interface SendTokenForSignupRequest
  extends runtime.BaseRequestParameters {
  body?: SignUpSendTokenRequestBody
}

export interface UpdateLoginRequest extends runtime.BaseRequestParameters {
  body?: LoginUpdateRequestBody
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   * Send a one-time change email token to the provided email. The token will be added to the current authenticated session to verify in the \'confirmTokenForChangeEmail\' route
   * Send a one-time change email token.
   */
  async changeEmailRaw(
    requestParameters: ChangeEmailRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/changeEmail/sendToken`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ChangeEmailRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Send a one-time change email token to the provided email. The token will be added to the current authenticated session to verify in the \'confirmTokenForChangeEmail\' route
   * Send a one-time change email token.
   */
  async changeEmail(requestParameters: ChangeEmailRequest): Promise<void> {
    await this.changeEmailRaw(requestParameters)
  }

  /**
   * Change or reset a user account password. If requester is logged in, an \'oldPassword\' must be provided and match the password associated with the requester\'s user account. If requester is not logged in, a valid, not expired resetToken must be provided that matches the token sent to their email address. If all checks out, the requester\'s password will be updated on their user account. If the requester was logged in, they must provide their oldPassword, and the new password must not be the same as the old password. When all checks out, their current sessions will be terminated and require them to log in again..
   * Change or reset a user account password.
   */
  async changePasswordRaw(
    requestParameters: ChangePasswordRequest
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login/changePassword`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ChangePasswordRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    )
  }

  /**
   * Change or reset a user account password. If requester is logged in, an \'oldPassword\' must be provided and match the password associated with the requester\'s user account. If requester is not logged in, a valid, not expired resetToken must be provided that matches the token sent to their email address. If all checks out, the requester\'s password will be updated on their user account. If the requester was logged in, they must provide their oldPassword, and the new password must not be the same as the old password. When all checks out, their current sessions will be terminated and require them to log in again..
   * Change or reset a user account password.
   */
  async changePassword(
    requestParameters: ChangePasswordRequest
  ): Promise<LoginResponse> {
    const response = await this.changePasswordRaw(requestParameters)
    return await response.value()
  }

  /**
   * If the change email token validates successfully, the existing user email will be updated with the new email that was supplied in the SendChangeEmailToken route.  You will receive a 400 if there is no `changeEmailToken` found in the current user session, or if the new email is currently in use,   If a `ChangeEmailTokenError` occurs, the user should be prompted to retry and receive a new token.  A 401 will occur if the token has expired or does not match the token in session.
   * Finish change email request started by user
   */
  async confirmTokenRaw(
    requestParameters: ConfirmTokenRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/changeEmail/confirmToken`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ChangeEmailConfirmTokenRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * If the change email token validates successfully, the existing user email will be updated with the new email that was supplied in the SendChangeEmailToken route.  You will receive a 400 if there is no `changeEmailToken` found in the current user session, or if the new email is currently in use,   If a `ChangeEmailTokenError` occurs, the user should be prompted to retry and receive a new token.  A 401 will occur if the token has expired or does not match the token in session.
   * Finish change email request started by user
   */
  async confirmToken(requestParameters: ConfirmTokenRequest): Promise<void> {
    await this.confirmTokenRaw(requestParameters)
  }

  /**
   * If the username, password, and one-time password validate successfully, you will have a new user account created and get a session cookie that will eventually expire. The invite that enabled sending the token will be bound to this new account, and it can no longer be used to create further accounts or be bound to a different account.  You will receive a 400 if there is already an existing user, the `signupToken` does not exist, there are password formatting errors, or the agreement/family invite is not valid.  If `UserNameAlreadyExists` error occurs, the user should be redirected to the invite login, as opposed to signup.  If a `SignupTokenError` occurs, the user should be prompted to retry and receive a new token.  A 401 will occur if the token has expired or does not match the token in session.
   * Create a new account to respond to an invite sent by `inviteFamily` or `inviteTeamMember`
   */
  async confirmTokenForSignupRaw(
    requestParameters: ConfirmTokenForSignupRequest
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/signup/confirmToken`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SignUpConfirmTokenRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    )
  }

  /**
   * If the username, password, and one-time password validate successfully, you will have a new user account created and get a session cookie that will eventually expire. The invite that enabled sending the token will be bound to this new account, and it can no longer be used to create further accounts or be bound to a different account.  You will receive a 400 if there is already an existing user, the `signupToken` does not exist, there are password formatting errors, or the agreement/family invite is not valid.  If `UserNameAlreadyExists` error occurs, the user should be redirected to the invite login, as opposed to signup.  If a `SignupTokenError` occurs, the user should be prompted to retry and receive a new token.  A 401 will occur if the token has expired or does not match the token in session.
   * Create a new account to respond to an invite sent by `inviteFamily` or `inviteTeamMember`
   */
  async confirmTokenForSignup(
    requestParameters: ConfirmTokenForSignupRequest
  ): Promise<LoginResponse> {
    const response = await this.confirmTokenForSignupRaw(requestParameters)
    return await response.value()
  }

  /**
   * Confirms/signs login information to connect users from NC3 to Shopify.
   */
  async fetchShopifyMultipassUrlRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<FetchShopifyMultipassUrlResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/shopify/multipass-login`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchShopifyMultipassUrlResponseFromJSON(jsonValue)
    )
  }

  /**
   * Confirms/signs login information to connect users from NC3 to Shopify.
   */
  async fetchShopifyMultipassUrl(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<FetchShopifyMultipassUrlResponse> {
    const response = await this.fetchShopifyMultipassUrlRaw(requestParameters)
    return await response.value()
  }

  /**
   * If the password checks out, you get a session cookie. That cookie will eventually expire, so be prepared for that. Don\'t forget to include credentials with your `fetch` requests.  You will also receive a list of permissions. These are intended to be used to conditionally display UI based on actions the user might be able to take in general. However, just because a user can take an action, this doesn\'t mean they can take that action with regard to a specific object: a community owner has permission to edit communities, but it\'s limited to their community. To address this matter of permission scope, specific resource details responses will provide more context-sensitive permissions lists under the `meta.permissions` field (see the `DetailMeta` model docs for details). These should be used when deciding whether to, say, show the Edit button an a Community Details page.  If no user with that username exists, or one does but the provided password is wrong, then you\'ll get a 401 Unauthorized response. We intentionally don\'t distinguish these two cases in order to prevent people from enumerating the list of registered accounts.
   * Delivers a session cookie and a rough notion of user capabilities.
   */
  async loginRaw(
    requestParameters: LoginRequest
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.body as any,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    )
  }

  /**
   * If the password checks out, you get a session cookie. That cookie will eventually expire, so be prepared for that. Don\'t forget to include credentials with your `fetch` requests.  You will also receive a list of permissions. These are intended to be used to conditionally display UI based on actions the user might be able to take in general. However, just because a user can take an action, this doesn\'t mean they can take that action with regard to a specific object: a community owner has permission to edit communities, but it\'s limited to their community. To address this matter of permission scope, specific resource details responses will provide more context-sensitive permissions lists under the `meta.permissions` field (see the `DetailMeta` model docs for details). These should be used when deciding whether to, say, show the Edit button an a Community Details page.  If no user with that username exists, or one does but the provided password is wrong, then you\'ll get a 401 Unauthorized response. We intentionally don\'t distinguish these two cases in order to prevent people from enumerating the list of registered accounts.
   * Delivers a session cookie and a rough notion of user capabilities.
   */
  async login(requestParameters: LoginRequest): Promise<LoginResponse> {
    const response = await this.loginRaw(requestParameters)
    return await response.value()
  }

  /**
   * If the `signupType` is `family`, and the family enrollment invitation identified by `typeIdentifier` is not active, a 400 will be returned. If the `signupType` is that of `agreement`, and the agreement identified by `typeIdentifier` is not active, a 400 will be returned.  After verifying the invitation is still valid and not yet bound to a different account, this endpoint behaves like the login endpoint and returns an identical response. No other account may then be used to accept the identified invitation.
   * Bind an invite extended by `inviteFamily` or `inviteTeamMember` to an existing account so it can respond to the invite
   */
  async loginForInviteRaw(
    requestParameters: LoginForInviteRequest
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invite/login`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: LoginForInviteRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    )
  }

  /**
   * If the `signupType` is `family`, and the family enrollment invitation identified by `typeIdentifier` is not active, a 400 will be returned. If the `signupType` is that of `agreement`, and the agreement identified by `typeIdentifier` is not active, a 400 will be returned.  After verifying the invitation is still valid and not yet bound to a different account, this endpoint behaves like the login endpoint and returns an identical response. No other account may then be used to accept the identified invitation.
   * Bind an invite extended by `inviteFamily` or `inviteTeamMember` to an existing account so it can respond to the invite
   */
  async loginForInvite(
    requestParameters: LoginForInviteRequest
  ): Promise<LoginResponse> {
    const response = await this.loginForInviteRaw(requestParameters)
    return await response.value()
  }

  /**
   * Calling this when you are logged in destroys the session associated with the request. (Calling this when you\'re not logged in trivially succeeds: congrats, you\'re logged out.)  In future, this will likely accept a parameter to let you specify a user to terminate all the sessions of, which will let you log yourself out everywhere, not just from the current device.
   * Log out by terminating the current session.
   */
  async logoutRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<string>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Calling this when you are logged in destroys the session associated with the request. (Calling this when you\'re not logged in trivially succeeds: congrats, you\'re logged out.)  In future, this will likely accept a parameter to let you specify a user to terminate all the sessions of, which will let you log yourself out everywhere, not just from the current device.
   * Log out by terminating the current session.
   */
  async logout(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<string> {
    const response = await this.logoutRaw(requestParameters)
    return await response.value()
  }

  /**
   * Calling this when you are logged in destroys the session associated with the request. (Calling this when you\'re not logged in trivially succeeds: congrats, you\'re logged out.)  In future, this will likely accept a parameter to let you specify a user to terminate all the sessions of, which will let you log yourself out everywhere, not just from the current device.
   * Log out all the user\'s sessions
   */
  async logoutAllSessionsRaw(
    requestParameters: LogoutAllSessionsRequest
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling logoutAllSessions.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Calling this when you are logged in destroys the session associated with the request. (Calling this when you\'re not logged in trivially succeeds: congrats, you\'re logged out.)  In future, this will likely accept a parameter to let you specify a user to terminate all the sessions of, which will let you log yourself out everywhere, not just from the current device.
   * Log out all the user\'s sessions
   */
  async logoutAllSessions(
    requestParameters: LogoutAllSessionsRequest
  ): Promise<string> {
    const response = await this.logoutAllSessionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Send a one-time reset password token to the provided username. An anonymous session will be stored with the reset token to verify in the `changePassword` route.
   * Send a one-time reset password token.
   */
  async resetPasswordRaw(
    requestParameters: ResetPasswordRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login/resetPassword`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ResetPasswordRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Send a one-time reset password token to the provided username. An anonymous session will be stored with the reset token to verify in the `changePassword` route.
   * Send a one-time reset password token.
   */
  async resetPassword(requestParameters: ResetPasswordRequest): Promise<void> {
    await this.resetPasswordRaw(requestParameters)
  }

  /**
   * If the username is not already in the system, and the email provided for the username validates successfully, then an email with be sent to the specified username with a one-time password. An anonymous session will be stored with the one time-password to verify in the `confirmTokenForSignup` route.
   * Given an invite, send a one-time password to enable creating a new account
   */
  async sendTokenForSignupRaw(
    requestParameters: SendTokenForSignupRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/signup/sendToken`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SignUpSendTokenRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * If the username is not already in the system, and the email provided for the username validates successfully, then an email with be sent to the specified username with a one-time password. An anonymous session will be stored with the one time-password to verify in the `confirmTokenForSignup` route.
   * Given an invite, send a one-time password to enable creating a new account
   */
  async sendTokenForSignup(
    requestParameters: SendTokenForSignupRequest
  ): Promise<void> {
    await this.sendTokenForSignupRaw(requestParameters)
  }

  /**
   * Check if user is already logged, then check if the actorKey in body is a valid actorKey, then check if currentUser is the user requester and finally update the login session
   * Allows a user to update what role they are currently acting as.
   */
  async updateLoginRaw(
    requestParameters: UpdateLoginRequest
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/login`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: LoginUpdateRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    )
  }

  /**
   * Check if user is already logged, then check if the actorKey in body is a valid actorKey, then check if currentUser is the user requester and finally update the login session
   * Allows a user to update what role they are currently acting as.
   */
  async updateLogin(
    requestParameters: UpdateLoginRequest
  ): Promise<LoginResponse> {
    const response = await this.updateLoginRaw(requestParameters)
    return await response.value()
  }
}
