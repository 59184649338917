import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Card from '@mui/material/Card'
import { RegionContentTag } from '../../swagger'
import Header, { HeaderVariant } from '../Elements/Header'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'

const CheckboxFormGroup = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
  justifyContent: 'flex-start',
})) as typeof FormControl

const ContentTagFormCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 4, 4),
  maxWidth: 1200,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
}))

const ContentTagsCard: React.FC<{
  contentTags?: RegionContentTag[]
  isFieldDisabled: boolean
  handleContentTagsChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ contentTags, isFieldDisabled, handleContentTagsChange }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ContentTagFormCard>
      <Header
        id="contentTagsCardHeader"
        headerName={t('ContentTagsCard.Header.ContentTags', 'Content Tags')}
        component="h2"
        variant={HeaderVariant.Card}
      />
      <CheckboxFormGroup component="fieldset">
        <FormGroup>
          {contentTags ? (
            <Grid container spacing={1}>
              {contentTags &&
                contentTags.map((tag) => (
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    spacing={3}
                    key={tag.contentTagKey}
                  >
                    <FormControlLabel
                      sx={{
                        lineHeight: 1.4,
                      }}
                      control={
                        <Checkbox
                          data-testid="content-tag-checkbox"
                          sx={{
                            color: theme.palette.textOrIcon.checkbox,
                          }}
                          size="medium"
                          onChange={handleContentTagsChange}
                          key={`${tag.tag}-${tag.contentTagKey}`}
                          name={tag.tag}
                          value={tag.contentTagKey}
                          inputProps={{ 'aria-labelledby': `${tag.tag}` }}
                          disabled={isFieldDisabled}
                          checked={tag.enabledInRegion}
                        />
                      }
                      label={tag.tag}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </FormGroup>
      </CheckboxFormGroup>
    </ContentTagFormCard>
  )
}
export default ContentTagsCard
