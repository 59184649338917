/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface StudentForTranscript
 */
export interface StudentForTranscript {
  /**
   * The unique identifier of the student.
   * @type {number}
   * @memberof StudentForTranscript
   */
  studentKey: number
  /**
   * The first name of the student.
   * @type {string}
   * @memberof StudentForTranscript
   */
  firstName?: string
  /**
   * The last name of the parent of the student.
   * @type {string}
   * @memberof StudentForTranscript
   */
  lastName?: string
  /**
   * Date of birth for the student
   * @type {Date}
   * @memberof StudentForTranscript
   */
  dateOfBirth?: Date
}

export function StudentForTranscriptFromJSON(json: any): StudentForTranscript {
  return StudentForTranscriptFromJSONTyped(json, false)
}

export function StudentForTranscriptFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StudentForTranscript {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    dateOfBirth: !exists(json, 'dateOfBirth')
      ? undefined
      : new Date(json['dateOfBirth']),
  }
}

export function StudentForTranscriptToJSON(
  value?: StudentForTranscript | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    firstName: value.firstName,
    lastName: value.lastName,
    dateOfBirth:
      value.dateOfBirth === undefined
        ? undefined
        : value.dateOfBirth.toISOString().substr(0, 10),
  }
}
