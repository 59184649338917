import React, {
  useState,
  useEffect,
  useMemo,
  ReactElement,
  useCallback,
  useRef,
} from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import { addUpdateProgram, fetchProgramOptions } from '../../api/programs'
import { useNavigate, useLocation } from 'react-router'
import {
  ActorSelectOption,
  FeeStructure,
  FeeType,
  Program,
  ProgramCommunityOption,
  ProgramOptions,
  ProgramStatus,
  ProgramType,
  ProgramUpdate,
} from '../../swagger'
import { extractedErrorObject } from '../../api/swagger'
import { sortCollatorMatchingLanguagesOf } from '../../i18n'
import dateUtilities, {
  areDatesInRange,
  dateToDashString,
  getDateWithZeroHourOffset,
  isFirstDateStringBeforeOrAtSecond,
  isFirstDateStringBeforeSecond,
  isSameDate,
} from '../../utils/dateUtility'
import { equals, isEmpty } from '../../utils/stringUtility'
import CardFormHeader from '../Card/CardFormHeader'
import { FormDivider } from '../Elements/FormDivider'
import ProgramFormCardButtons, {
  Action,
} from '../Buttons/ProgramFormCardButtons'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { FieldValidity } from '../Interfaces/FieldValidity'
import AddressModal from '../Address/AddressModal'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { Ability } from '@casl/ability'
import { useAuth } from '../Routes/AuthProvider'
import programTypeMap from '../../utils/programTypeMap'
import ProgramInformationFields from '../Programs/ProgramInformationFields'
import ProgramAddressFields from '../Programs/ProgramAddressFields'
import DropdownSelectOptions from '../Interfaces/DropdownSelectOptions'
import { jsDateFromYearMonthDayString } from '../../utils/jsDateFromYearMonthDayString'
import {
  ProgramFeeFields,
  ProgramFeeNames,
  ProgramFees,
} from '../Programs/ProgramFeeFields'
import useSetSnackbarProps from '../../hooks/useSetSnackbarProps'
import TutorFormField from '../Programs/TutorFormField'
import { useSnackbarContext } from '../Context/SnackbarContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { OperationIds } from '../../swagger/operationIdEnum'
import { LoadingContext } from '../Context/LoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { SelectChangeEvent } from '@mui/material/Select'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import { ACTING_AS_PARENT } from '../../utils/constants'

export interface ICommunityInformation {
  communityKey: string
  communityName: string
}

export enum ProgramFormCardVariants {
  AddProgram = 'Add Program',
  EditProgram = 'Edit Program',
}

export const daysOfTheWeekMap = new Map([
  ['Sunday', 0],
  ['Monday', 1],
  ['Tuesday', 2],
  ['Wednesday', 3],
  ['Thursday', 4],
  ['Friday', 5],
  ['Saturday', 6],
])

const isWithinProgramDateRange = (
  semesterDateString: string,
  minimumSemesterDate: Date,
  maximumSemesterDate: Date
) => {
  return {
    afterMin: !isFirstDateStringBeforeSecond(
      new Date(semesterDateString),
      minimumSemesterDate
    ),
    beforeMax: !isFirstDateStringBeforeSecond(
      maximumSemesterDate,
      new Date(semesterDateString)
    ),
  }
}

export interface ProgramFormValidFields {
  type: FieldValidity
  semesterOneStartDate: FieldValidity
  semesterTwoStartDate: FieldValidity
  startTime: FieldValidity
  endTime: FieldValidity
  dayOfTheWeek: FieldValidity
  capacity: FieldValidity
  director: FieldValidity
  tutorName: FieldValidity
  communityName: FieldValidity
  directorPhone: FieldValidity
  [ProgramFeeNames.ApplicationFee]: FieldValidity
  [ProgramFeeNames.Tuition]: FieldValidity
  [ProgramFeeNames.EnrollmentFee]: FieldValidity
  [ProgramFeeNames.SupplyFeeFirstStudent]: FieldValidity
  [ProgramFeeNames.SupplyFeeAdditionalStudent]: FieldValidity
  [ProgramFeeNames.LocalFeeFirstStudent]: FieldValidity
  [ProgramFeeNames.LocalFeeAdditionalStudent]: FieldValidity
  [ProgramFeeNames.FacilityFeeFirstStudent]: FieldValidity
  [ProgramFeeNames.FacilityFeeAdditionalStudent]: FieldValidity
  [ProgramFeeNames.MiscFeeFirstStudent]: FieldValidity
  [ProgramFeeNames.MiscFeeAdditionalStudent]: FieldValidity
  [ProgramFeeNames.SubLicensedTutorPercentage]: FieldValidity
  [ProgramFeeNames.SemesterOneLicensingFee]: FieldValidity
  [ProgramFeeNames.DiscountSemesterOneLicensingFee]: FieldValidity
  [ProgramFeeNames.SemesterTwoLicensingFee]: FieldValidity
  [ProgramFeeNames.DiscountSemesterTwoLicensingFee]: FieldValidity
  [ProgramFeeNames.MultiStudentApplicationDiscount]: FieldValidity
  [ProgramFeeNames.InvitationFee]: FieldValidity
  [ProgramFeeNames.DiscountInvitationFee]: FieldValidity
}

interface ProgramFormCardProps {
  /**
   * variant: determines whether we render 'edit' or 'add' variant
   * @type ProgramFormCardVariants
   */
  variant: ProgramFormCardVariants
  /**
   * handleCancel: handles form button cancel event
   * @type () => void
   */
  handleCancel?: () => void
  /**
   * totalEnrolledStudents: Total number of enrolled students
   * @type number | undefined
   */
  totalEnrolledStudents?: number
  /**
   * programDetails: detailed information of a specific program
   * @type Program
   */
  programDetails: Program
  /**
   * handleSave: handles form button save event
   * @type () => void
   */
  handleSave?: () => void
  /**
   * programAbility: `CASL` is a system that enables us to declaratively define and check user permissions,
   * we use it's method for boolean determinations e.g. `Ability.can('edit', 'Program')`
   *  or `programAbility.can('create', 'Program')` for more details visit:
   * https://casl.js.org/v4/en/guide/intro
   * @type Ability
   */
  programAbility?: Ability
  /**
   * setEditForParent: trigger state update at parent component level to set the form to Edit Variant
   * @type (isEdit: boolean) => void
   */
  setEditForParent?: (setEditForParent: boolean) => void
  /**
   * hasEnrollment: determined by the program `enrollments.length > 0 ? true : false`
   * @type boolean
   */
  hasEnrollment?: boolean
  /**
   * updateSelectedTab: updates `selectedTab` state (programDetails) on parent component level.
   * @type boolean
   */
  updateSelectedTab?: (newTab: string) => void
  /**
   * initialProgramDetails: used to persist initial details passed by parent
   * e.g. the user cancels editing we reset back to this initial details
   * @type Program
   */
  initialProgramDetails?: Program
  /**
   * setProgramDetailsForParent: updates state (programDetails) on parent component
   * @type Program
   */
  setProgramDetailsForParent?: (program: Program | undefined) => void
  /**
   * isProgramDetailsEdit: determine if in edit mode used for breadcrumbs and navigation handler
   * @type boolean
   */
  isProgramDetailsEdit?: boolean
  /**
   * hasEnrollmentInvitesWithInProgressStatus: determined by any
   * `enrollmentInvite.status === EnrollmentInvite1StatusEnum.InProgress`
   * a program can not be reverted to Draft if there are invitations still in progress.
   * @type boolean
   */
  hasEnrollmentInvitesWithInProgressStatus?: boolean
}

/**
 * emptyProgramDetails: Used when adding a new Program or resetting a fields upon cancel when in Add variant.
 * When using this object in tests, it is prudent to spread it to another object
 * so we start with the same fresh values and do not mutate for different tests.
 */
export const emptyProgramDetails: Program = {
  programsKey: -1,
  type: ProgramType.Foundations,
  status: ProgramStatus.Draft,
  semesterOneStartDate: new Date(),
  semesterTwoStartDate: new Date(),
  startTime: '08:00',
  endTime: '15:00',
  dayOfTheWeek: 0,
  director: '',
  directorEmail: '',
  directorActorId: -1,
  tutorName: '',
  tutorEmail: '',
  capacity: 0,
  currencyCode: '',
  applicationFee: undefined,
  enrollmentFee: undefined,
  supplyFeeFirstStudent: undefined,
  supplyFeeAdditionalStudent: undefined,
  tuition: undefined,
  localFeeFirstStudent: undefined,
  localFeeAdditionalStudent: undefined,
  facilityFeeFirstStudent: undefined,
  facilityFeeAdditionalStudent: undefined,
  miscFeeFirstStudent: undefined,
  miscFeeAdditionalStudent: undefined,
  subLicensedTutorPercentage: undefined,
  semesterOneLicensingFee: undefined,
  discountSemesterOneLicensingFee: undefined,
  semesterTwoLicensingFee: undefined,
  discountSemesterTwoLicensingFee: undefined,
  multiStudentApplicationDiscount: undefined,
  invitationFee: undefined,
  discountInvitationFee: undefined,
  communityId: -1,
  communityName: '',
  address: {
    locationName: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: '',
  },
  availableSpots: 9,
  meta: undefined, // No initial meta
}
/**
 * validFieldsInitialState: defines form `validFields` initial state
 * is used to restore field validations once the user cancels editing,
 * and is modified as we handle change events on `handleInputChange`
 */
export const validFieldsInitialState: ProgramFormValidFields = {
  type: { input: true },
  semesterOneStartDate: {
    input: true,
    beforeMax: true,
    afterMin: true,
  },
  semesterTwoStartDate: {
    input: true,
    beforeMax: true,
    afterMin: true,
    afterPrevious: true,
  },
  startTime: { input: true },
  endTime: {
    input: true,
    afterPrevious: true,
  },
  dayOfTheWeek: { input: true },
  capacity: { input: true },
  director: { input: true },
  tutorName: { input: true },
  communityName: { input: true },
  directorPhone: { input: true },
  [ProgramFeeNames.ApplicationFee]: { input: true },
  [ProgramFeeNames.Tuition]: { input: true },
  [ProgramFeeNames.EnrollmentFee]: { input: true },
  [ProgramFeeNames.SupplyFeeFirstStudent]: { input: true },
  [ProgramFeeNames.SupplyFeeAdditionalStudent]: { input: true },
  [ProgramFeeNames.LocalFeeFirstStudent]: { input: true },
  [ProgramFeeNames.LocalFeeAdditionalStudent]: { input: true },
  [ProgramFeeNames.FacilityFeeFirstStudent]: { input: true },
  [ProgramFeeNames.FacilityFeeAdditionalStudent]: { input: true },
  [ProgramFeeNames.MiscFeeFirstStudent]: { input: true },
  [ProgramFeeNames.MiscFeeAdditionalStudent]: { input: true },
  [ProgramFeeNames.SubLicensedTutorPercentage]: { input: true },
  [ProgramFeeNames.SemesterOneLicensingFee]: { input: true },
  [ProgramFeeNames.DiscountSemesterOneLicensingFee]: { input: true },
  [ProgramFeeNames.SemesterTwoLicensingFee]: { input: true },
  [ProgramFeeNames.DiscountSemesterTwoLicensingFee]: { input: true },
  [ProgramFeeNames.MultiStudentApplicationDiscount]: { input: true },
  [ProgramFeeNames.InvitationFee]: { input: true },
  [ProgramFeeNames.DiscountInvitationFee]: { input: true },
}

/**
 *
 * @param {ActorSelectOption[]} asoList
 * @param filterOptions
 * is used to filter directors and tutors and conditionally
 * exclude those which role has expired or is not applicable
 */
export const filterActorSelectOption = (
  asoList: ActorSelectOption[],
  filterOptions: {
    type: ProgramType
    firstSemesterStartDate: string
    communityKey?: number
    directorActorId?: number
    removeExpired?: boolean
    removeNotApplicable?: boolean
    areTutors?: boolean
  }
): Array<
  ActorSelectOption & {
    expired?: boolean
    applicableToResourceType?: boolean
  }
> => {
  const {
    type,
    removeExpired = true,
    removeNotApplicable = true,
    areTutors = false,
    firstSemesterStartDate,
  } = filterOptions

  const today = reinterpretYearMonthDayAsLocalTime(new Date(), true)
  const firstSemesterStartDateReinterpreted =
    reinterpretYearMonthDayAsLocalTime(new Date(firstSemesterStartDate), true)

  return asoList
    .map((actor) => {
      const reinterpretValidFrom = reinterpretYearMonthDayAsLocalTime(
        actor.validFrom,
        true
      )

      const reinterpretValidTo = reinterpretYearMonthDayAsLocalTime(
        actor.validTo,
        true
      )

      let expired = false
      if (areTutors) {
        expired = !areDatesInRange(
          { start: reinterpretValidFrom, end: actor.validTo },
          today
        )
      } else {
        //For directors
        expired = !isFirstDateStringBeforeOrAtSecond(
          firstSemesterStartDateReinterpreted,
          reinterpretValidTo
        )
      }
      return {
        ...actor,
        expired,
        applicableToResourceType: actor.applicableToResourceTypes?.some(
          (programType) => programType === type
        ),
      }
    })
    .filter((actor) => {
      if (removeExpired) {
        return actor.expired === false
      }
      return actor
    })
    .filter((actor) => {
      if (removeNotApplicable) {
        return actor.applicableToResourceType === true
      }
      return actor
    })
    .sort((a, b) => {
      if (equals(a.name, b.name)) {
        return 0
      }
      return a.name > b.name ? 1 : -1
    })
}

const ProgramFormCard: React.FC<ProgramFormCardProps> = (props) => {
  const filename = 'ProgramFormCard'
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { permissionAbility, userDetails } = useAuth()
  const sortCollator = sortCollatorMatchingLanguagesOf(i18n)
  const useAddProgramVariant =
    props.variant === ProgramFormCardVariants.AddProgram
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const location: { search: string; state: { communityKey: string } } =
    useLocation()
  const { hasEnrollmentInvitesWithInProgressStatus, setEditForParent } = props

  const {
    directorOptions: directors,
    programTypesFeeOptions: programTypesFees,
    tutorOptions,
    setDirectorsAndTutorsForCommunitySelection,
    selectedCommunityKey,
  } = useCommunitiesContext()

  /** Loading */
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const fetchCommunitySpecificProgramOptionsLoadingId = `${filename}-${OperationIds.FetchCommunitySpecificProgramOptions}`
  const fetchProgramOptionsLoadingId = `${filename}-${OperationIds.FetchProgramOptions}`

  const fetchErrorMessage = t(
    'Programs.ProgramForm.Error.FetchProgramOptions',
    'Error occurred retrieving program options.'
  )

  const fetchErrorMessageDirectorsAndTutorsOptions = t(
    'Programs.ProgramForm.Error.FetchDirectorsAndTutorsOptions',
    'Error occurred retrieving director and tutor options.'
  )

  // Data Initialization
  const today = useRef(new Date())
  const fourMonthsFromToday = useRef(() => {
    const date = new Date()
    date.setMonth(today.current.getMonth() + 4)
    return date
  })

  // Restrict to 2 years in the future
  const maximumSemesterDate = useRef(
    new Date(Date.UTC(today.current.getFullYear() + 2, 11, 31))
  )
  // Restrict to 2 years in the past
  const minimumSemesterDate = useRef(
    new Date(Date.UTC(today.current.getFullYear() - 2, 0, 1))
  )

  if (useAddProgramVariant) {
    if (location?.state) {
      //It prevents warning in the console
      const { communityKey, communityName } =
        location.state as ICommunityInformation
      props.programDetails.communityId = +communityKey
      props.programDetails.communityName = communityName
    }
  }

  /** State Objects */
  const [cancelInitiated, setCancelInitiated] = useState(false)

  const [programDetails, setProgramDetails] = useState<Program>(
    props.programDetails ?? {
      ...emptyProgramDetails,
      // Since load goes to the component, the previous today and fourMonthsFromToday are no longer in emptyProgramDetails, so add them here now.
      semesterOneStartDate: today,
      semesterTwoStartDate: fourMonthsFromToday,
    }
  )

  const [programType, setProgramType] = useState<ProgramType>()

  const firstSemesterDate = getDateWithZeroHourOffset(
    props.programDetails.semesterOneStartDate
  )

  const secondSemesterDate = getDateWithZeroHourOffset(
    props.programDetails.semesterTwoStartDate
  )

  /** dateToDashString removes time zone offset part of the date, which is +X hours e.g.
   *  Sun Jan 30 2023 18:00:00 GMT-0600 (Central Standard Time)
   *  becomes 2023-01-30 since we provide isUTC?: true
   */
  const semesterOneDate =
    dateToDashString(firstSemesterDate, true) ??
    dateToDashString(today.current, true)

  const semesterTwoDate =
    dateToDashString(secondSemesterDate, true) ??
    dateToDashString(fourMonthsFromToday.current(), true)

  const [firstSemesterStartDate, setFirstSemesterStartDate] =
    useState(semesterOneDate)
  const [secondSemesterStartDate, setSecondSemesterStartDate] =
    useState(semesterTwoDate)

  const [previousProgramType, setPreviousProgramType] = useState(
    props.programDetails.type ?? emptyProgramDetails.type
  )
  const [previousCommunityId, setPreviousCommunityId] = useState(
    props.programDetails.communityId ?? emptyProgramDetails.communityId
  )
  const [isFieldDisabled, setIsFieldDisabled] = useState(!useAddProgramVariant)

  const [tutorFormFields, setTutorFormFields] = useState<ReactElement[]>([])

  const [fieldIdToRemove, setFieldIdToRemove] = useState('')

  const [tutorActorIdMap, setTutorActorIdMap] = useState(
    new Map<string, number>()
  )

  const [tutorSelectionOptions, setTutorSelectionOptions] = useState<
    DropdownSelectOptions[]
  >([])

  /**
   * initialFees: defines form `fees` initial state
   * is used to restore fees, is modified as we handle change events on `handleInputChange`
   */
  const initialFees: ProgramFees = {
    [ProgramFeeNames.ApplicationFee]: props.programDetails.applicationFee ?? 0,
    [ProgramFeeNames.Tuition]: props.programDetails.tuition ?? 0,
    [ProgramFeeNames.EnrollmentFee]: props.programDetails.enrollmentFee ?? 0,
    [ProgramFeeNames.SupplyFeeFirstStudent]:
      props.programDetails.supplyFeeFirstStudent ?? 0,
    [ProgramFeeNames.SupplyFeeAdditionalStudent]:
      props.programDetails.supplyFeeAdditionalStudent ?? 0,
    [ProgramFeeNames.LocalFeeFirstStudent]:
      props.programDetails.localFeeFirstStudent ?? 0,
    [ProgramFeeNames.LocalFeeAdditionalStudent]:
      props.programDetails.localFeeAdditionalStudent ?? 0,
    [ProgramFeeNames.FacilityFeeFirstStudent]:
      props.programDetails.facilityFeeFirstStudent ?? 0,
    [ProgramFeeNames.FacilityFeeAdditionalStudent]:
      props.programDetails.facilityFeeAdditionalStudent ?? 0,
    [ProgramFeeNames.MiscFeeFirstStudent]:
      props.programDetails.miscFeeFirstStudent ?? 0,
    [ProgramFeeNames.MiscFeeAdditionalStudent]:
      props.programDetails.miscFeeAdditionalStudent ?? 0,
    [ProgramFeeNames.SubLicensedTutorPercentage]:
      props.programDetails.subLicensedTutorPercentage ?? 0,
    [ProgramFeeNames.SemesterOneLicensingFee]:
      props.programDetails.semesterOneLicensingFee ?? 0,
    [ProgramFeeNames.DiscountSemesterOneLicensingFee]:
      props.programDetails.discountSemesterOneLicensingFee ?? 0,
    [ProgramFeeNames.SemesterTwoLicensingFee]:
      props.programDetails.semesterTwoLicensingFee ?? 0,
    [ProgramFeeNames.DiscountSemesterTwoLicensingFee]:
      props.programDetails.discountSemesterTwoLicensingFee ?? 0,
    [ProgramFeeNames.MultiStudentApplicationDiscount]:
      props.programDetails.multiStudentApplicationDiscount ?? 0,
    [ProgramFeeNames.InvitationFee]: props.programDetails.invitationFee ?? 0,
    [ProgramFeeNames.DiscountInvitationFee]:
      props.programDetails.discountInvitationFee ?? 0,
  }

  const [fees, setFees] = useState<ProgramFees>(initialFees)
  const [status, setStatus] = useState(programDetails.status)

  const [programOptions, setProgramOptions] = useState<ProgramOptions>()
  const communities = programOptions?.communities?.sort((a, b) => {
    return sortCollator.compare(
      a.name ?? fallbackForCommunityName(a),
      b.name ?? fallbackForCommunityName(b)
    )
  })

  // Our assumption is unique community names per https://projekt202.atlassian.net/browse/CCP1-953
  // Use useMemo here so useEffect below isn't rendering unnecessarily
  const communitiesMap = useMemo(() => {
    const map = new Map<
      string /** communityName */,
      ProgramCommunityOption /** Community Program Option */
    >()
    communities?.forEach((community) => {
      const communityName =
        community.name ?? fallbackForCommunityName(community)
      if (!map.has(communityName)) {
        map.set(communityName, community)
      }
    })
    return map
  }, [communities])

  const onTutorFieldRemove = useCallback((tutorFieldKey: string) => {
    setTutorActorIdMap((currentMap) => {
      currentMap.delete(tutorFieldKey)
      return currentMap
    })
  }, [])

  const removeTutorField = useCallback(
    (fieldId: string, tutorFieldKey: string) => {
      setFieldIdToRemove(fieldId)
      onTutorFieldRemove(tutorFieldKey)
    },
    [onTutorFieldRemove]
  )

  const onTutorChange = useCallback(
    (tutorKey: number, event: SelectChangeEvent<unknown>) => {
      setTutorActorIdMap((currentMap) => {
        currentMap.set(event.target.name as string, tutorKey)
        return currentMap
      })
      setTutorFormFields((currentTutorFields) => {
        const fieldIndexToUpdate = currentTutorFields.findIndex((field) => {
          return field.props.tutorFormKey === (event.target.name as string)
        })
        const tutorFields = [...currentTutorFields]
        tutorFields[fieldIndexToUpdate] = {
          ...tutorFields[fieldIndexToUpdate],
          props: {
            ...tutorFields[fieldIndexToUpdate].props,
            tutorName: event.target.name,
          },
        }
        return tutorFields
      })
    },
    []
  )

  const [loadedProgramDetails, setLoadedProgramDetails] = useState(false)

  const setDateFieldsForCommunitySelection = useCallback(
    (community: ProgramCommunityOption) => {
      const defaultFirstDate = new Date(community.defaultSemesterOneStartDate)
      const defaultSecondDate = new Date(community.defaultSemesterTwoStartDate)

      if (defaultSecondDate <= today.current) {
        defaultSecondDate.setFullYear(defaultFirstDate.getUTCFullYear() + 1)
      }

      // ensure we pass UTC dates to dateToDashString
      const firstSemesterDate = new Date(
        defaultFirstDate.getUTCFullYear(),
        defaultFirstDate.getUTCMonth(),
        defaultFirstDate.getUTCDate()
      )

      const secondSemesterDate = new Date(
        defaultSecondDate.getUTCFullYear(),
        defaultSecondDate.getUTCMonth(),
        defaultSecondDate.getUTCDate()
      )

      setFirstSemesterStartDate(
        !!community
          ? dateToDashString(firstSemesterDate)
          : firstSemesterStartDate
      )

      setSecondSemesterStartDate(
        !!community
          ? dateToDashString(secondSemesterDate)
          : secondSemesterStartDate
      )
    },
    [firstSemesterStartDate, secondSemesterStartDate, today]
  )

  /**
   * Given our programDetails were passed in, set the appropriate state objects
   * so fields have properly formatted data for editing a program.
   */
  useEffect(() => {
    if (!!props.programDetails && !loadedProgramDetails) {
      setProgramDetails({
        ...props.programDetails,
        startTime: !!props.programDetails.startTime
          ? props.programDetails.startTime
          : '08:00',
        endTime: !!props.programDetails.endTime
          ? props.programDetails.endTime
          : '15:00',
        dayOfTheWeek:
          !props.programDetails.dayOfTheWeek ||
          props.programDetails.dayOfTheWeek === 7
            ? 0
            : props.programDetails.dayOfTheWeek,
      })
      setProgramType(props.programDetails.type)
      setFees({
        ...fees,
        [ProgramFeeNames.ApplicationFee]:
          props.programDetails.applicationFee ?? fees.applicationFee,
        [ProgramFeeNames.Tuition]: props.programDetails.tuition ?? fees.tuition,
        [ProgramFeeNames.LocalFeeFirstStudent]:
          props.programDetails.localFeeFirstStudent ??
          fees.localFeeFirstStudent,
        [ProgramFeeNames.LocalFeeAdditionalStudent]:
          props.programDetails.localFeeAdditionalStudent ??
          fees.localFeeAdditionalStudent,
        [ProgramFeeNames.FacilityFeeFirstStudent]:
          props.programDetails.facilityFeeFirstStudent ??
          fees.facilityFeeFirstStudent,
        [ProgramFeeNames.FacilityFeeAdditionalStudent]:
          props.programDetails.facilityFeeAdditionalStudent ??
          fees.facilityFeeAdditionalStudent,
        [ProgramFeeNames.SupplyFeeFirstStudent]:
          props.programDetails.supplyFeeFirstStudent ??
          fees.supplyFeeFirstStudent,
        [ProgramFeeNames.SupplyFeeAdditionalStudent]:
          props.programDetails.supplyFeeAdditionalStudent ??
          fees.supplyFeeAdditionalStudent,
        [ProgramFeeNames.EnrollmentFee]:
          props.programDetails.enrollmentFee ?? fees.enrollmentFee,
        [ProgramFeeNames.MiscFeeFirstStudent]:
          props.programDetails.miscFeeFirstStudent ?? fees.miscFeeFirstStudent,
        [ProgramFeeNames.MiscFeeAdditionalStudent]:
          props.programDetails.miscFeeAdditionalStudent ??
          fees.miscFeeAdditionalStudent,
        [ProgramFeeNames.SubLicensedTutorPercentage]:
          props.programDetails.subLicensedTutorPercentage ??
          fees.subLicensedTutorPercentage,
        [ProgramFeeNames.SemesterOneLicensingFee]:
          props.programDetails.semesterOneLicensingFee ??
          fees.semesterOneLicensingFee,
        [ProgramFeeNames.DiscountSemesterOneLicensingFee]:
          props.programDetails.discountSemesterOneLicensingFee ??
          fees.discountSemesterOneLicensingFee,
        [ProgramFeeNames.SemesterTwoLicensingFee]:
          props.programDetails.semesterTwoLicensingFee ??
          fees.semesterTwoLicensingFee,
        [ProgramFeeNames.DiscountSemesterTwoLicensingFee]:
          props.programDetails.discountSemesterTwoLicensingFee ??
          fees.discountSemesterTwoLicensingFee,
        [ProgramFeeNames.MultiStudentApplicationDiscount]:
          props.programDetails.multiStudentApplicationDiscount ??
          fees.multiStudentApplicationDiscount,
        [ProgramFeeNames.InvitationFee]:
          props.programDetails.invitationFee ?? fees.invitationFee,
        [ProgramFeeNames.DiscountInvitationFee]:
          props.programDetails.discountInvitationFee ??
          fees.discountInvitationFee,
      })
      setLoadedProgramDetails(true)
      setPreviousProgramType(props.programDetails.type)
      setPreviousCommunityId(props.programDetails.communityId)
      if (!!props.programDetails.semesterOneStartDate) {
        setFirstSemesterStartDate(
          dateToDashString(props.programDetails.semesterOneStartDate, true)
        )
      }
      if (!!props.programDetails.semesterTwoStartDate) {
        setSecondSemesterStartDate(
          dateToDashString(props.programDetails.semesterTwoStartDate, true)
        )
      }
      setStatus(props.programDetails.status)
      setAddress(props.programDetails.address)

      if (userDetails.actingAs !== ACTING_AS_PARENT) {
        addLoadingIds([fetchCommunitySpecificProgramOptionsLoadingId])
      }
    }
  }, [
    props.programDetails,
    loadedProgramDetails,
    fees,
    communitiesMap,
    useAddProgramVariant,
    props.programDetails.directorEmail,
    props.programDetails.communityId,
    fetchErrorMessageDirectorsAndTutorsOptions,
    addLoadingIds,
    fetchCommunitySpecificProgramOptionsLoadingId,
    userDetails.actingAs,
  ])

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  useEffect(() => {
    if (
      useAddProgramVariant &&
      communitiesMap.size > 0 &&
      (previousCommunityId < 0 || isFirstLoad)
    ) {
      const communityProgramOption = communitiesMap.get(
        props.programDetails.communityName ?? ''
      )
      if (!!communityProgramOption) {
        setDateFieldsForCommunitySelection(communityProgramOption)
        setIsFirstLoad(false)
      }
    }
  }, [
    useAddProgramVariant,
    communitiesMap,
    props.programDetails.communityName,
    setDateFieldsForCommunitySelection,
    previousCommunityId,
    isFirstLoad,
  ])

  const [optionsFetched, setOptionsFetched] = useState(false)

  const setAddressFieldsForCommunitySelection = useCallback(
    (community: ProgramCommunityOption) => {
      const newAddress = {
        ...community.address,
        streetAddress2: community.address?.streetAddress2 ?? '',
        state: community.address?.state ?? '',
        zip: community.address?.zip ?? '',
      }
      setAddress(newAddress)
      setProgramDetails({
        ...programDetails,
        address: newAddress,
      })
      setIsAddressValid(true)
    },
    [programDetails]
  )

  useEffect(() => {
    if (optionsFetched && communitiesMap.size > 0) {
      const communityProgramOption = communitiesMap.get(
        programDetails.communityName ?? ''
      )
      if (!!communityProgramOption && !programDetails.address.countryCode) {
        setAddressFieldsForCommunitySelection(communityProgramOption)
      }
      setOptionsFetched(false)
    }
  }, [
    communitiesMap,
    optionsFetched,
    programDetails.address.countryCode,
    programDetails.communityName,
    setAddressFieldsForCommunitySelection,
    setDateFieldsForCommunitySelection,
  ])

  const fetchedDirectorsAndTutors = async (
    communityKey = programDetails.communityId
  ): Promise<void> => {
    try {
      await setDirectorsAndTutorsForCommunitySelection(communityKey)
      // The communitiesMap is not set when initially loading Add Program, so we load these after indicating fetchCommunityOptions is called and we setup the communitiesMap.
      const communityProgramOption = communitiesMap.get(
        programDetails.communityName ?? ''
      )
      if (!!communityProgramOption) {
        setDateFieldsForCommunitySelection(communityProgramOption)
      }
      setOptionsFetched(true)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          fetchErrorMessageDirectorsAndTutorsOptions,
      }
      setSnackbarContents({
        snackbarMessage: errorObj.message,
        snackbarSeverity: SnackbarSeverity.Error,
      })
    }
  }
  useLoadingContext({
    loadingId: fetchCommunitySpecificProgramOptionsLoadingId,
    asyncFunction: fetchedDirectorsAndTutors,
  })

  const [loadCommunityProgramOptions, setLoadCommunityProgramOptions] =
    useState(useAddProgramVariant)

  /** When we update the communityId but the states have yet to update, don't add the loadingId until the state updated so we call the endpoint with the new communityId */
  useEffect(() => {
    if (
      (previousCommunityId > 0 || !useAddProgramVariant) &&
      previousCommunityId !== programDetails.communityId &&
      loadCommunityProgramOptions &&
      userDetails.actingAs !== ACTING_AS_PARENT
    ) {
      setLoadCommunityProgramOptions(false)
      addLoadingIds([fetchCommunitySpecificProgramOptionsLoadingId])
    }
  }, [
    addLoadingIds,
    fetchCommunitySpecificProgramOptionsLoadingId,
    loadCommunityProgramOptions,
    previousCommunityId,
    programDetails.communityId,
    props.programDetails.communityId,
    useAddProgramVariant,
    userDetails.actingAs,
  ])

  const programFeeStructureMap = useMemo(() => {
    const feeStructureMap = new Map<string, FeeStructure[]>()
    programTypesFees?.programFeeStructures.forEach((programFeeStructure) => {
      feeStructureMap.set(
        programFeeStructure.programType,
        programFeeStructure.feeStructures
      )
    })
    return feeStructureMap
  }, [programTypesFees?.programFeeStructures])

  const programFeeMinimaMap = useMemo(() => {
    const feeMap = new Map<
      string,
      { minimum: number; default: number; allowEdit: boolean }
    >()
    programFeeStructureMap.get(programDetails.type)?.forEach((fee) => {
      // Translate feeType enum to Program object fee names
      let feeKey: string = fee.feeType
      if (fee.feeType === FeeType.MiscFeeFirstStudent) {
        feeKey = 'miscFeeFirstStudent'
      } else {
        Object.values(ProgramFeeNames).forEach((it) => {
          if (new RegExp(fee.feeType).test(it)) {
            feeKey = it
          }
        })
      }
      // Set the minimums on the map
      feeMap.set(feeKey, {
        minimum: fee.minimumAmount,
        default: fee.defaultTotalAmount,
        allowEdit: fee.allowEditTotal,
      })
    })
    return feeMap
  }, [programFeeStructureMap, programDetails.type])

  // Can update these to be the same function
  const filteredTutorOptions = useMemo(() => {
    return filterActorSelectOption(tutorOptions ?? [], {
      type: programDetails.type,
      firstSemesterStartDate,
      directorActorId: programDetails.directorActorId,
      removeExpired: false,
    })
  }, [
    firstSemesterStartDate,
    tutorOptions,
    programDetails.type,
    programDetails.directorActorId,
  ])

  const tutorOptionsMap = useMemo(() => {
    const map = new Map<number, string>(
      tutorSelectionOptions.map((tutor) => [tutor.id as number, tutor.label])
    )
    return map
  }, [tutorSelectionOptions])

  // Can update these to be the same function
  const filteredDirectors = filterActorSelectOption(directors ?? [], {
    type: programDetails.type,
    firstSemesterStartDate,
    communityKey: programDetails.communityId,
    removeExpired: true,
  }).map((director) => {
    return {
      ...director,
      disabled: director.expired,
    }
  })

  const directorsMap = new Map<
    string /** key: director */,
    number /** value: directorActorId */
  >()
  filteredDirectors?.forEach((director) => {
    directorsMap.set(
      `${director.name} - ${director.actorKey}`,
      director.actorKey
    )
  })

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [address, setAddress] = useState(programDetails.address)
  const [isAddressValid, setIsAddressValid] = useState(true)

  const [validFields, setValidFields] = useState<ProgramFormValidFields>(
    validFieldsInitialState
  )

  const [isCancel, setIsCancel] = useState(false)
  const [loading, setLoading] = useState(false)

  const [programTypes, setProgramTypes] = useState<ProgramType[]>([
    props.programDetails.type,
  ])

  // Fetch Program Options
  const [loadProgramOptions, setLoadProgramOptions] = useState(false)
  const [snackbarContents, setSnackbarContents] = useState({
    snackbarMessage: '',
    snackbarSeverity: SnackbarSeverity.Success,
  })

  useEffect(() => {
    if (useAddProgramVariant) {
      setLoadProgramOptions(true)
      if (!!programOptions) {
        setLoadProgramOptions(false)
      }
    }
  }, [loadProgramOptions, useAddProgramVariant, programOptions])

  /**
   * Load the program options once. Set the necessary objects from the response
   * to reference when new selections are made.
   */
  useEffect(() => {
    if (!loadProgramOptions) return
    addLoadingIds([fetchProgramOptionsLoadingId])
  }, [loadProgramOptions, addLoadingIds, fetchProgramOptionsLoadingId])

  const fetchUserProgramOptions = async (): Promise<void> => {
    try {
      const fetchedProgramOptions = await fetchProgramOptions()
      setProgramOptions(fetchedProgramOptions)
      setProgramTypes(fetchedProgramOptions.programTypes)
      setLoadProgramOptions(false)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? fetchErrorMessage,
      }
      setSnackbarContents({
        snackbarMessage: errorObj.message,
        snackbarSeverity: SnackbarSeverity.Error,
      })
    }
  }
  useLoadingContext({
    loadingId: fetchProgramOptionsLoadingId,
    asyncFunction: fetchUserProgramOptions,
  })

  const [doCheckProgramFeesExist, setDoCheckProgramFeesExist] = useState(true)

  /**
   *  When we load Program Fees for a given community/program and we DO NOT
   *  have fees already set, update with the default fees if they exist and
   *  the minimum fee if default doesn't exist
   */
  useEffect(() => {
    if (programFeeMinimaMap.size > 0 && doCheckProgramFeesExist) {
      setFees({
        /** check if typeof programDetails.AnyFee === 'number' to prevent it from evaluating the value es false
         *  in case the fee is 0
         */
        [ProgramFeeNames.ApplicationFee]:
          typeof programDetails.applicationFee === 'number'
            ? fees.applicationFee
            : programFeeMinimaMap.get(ProgramFeeNames.ApplicationFee)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.ApplicationFee)
                ?.minimum ??
              0,
        [ProgramFeeNames.Tuition]:
          typeof programDetails.tuition === 'number'
            ? fees.tuition
            : programFeeMinimaMap.get(ProgramFeeNames.Tuition)?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.Tuition)?.minimum ??
              0,
        [ProgramFeeNames.LocalFeeFirstStudent]:
          typeof programDetails.localFeeFirstStudent === 'number'
            ? fees.localFeeFirstStudent
            : programFeeMinimaMap.get(ProgramFeeNames.LocalFeeFirstStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.LocalFeeFirstStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.LocalFeeAdditionalStudent]:
          typeof programDetails.localFeeAdditionalStudent === 'number'
            ? fees.localFeeAdditionalStudent
            : programFeeMinimaMap.get(ProgramFeeNames.LocalFeeAdditionalStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.LocalFeeAdditionalStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.FacilityFeeFirstStudent]:
          typeof programDetails.facilityFeeFirstStudent === 'number'
            ? fees.facilityFeeFirstStudent
            : programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeFirstStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeFirstStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.FacilityFeeAdditionalStudent]:
          typeof programDetails.facilityFeeAdditionalStudent === 'number'
            ? fees.facilityFeeAdditionalStudent
            : programFeeMinimaMap.get(
                ProgramFeeNames.FacilityFeeAdditionalStudent
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.FacilityFeeAdditionalStudent
              )?.minimum ??
              0,
        [ProgramFeeNames.SupplyFeeFirstStudent]:
          typeof programDetails.supplyFeeFirstStudent === 'number'
            ? fees.supplyFeeFirstStudent
            : programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeFirstStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeFirstStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.SupplyFeeAdditionalStudent]:
          typeof programDetails.supplyFeeFirstStudent === 'number'
            ? fees.supplyFeeAdditionalStudent
            : programFeeMinimaMap.get(
                ProgramFeeNames.SupplyFeeAdditionalStudent
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.SupplyFeeAdditionalStudent
              )?.minimum ??
              0,
        [ProgramFeeNames.EnrollmentFee]:
          typeof programDetails.enrollmentFee === 'number'
            ? fees.enrollmentFee
            : programFeeMinimaMap.get(ProgramFeeNames.EnrollmentFee)?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.EnrollmentFee)?.minimum ??
              0,
        [ProgramFeeNames.MiscFeeFirstStudent]:
          typeof programDetails.miscFeeFirstStudent === 'number'
            ? fees.miscFeeFirstStudent
            : programFeeMinimaMap.get(ProgramFeeNames.MiscFeeFirstStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.MiscFeeFirstStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.MiscFeeAdditionalStudent]:
          typeof programDetails.miscFeeAdditionalStudent === 'number'
            ? fees.miscFeeAdditionalStudent
            : programFeeMinimaMap.get(ProgramFeeNames.MiscFeeAdditionalStudent)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.MiscFeeAdditionalStudent)
                ?.minimum ??
              0,
        [ProgramFeeNames.SubLicensedTutorPercentage]:
          typeof programDetails.subLicensedTutorPercentage === 'number'
            ? fees.subLicensedTutorPercentage
            : programFeeMinimaMap.get(
                ProgramFeeNames.SubLicensedTutorPercentage
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.SubLicensedTutorPercentage
              )?.minimum ??
              0,
        [ProgramFeeNames.SemesterOneLicensingFee]:
          typeof programDetails.semesterOneLicensingFee === 'number'
            ? fees.semesterOneLicensingFee
            : programFeeMinimaMap.get(ProgramFeeNames.SemesterOneLicensingFee)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.SemesterOneLicensingFee)
                ?.minimum ??
              0,
        [ProgramFeeNames.DiscountSemesterOneLicensingFee]:
          typeof programDetails.discountSemesterOneLicensingFee === 'number'
            ? fees.discountSemesterOneLicensingFee
            : programFeeMinimaMap.get(
                ProgramFeeNames.DiscountSemesterOneLicensingFee
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.DiscountSemesterOneLicensingFee
              )?.minimum ??
              0,
        [ProgramFeeNames.SemesterTwoLicensingFee]:
          typeof programDetails.semesterTwoLicensingFee === 'number'
            ? fees.semesterTwoLicensingFee
            : programFeeMinimaMap.get(ProgramFeeNames.SemesterTwoLicensingFee)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.SemesterTwoLicensingFee)
                ?.minimum ??
              0,
        [ProgramFeeNames.DiscountSemesterTwoLicensingFee]:
          typeof programDetails.discountSemesterTwoLicensingFee === 'number'
            ? fees.discountSemesterTwoLicensingFee
            : programFeeMinimaMap.get(
                ProgramFeeNames.DiscountSemesterTwoLicensingFee
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.DiscountSemesterTwoLicensingFee
              )?.minimum ??
              0,
        [ProgramFeeNames.MultiStudentApplicationDiscount]:
          typeof programDetails.multiStudentApplicationDiscount === 'number'
            ? fees.multiStudentApplicationDiscount
            : programFeeMinimaMap.get(
                ProgramFeeNames.MultiStudentApplicationDiscount
              )?.default ??
              programFeeMinimaMap.get(
                ProgramFeeNames.MultiStudentApplicationDiscount
              )?.minimum ??
              0,
        [ProgramFeeNames.InvitationFee]:
          typeof programDetails.invitationFee === 'number'
            ? fees.invitationFee
            : programFeeMinimaMap.get(ProgramFeeNames.InvitationFee)?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.InvitationFee)?.minimum ??
              0,
        [ProgramFeeNames.DiscountInvitationFee]:
          typeof programDetails.discountInvitationFee === 'number'
            ? fees.discountInvitationFee
            : programFeeMinimaMap.get(ProgramFeeNames.DiscountInvitationFee)
                ?.default ??
              programFeeMinimaMap.get(ProgramFeeNames.DiscountInvitationFee)
                ?.minimum ??
              0,
      })
      setDoCheckProgramFeesExist(false)
    }
  }, [programFeeMinimaMap, doCheckProgramFeesExist, programDetails, fees])

  /**
   * If we aren't canceling an edit and we have changed program types or
   * community and we have default/minimum fees to load then update the fee
   * fields to the new defaults/minimums
   */
  useEffect(() => {
    if (
      !isCancel &&
      (previousProgramType !== programDetails.type ||
        (programDetails.communityId > 0 &&
          programDetails.communityId !== previousCommunityId)) &&
      programFeeMinimaMap.size > 0
    ) {
      setFees({
        [ProgramFeeNames.ApplicationFee]:
          programFeeMinimaMap.get(ProgramFeeNames.ApplicationFee)?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.ApplicationFee)?.minimum ??
          0,
        [ProgramFeeNames.Tuition]:
          programFeeMinimaMap.get(ProgramFeeNames.Tuition)?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.Tuition)?.minimum ??
          0,
        [ProgramFeeNames.LocalFeeFirstStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.LocalFeeFirstStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.LocalFeeFirstStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.LocalFeeAdditionalStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.LocalFeeAdditionalStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.LocalFeeAdditionalStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.FacilityFeeFirstStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeFirstStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeFirstStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.FacilityFeeAdditionalStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeAdditionalStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.FacilityFeeAdditionalStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.SupplyFeeFirstStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeFirstStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeFirstStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.SupplyFeeAdditionalStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeAdditionalStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.SupplyFeeAdditionalStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.EnrollmentFee]:
          programFeeMinimaMap.get(ProgramFeeNames.EnrollmentFee)?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.EnrollmentFee)?.minimum ??
          0,
        [ProgramFeeNames.MiscFeeFirstStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.MiscFeeFirstStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.MiscFeeFirstStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.MiscFeeAdditionalStudent]:
          programFeeMinimaMap.get(ProgramFeeNames.MiscFeeAdditionalStudent)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.MiscFeeAdditionalStudent)
            ?.minimum ??
          0,
        [ProgramFeeNames.SubLicensedTutorPercentage]:
          programFeeMinimaMap.get(ProgramFeeNames.SubLicensedTutorPercentage)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.SubLicensedTutorPercentage)
            ?.minimum ??
          0,
        [ProgramFeeNames.SemesterOneLicensingFee]:
          programFeeMinimaMap.get(ProgramFeeNames.SemesterOneLicensingFee)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.SemesterOneLicensingFee)
            ?.minimum ??
          0,
        [ProgramFeeNames.DiscountSemesterOneLicensingFee]:
          programFeeMinimaMap.get(
            ProgramFeeNames.DiscountSemesterOneLicensingFee
          )?.default ??
          programFeeMinimaMap.get(
            ProgramFeeNames.DiscountSemesterOneLicensingFee
          )?.minimum ??
          0,
        [ProgramFeeNames.SemesterTwoLicensingFee]:
          programFeeMinimaMap.get(ProgramFeeNames.SemesterTwoLicensingFee)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.SemesterTwoLicensingFee)
            ?.minimum ??
          0,
        [ProgramFeeNames.DiscountSemesterTwoLicensingFee]:
          programFeeMinimaMap.get(
            ProgramFeeNames.DiscountSemesterTwoLicensingFee
          )?.default ??
          programFeeMinimaMap.get(
            ProgramFeeNames.DiscountSemesterTwoLicensingFee
          )?.minimum ??
          0,
        [ProgramFeeNames.MultiStudentApplicationDiscount]:
          programFeeMinimaMap.get(
            ProgramFeeNames.MultiStudentApplicationDiscount
          )?.default ??
          programFeeMinimaMap.get(
            ProgramFeeNames.MultiStudentApplicationDiscount
          )?.minimum ??
          0,
        [ProgramFeeNames.InvitationFee]:
          programFeeMinimaMap.get(ProgramFeeNames.InvitationFee)?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.InvitationFee)?.minimum ??
          0,
        [ProgramFeeNames.DiscountInvitationFee]:
          programFeeMinimaMap.get(ProgramFeeNames.DiscountInvitationFee)
            ?.default ??
          programFeeMinimaMap.get(ProgramFeeNames.DiscountInvitationFee)
            ?.minimum ??
          0,
      })
    }
    // Ensure everything is reset before saying cancel is complete
    if (
      isCancel &&
      programDetails.communityId === props.programDetails.communityId &&
      previousCommunityId === props.programDetails.communityId &&
      programDetails.type === props.programDetails.type &&
      previousProgramType === props.programDetails.type
    ) {
      setIsCancel(false)
      if (useAddProgramVariant && userDetails.actingAs !== ACTING_AS_PARENT) {
        addLoadingIds([fetchCommunitySpecificProgramOptionsLoadingId])
      }
    }
  }, [
    programFeeMinimaMap,
    previousCommunityId,
    isCancel,
    previousProgramType,
    programDetails.type,
    props.programDetails.type,
    programDetails.communityId,
    props.programDetails.communityId,
    fetchCommunitySpecificProgramOptionsLoadingId,
    addLoadingIds,
    useAddProgramVariant,
    userDetails.actingAs,
  ])

  /** Inform the parent component if an edit is in progress for ConfirmNavigationAwayModal */
  useEffect(() => {
    if (!useAddProgramVariant) {
      setEditForParent?.(!isFieldDisabled)
    }
  }, [isFieldDisabled, setEditForParent, useAddProgramVariant])

  const isTutorFieldDisabled =
    isFieldDisabled ||
    !programDetails.director ||
    !programDetails.type ||
    filteredTutorOptions.length === 0

  useEffect(() => {
    const initializeTutorFields = () => {
      const tutorFieldsArray: ReactElement[] = []
      if (tutorFormFields.length === 0) {
        if (
          programDetails.tutors &&
          programDetails.tutors.length > 0 &&
          tutorOptionsMap.size > 0
        ) {
          programDetails.tutors?.forEach((tutor) => {
            const tutorActorKey = tutor?.actorKey ?? -1
            const valueForTutorField = tutorOptionsMap.get(tutorActorKey) ?? ''
            tutorFieldsArray.push(
              <TutorFormField
                tutors={tutorSelectionOptions}
                isDisabled={isTutorFieldDisabled}
                key={`tutorFormFieldKey-${tutorActorKey}`}
                tutorFormKey={`tutorFormKey-${tutorActorKey}`}
                tutorFormId={`tutorFormId-${tutorActorKey}`}
                tutorName={valueForTutorField}
                removeTutorField={removeTutorField}
                onTutorChange={onTutorChange}
                error={false}
              />
            )
            setTutorActorIdMap((currentMap) => {
              currentMap.set(
                `tutorFormKey-${tutorActorKey}`,
                tutor?.actorKey ?? -1
              )
              return currentMap
            })

            setTutorFormFields(tutorFieldsArray)
          })
        } else if (!programDetails.tutors) {
          const dateTime = new Date().getTime()
          tutorFieldsArray.push(
            <TutorFormField
              tutors={tutorSelectionOptions}
              isDisabled={isTutorFieldDisabled}
              key={`tutorFormFieldKey-${dateTime}`}
              tutorFormKey={`tutorFormKey-${dateTime}`}
              tutorFormId={`tutorFormId-${dateTime}`}
              tutorName=""
              removeTutorField={removeTutorField}
              onTutorChange={onTutorChange}
              error={false}
            />
          )
          setTutorFormFields(tutorFieldsArray)
        }
      }
    }
    if (
      (loadedProgramDetails && !useAddProgramVariant) ||
      useAddProgramVariant
    ) {
      initializeTutorFields()
    }
  }, [
    tutorSelectionOptions,
    isTutorFieldDisabled,
    programDetails.tutors,
    onTutorChange,
    removeTutorField,
    tutorFormFields,
    isCancel,
    loadedProgramDetails,
    useAddProgramVariant,
    tutorOptionsMap,
  ])

  //Updating the fields when we swap from editable to uneditable field modes
  useEffect(() => {
    setTutorFormFields((currentTutorFormFields) =>
      currentTutorFormFields.map((tutorFormField: ReactElement) => {
        return {
          ...tutorFormField,
          props: {
            ...tutorFormField.props,
            tutors: tutorSelectionOptions,
            isDisabled: isTutorFieldDisabled,
          },
        }
      })
    )
  }, [tutorSelectionOptions, isTutorFieldDisabled])

  useEffect(() => {
    const haveFieldIdAndFieldsAreLoaded =
      !!fieldIdToRemove && tutorFormFields.length > 0
    if (haveFieldIdAndFieldsAreLoaded) {
      tutorFormFields.forEach((field, index) => {
        if (field.props.tutorFormId === fieldIdToRemove) {
          const modifiedTutorFields = [...tutorFormFields]
          modifiedTutorFields.splice(index, 1)
          setTutorFormFields(modifiedTutorFields)
        }
      })
      const tutorsFormProgramDetail = programDetails.tutors
      tutorsFormProgramDetail?.forEach((field, index) => {
        if (field.actorKey?.toString() === fieldIdToRemove.split('-')[1]) {
          const modifiedTutorsFormProgramDetail = [...tutorsFormProgramDetail]
          modifiedTutorsFormProgramDetail.splice(index, 1)
          setProgramDetails({
            ...props.programDetails,
            tutors: modifiedTutorsFormProgramDetail,
          })
        }
      })
      setFieldIdToRemove('')
    }
  }, [
    fieldIdToRemove,
    tutorFormFields,
    programDetails.tutors,
    props.programDetails,
  ])

  useEffect(() => {
    setTutorSelectionOptions(() => {
      return filteredTutorOptions.map((tutor) => {
        return {
          id: tutor.actorKey,
          label: tutor.name,
          disabled:
            (programDetails?.tutors?.some(
              (detailTutor) => tutor.actorKey === detailTutor.actorKey
            ) ??
              false) ||
            tutor.expired,
        }
      })
    })
  }, [filteredTutorOptions, programDetails.tutors])

  /** Hooks */
  useSetSnackbarProps({
    snackbarContents,
  })

  /** Methods */
  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => {
    switch (event.target.name) {
      case 'capacity':
        setProgramDetails({
          ...programDetails,
          [event.target.name]: +event.target.value.replace(/[^0-9]/g, ''),
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      case 'firstSemesterStartDate':
        setFirstSemesterStartDate(event.target.value)
        setValidFields({
          ...validFields,
          semesterOneStartDate: validity ?? {
            input: true,
            beforeMax: true,
            afterMin: true,
          },
        })
        break
      case 'secondSemesterStartDate':
        setSecondSemesterStartDate(event.target.value)
        setValidFields({
          ...validFields,
          semesterTwoStartDate: validity ?? {
            input: true,
            beforeMax: true,
            afterMin: true,
          },
        })

        if (event.target.value > semesterOneDate) {
          if (validity) validity.afterPrevious = true
        }

        break
      case 'dayOfTheWeek':
        setProgramDetails({
          ...programDetails,
          [event.target.name]: daysOfTheWeekMap.get(event.target.value) ?? 0,
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      case 'tutorName':
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      case 'type':
        setPreviousProgramType(programDetails.type)
        const programType = programTypeMap.get(
          event.target.value
        ) as ProgramType
        const resetForProgramType = programType !== programDetails.type
        setProgramDetails({
          ...programDetails,
          director: resetForProgramType ? '' : programDetails.director,
          directorActorId: resetForProgramType
            ? -1
            : programDetails.directorActorId,
          tutorName: resetForProgramType ? '' : programDetails.tutorName,
          [event.target.name]: programTypeMap.get(
            event.target.value
          ) as ProgramType,
        })
        setProgramType(event.target.value as ProgramType)
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      case 'communityName':
        const communityProgramOption = communitiesMap.get(event.target.value)
        if (communityProgramOption) {
          setPreviousCommunityId(programDetails.communityId)
          setDateFieldsForCommunitySelection(communityProgramOption)
          setAddressFieldsForCommunitySelection(communityProgramOption)
        }
        const communityKey = !!communityProgramOption
          ? communityProgramOption.communityKey
          : -1
        const resetForCommunityChange =
          communityKey < 0 ||
          communityKey !== (programDetails.communityId ?? -1)
        setProgramDetails({
          ...programDetails,
          communityId: communityKey,
          // If the community is updating reset the director and tutor
          director: resetForCommunityChange ? '' : programDetails.director,
          directorActorId: resetForCommunityChange
            ? -1
            : programDetails.directorActorId,
          tutorName: resetForCommunityChange ? '' : programDetails.tutorName,
          address: !!communityProgramOption
            ? communityProgramOption.address
            : programDetails.address,
          [event.target.name]: event.target.value,
        })
        setLoadCommunityProgramOptions(true)
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      case 'director':
        const resetForDirectorChange = true
        const [director] = event.target.value.split(' - ')
        setProgramDetails({
          ...programDetails,
          directorActorId: directorsMap.get(event.target.value),
          directorPhone:
            directors
              ?.filter(
                (actor) =>
                  actor.actorKey === directorsMap.get(event.target.value)
              )
              .map((director) => director.phone)
              .toString() ?? '',
          directorEmail:
            directors
              ?.filter(
                (actor) =>
                  actor.actorKey === directorsMap.get(event.target.value)
              )
              .map((director) => director.email)
              .toString() ?? '',
          tutorName: resetForDirectorChange ? '' : programDetails.tutorName,
          [event.target.name]: director,
        })

        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break

      case 'endTime':
        setProgramDetails({
          ...programDetails,
          [event.target.name]: event.target.value,
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
            afterPrevious: true,
          },
        })
        break

      case 'directorPhone':
        setProgramDetails({
          ...programDetails,
          [event.target.name]: event.target.value,
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break

      case ProgramFeeNames.Tuition:
      case ProgramFeeNames.EnrollmentFee:
      case ProgramFeeNames.FacilityFeeFirstStudent:
      case ProgramFeeNames.FacilityFeeAdditionalStudent:
      case ProgramFeeNames.LocalFeeFirstStudent:
      case ProgramFeeNames.LocalFeeAdditionalStudent:
      case ProgramFeeNames.MiscFeeFirstStudent:
      case ProgramFeeNames.MiscFeeAdditionalStudent:
      case ProgramFeeNames.SupplyFeeFirstStudent:
      case ProgramFeeNames.SupplyFeeAdditionalStudent:
      case ProgramFeeNames.SubLicensedTutorPercentage:
      case ProgramFeeNames.SemesterOneLicensingFee:
      case ProgramFeeNames.DiscountSemesterOneLicensingFee:
      case ProgramFeeNames.SemesterTwoLicensingFee:
      case ProgramFeeNames.DiscountSemesterTwoLicensingFee:
      case ProgramFeeNames.MultiStudentApplicationDiscount:
      case ProgramFeeNames.InvitationFee:
      case ProgramFeeNames.DiscountInvitationFee:
        setFees({
          ...fees,
          [event.target.name]: +event.target.value.replace(/[^0-9]/g, ''),
        })
        setProgramDetails({
          ...programDetails,
          [event.target.name]: +event.target.value.replace(/[^0-9]/g, ''),
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
      default:
        setProgramDetails({
          ...programDetails,
          [event.target.name]: event.target.value,
        })
        setValidFields({
          ...validFields,
          [event.target.name]: {
            input: true,
          },
        })
        break
    }
  }

  const fallbackForCommunityName = ({
    communityKey,
  }: Pick<ProgramCommunityOption, 'communityKey'>) => communityKey + ''

  const resetFields = useCallback(() => {
    setProgramDetails(
      !!props.programDetails ? props.programDetails : emptyProgramDetails
    )
    setAddress({
      ...props.programDetails.address,
    })

    setFirstSemesterStartDate(
      dateToDashString(
        !!props.programDetails.semesterOneStartDate
          ? props.programDetails.semesterOneStartDate
          : today.current,
        true
      )
    )

    setSecondSemesterStartDate(
      dateToDashString(
        !!props.programDetails.semesterTwoStartDate
          ? props.programDetails.semesterTwoStartDate
          : fourMonthsFromToday.current(),
        true
      )
    )

    setFees({
      [ProgramFeeNames.ApplicationFee]:
        initialFees.applicationFee ?? fees.applicationFee,
      [ProgramFeeNames.Tuition]: initialFees.tuition ?? fees.tuition,
      [ProgramFeeNames.LocalFeeFirstStudent]:
        initialFees.localFeeFirstStudent ?? fees.localFeeFirstStudent,
      [ProgramFeeNames.LocalFeeAdditionalStudent]:
        initialFees.localFeeAdditionalStudent ?? fees.localFeeAdditionalStudent,
      [ProgramFeeNames.FacilityFeeFirstStudent]:
        initialFees.facilityFeeFirstStudent ?? fees.facilityFeeFirstStudent,
      [ProgramFeeNames.FacilityFeeAdditionalStudent]:
        initialFees.facilityFeeAdditionalStudent ??
        fees.facilityFeeAdditionalStudent,
      [ProgramFeeNames.SupplyFeeFirstStudent]:
        initialFees.supplyFeeFirstStudent ?? fees.supplyFeeFirstStudent,
      [ProgramFeeNames.SupplyFeeAdditionalStudent]:
        initialFees.supplyFeeAdditionalStudent ??
        fees.supplyFeeAdditionalStudent,
      [ProgramFeeNames.MiscFeeFirstStudent]:
        initialFees.miscFeeFirstStudent ?? fees.miscFeeFirstStudent,
      [ProgramFeeNames.MiscFeeAdditionalStudent]:
        initialFees.miscFeeAdditionalStudent ?? fees.miscFeeAdditionalStudent,
      [ProgramFeeNames.EnrollmentFee]:
        initialFees.enrollmentFee ?? fees.enrollmentFee,
      [ProgramFeeNames.SubLicensedTutorPercentage]:
        initialFees.subLicensedTutorPercentage ??
        fees.subLicensedTutorPercentage,
      [ProgramFeeNames.SemesterOneLicensingFee]:
        initialFees.semesterOneLicensingFee ?? fees.semesterOneLicensingFee,
      [ProgramFeeNames.DiscountSemesterOneLicensingFee]:
        initialFees.discountSemesterOneLicensingFee ??
        fees.discountSemesterOneLicensingFee,
      [ProgramFeeNames.SemesterTwoLicensingFee]:
        initialFees.semesterTwoLicensingFee ?? fees.semesterTwoLicensingFee,
      [ProgramFeeNames.DiscountSemesterTwoLicensingFee]:
        initialFees.discountSemesterTwoLicensingFee ??
        fees.discountSemesterTwoLicensingFee,
      [ProgramFeeNames.MultiStudentApplicationDiscount]:
        initialFees.multiStudentApplicationDiscount ??
        fees.multiStudentApplicationDiscount,
      [ProgramFeeNames.InvitationFee]:
        initialFees.invitationFee ?? fees.invitationFee,
      [ProgramFeeNames.DiscountInvitationFee]:
        initialFees.discountInvitationFee ?? fees.discountInvitationFee,
    })

    setValidFields({
      ...validFieldsInitialState,
    })
  }, [
    fees.applicationFee,
    fees.discountSemesterOneLicensingFee,
    fees.discountSemesterTwoLicensingFee,
    fees.enrollmentFee,
    fees.facilityFeeFirstStudent,
    fees.localFeeFirstStudent,
    fees.miscFeeFirstStudent,
    fees.facilityFeeAdditionalStudent,
    fees.localFeeAdditionalStudent,
    fees.miscFeeAdditionalStudent,
    fees.multiStudentApplicationDiscount,
    fees.semesterOneLicensingFee,
    fees.semesterTwoLicensingFee,
    fees.subLicensedTutorPercentage,
    fees.supplyFeeFirstStudent,
    fees.supplyFeeAdditionalStudent,
    fees.tuition,
    fees.invitationFee,
    fees.discountInvitationFee,
    initialFees.applicationFee,
    initialFees.discountSemesterOneLicensingFee,
    initialFees.discountSemesterTwoLicensingFee,
    initialFees.enrollmentFee,
    initialFees.facilityFeeFirstStudent,
    initialFees.facilityFeeAdditionalStudent,
    initialFees.localFeeFirstStudent,
    initialFees.localFeeAdditionalStudent,
    initialFees.miscFeeFirstStudent,
    initialFees.miscFeeAdditionalStudent,
    initialFees.multiStudentApplicationDiscount,
    initialFees.semesterOneLicensingFee,
    initialFees.semesterTwoLicensingFee,
    initialFees.subLicensedTutorPercentage,
    initialFees.supplyFeeFirstStudent,
    initialFees.supplyFeeAdditionalStudent,
    initialFees.tuition,
    initialFees.invitationFee,
    initialFees.discountInvitationFee,
    props.programDetails,
  ])

  const validateDayOfWeek = useCallback(() => {
    return (
      (programDetails.dayOfTheWeek as number) >= 0 &&
      (programDetails.dayOfTheWeek as number) < 7
    )
  }, [programDetails.dayOfTheWeek])

  const labelForDayOfTheWeek = (numericalDay: number) => {
    let dayOfTheWeek = ''
    daysOfTheWeek.forEach((day) => {
      if (day.id === numericalDay) {
        dayOfTheWeek = day.label
      }
    })
    return dayOfTheWeek
  }

  const fieldsDoValidate = (args?: { isDraftValidation?: boolean }) => {
    setFieldValidity(false, args?.isDraftValidation)
    const isFirstSemesterValid = validateSemesterDate()
    const isSecondSemesterValid = validateSemesterDate(false)
    return (
      // We currently care about program type, status, and community to not be undefined before saving a draft
      !!programDetails.type &&
      !!programDetails.communityName &&
      isFirstSemesterValid.input &&
      isFirstSemesterValid.beforeMax &&
      isFirstSemesterValid.afterMin &&
      isSecondSemesterValid.input &&
      isSecondSemesterValid.beforeMax &&
      isSecondSemesterValid.afterMin &&
      isSecondSemesterValid.afterPrevious &&
      // Only need to check one same date, since both will have the same boolean determination
      !isSecondSemesterValid.sameDate &&
      validateTime().input &&
      validateTime(false).input &&
      validateMaxCapacity(args?.isDraftValidation === true) &&
      !!programDetails.director &&
      // If we are saving a draft carry on
      (args?.isDraftValidation === true ||
        // Otherwise check the other fields' existence
        (validateDayOfWeek() &&
          !!address.streetAddress1 &&
          satisfiesFeeMinimum(ProgramFeeNames.ApplicationFee) &&
          satisfiesFeeMinimum(ProgramFeeNames.Tuition) &&
          satisfiesFeeMinimum(ProgramFeeNames.LocalFeeFirstStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.LocalFeeAdditionalStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.FacilityFeeFirstStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.FacilityFeeAdditionalStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.SupplyFeeFirstStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.SupplyFeeAdditionalStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.EnrollmentFee) &&
          satisfiesFeeMinimum(ProgramFeeNames.SubLicensedTutorPercentage) &&
          satisfiesFeeMinimum(ProgramFeeNames.MiscFeeFirstStudent) &&
          satisfiesFeeMinimum(ProgramFeeNames.MiscFeeAdditionalStudent)))
    )
  }

  const performAction = async (options: {
    status: ProgramStatus
    action: Action
  }) => {
    // Set the programId for edit if it's already set
    try {
      const isEdit = options.action === Action.Edit
      let message = ''
      if (
        isEdit &&
        hasEnrollmentInvitesWithInProgressStatus &&
        options.status === ProgramStatus.Draft
      ) {
        message = t(
          'Programs.ProgramForm.ErrorMessage.RevertToDraft',
          'This program can not be reverted to Draft because there are invitations still in progress.'
        )
        throw new Error(message)
      }
      const addUpdateProgramBody: ProgramUpdate = {
        ...programDetails,
        semesterOneStartDate: jsDateFromYearMonthDayString(
          firstSemesterStartDate
        ),
        semesterTwoStartDate: jsDateFromYearMonthDayString(
          secondSemesterStartDate
        ),
        status: options.status,
        startTime: programDetails.startTime as string,
        endTime: programDetails.endTime as string,
        dayOfTheWeek:
          programDetails.dayOfTheWeek === 0
            ? 7
            : programDetails.dayOfTheWeek ?? 7,
        tutorActorIds: [...tutorActorIdMap.values()],
      }
      const createdProgramId = await addUpdateProgram(addUpdateProgramBody)
      if (isEdit) {
        message = t(
          'Programs.ProgramForm.SuccessMessage.UpdateProgram',
          'Program successfully updated.'
        )
      } else {
        message = t(
          'Programs.ProgramForm.SuccessMessage.CreateProgram',
          'Program successfully created.'
        )
      }

      if (!!createdProgramId) {
        setSnackbarContents({
          snackbarMessage: message,
          snackbarSeverity: SnackbarSeverity.Success,
        })
        handleSuccess({
          successType: options.action,
          programId: createdProgramId,
          status: options.status,
        })
      } else {
        const e = {
          code: 'UnknownError',
          message: errorMessage,
        }
        setSnackbarContents({
          snackbarMessage: e.message,
          snackbarSeverity: SnackbarSeverity.Error,
        })
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarContents({
        snackbarMessage: errorObject.message,
        snackbarSeverity: SnackbarSeverity.Error,
      })
    }
  }

  const handleActions = async (
    action: Action,
    status = ProgramStatus.Draft
  ) => {
    status = action === Action.Archived ? ProgramStatus.Archived : status
    if (
      fieldsDoValidate({
        isDraftValidation:
          action === Action.Draft || action === Action.Archived
            ? true
            : action === Action.Edit
            ? status === ProgramStatus.Draft
            : false,
      })
    ) {
      setLoading(true)
      performAction({
        action,
        status,
      })
    } else {
      const e = {
        code: 'UnknownError',
        message: errorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(e.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
    setLoading(false)
  }

  const handleSuccess = (args: {
    successType: Action
    programId: number
    status: ProgramStatus
  }) => {
    setIsFieldDisabled(true)
    setStatus(args.status)
    switch (args.successType) {
      case Action.Publish:
      case Action.Draft:
        navigate({
          pathname: `/communities/community-details/${selectedCommunityKey}/programs/program-details/${args.programId}`,
        })
        break
      case Action.Archived:
        navigate(
          {
            pathname: `/communities/community-details/${selectedCommunityKey}/overview`,
          },
          {
            state: {
              communityKey: selectedCommunityKey,
              name: programDetails.communityName,
            },
          }
        )
        break
      case Action.Edit:
        props.handleSave?.()
        break
    }
  }

  const satisfiesFeeMinimum = useCallback(
    (feeName: ProgramFeeNames) => {
      return fees[feeName] >= (programFeeMinimaMap.get(feeName)?.minimum ?? 0)
    },
    [fees, programFeeMinimaMap]
  )

  const validateSemesterDate = useCallback(
    (isFirstSemester = true): FieldValidity => {
      const isValidFirstSemesterDate = dateUtilities.isValidDateString(
        firstSemesterStartDate
      )
      const isValidSecondSemesterDate = dateUtilities.isValidDateString(
        secondSemesterStartDate
      )
      const isFirstSemesterBeforeSecond =
        dateUtilities.isFirstDateStringBeforeOrAtSecond(
          new Date(firstSemesterStartDate),
          new Date(secondSemesterStartDate)
        )
      const isFirstSemesterInRange = isWithinProgramDateRange(
        firstSemesterStartDate,
        minimumSemesterDate.current,
        maximumSemesterDate.current
      )
      const isSecondSemesterInRange = isWithinProgramDateRange(
        secondSemesterStartDate,
        minimumSemesterDate.current,
        maximumSemesterDate.current
      )
      const sameDate = isSameDate(
        new Date(firstSemesterStartDate),
        new Date(secondSemesterStartDate)
      )

      return isFirstSemester
        ? {
            input: isValidFirstSemesterDate,
            ...isFirstSemesterInRange,
            sameDate,
          }
        : {
            input: isValidSecondSemesterDate,
            afterPrevious: isFirstSemesterBeforeSecond,
            ...isSecondSemesterInRange,
            sameDate,
          }
    },
    [firstSemesterStartDate, secondSemesterStartDate]
  )

  const validateTime = useCallback(
    (isStartTime = true): FieldValidity => {
      const timeRegex = /([0-1][0-9]|2[0-3]):([0-5][0-9])/
      const startTimeIsValid = timeRegex.test(programDetails.startTime ?? '')
      const endTimeIsValid = timeRegex.test(programDetails.endTime ?? '')
      // If start time is invalid, we cannot validate either time
      if (isStartTime) {
        return { input: !!startTimeIsValid }
      }
      // Otherwise verify end time is a valid time
      else if (!isStartTime && endTimeIsValid) {
        const startTimeParts = (programDetails.startTime ?? '').split(':')
        const startTimeAsDate = new Date()
        startTimeAsDate.setHours(parseInt(startTimeParts[0]))
        startTimeAsDate.setMinutes(parseInt(startTimeParts[1]))
        const endTimeParts = (programDetails.endTime ?? '').split(':')
        const endTimeAsDate = new Date()
        endTimeAsDate.setHours(parseInt(endTimeParts[0]))
        endTimeAsDate.setMinutes(parseInt(endTimeParts[1]))
        const endTimeBeforeStartTime =
          startTimeIsValid && endTimeAsDate <= startTimeAsDate

        return {
          input: !!endTimeIsValid,
          afterPrevious: !endTimeBeforeStartTime,
        }
      }

      return { input: false }
    },
    [programDetails.endTime, programDetails.startTime]
  )

  const validateMaxCapacity = useCallback(
    (isDraft?: boolean) => {
      /**
       * If we are saving capacity in draft, we do allow a value of 0.
       * When we are publishing, then capacity must be greater than 0
       * and lower than 1000.
       */
      const maxCapacity = programDetails.capacity || 0
      const isGreaterOrEqualsThanMin = maxCapacity >= 1
      const isLessOrEqualsThanMax = maxCapacity <= 999
      const isValueInRange = isGreaterOrEqualsThanMin && isLessOrEqualsThanMax

      return isDraft ? isLessOrEqualsThanMax : isValueInRange
    },
    [programDetails.capacity]
  )

  const setFieldValidity = useCallback(
    (overrideValue = false, draftValidation = false) => {
      const isValidSemesterOneDate = validateSemesterDate(),
        isValidSemesterTwoDate = validateSemesterDate(false),
        isValidStartTime = validateTime(),
        isValidEndTime = validateTime(false)
      setValidFields({
        // Only care about validating program type and community if draft
        type: { input: !!programDetails.type || overrideValue },
        communityName: {
          input: !!programDetails.communityName || overrideValue,
        },
        semesterOneStartDate: isValidSemesterOneDate || {
          input: overrideValue,
          beforeMax: overrideValue,
          afterMin: overrideValue,
        },
        semesterTwoStartDate: isValidSemesterTwoDate || {
          input: overrideValue,
          beforeMax: overrideValue,
          afterMin: overrideValue,
          afterPrevious: overrideValue,
        },
        startTime: isValidStartTime || { input: overrideValue },
        endTime: isValidEndTime || {
          input: overrideValue,
          afterPrevious: overrideValue,
        },
        dayOfTheWeek: {
          input: validateDayOfWeek() || overrideValue || draftValidation,
        },
        capacity: {
          input: validateMaxCapacity() || overrideValue || draftValidation,
        },
        director: {
          input: !!programDetails.director || overrideValue,
        },
        tutorName: {
          input: !!programDetails.tutorName || overrideValue || draftValidation,
        },
        directorPhone: {
          input: !!programDetails.directorPhone,
        },
        // Validate fees if publishing
        [ProgramFeeNames.ApplicationFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.ApplicationFee),
        },
        [ProgramFeeNames.Tuition]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.Tuition),
        },
        [ProgramFeeNames.EnrollmentFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.EnrollmentFee),
        },
        [ProgramFeeNames.SupplyFeeFirstStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.SupplyFeeFirstStudent),
        },
        [ProgramFeeNames.SupplyFeeAdditionalStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.SupplyFeeAdditionalStudent),
        },
        [ProgramFeeNames.LocalFeeFirstStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.LocalFeeFirstStudent),
        },
        [ProgramFeeNames.LocalFeeAdditionalStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.LocalFeeAdditionalStudent),
        },
        [ProgramFeeNames.FacilityFeeFirstStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.FacilityFeeFirstStudent),
        },
        [ProgramFeeNames.FacilityFeeAdditionalStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.FacilityFeeAdditionalStudent),
        },
        [ProgramFeeNames.MiscFeeFirstStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.MiscFeeFirstStudent),
        },
        [ProgramFeeNames.MiscFeeAdditionalStudent]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.MiscFeeAdditionalStudent),
        },
        [ProgramFeeNames.SubLicensedTutorPercentage]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.SubLicensedTutorPercentage),
        },
        [ProgramFeeNames.SemesterOneLicensingFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.SemesterOneLicensingFee),
        },
        [ProgramFeeNames.DiscountSemesterOneLicensingFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(
              ProgramFeeNames.DiscountSemesterOneLicensingFee
            ),
        },
        [ProgramFeeNames.SemesterTwoLicensingFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.SemesterTwoLicensingFee),
        },
        [ProgramFeeNames.DiscountSemesterTwoLicensingFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(
              ProgramFeeNames.DiscountSemesterTwoLicensingFee
            ),
        },
        [ProgramFeeNames.MultiStudentApplicationDiscount]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(
              ProgramFeeNames.MultiStudentApplicationDiscount
            ),
        },
        [ProgramFeeNames.InvitationFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.InvitationFee),
        },
        [ProgramFeeNames.DiscountInvitationFee]: {
          input:
            !programDetails.communityName ||
            draftValidation ||
            satisfiesFeeMinimum(ProgramFeeNames.DiscountInvitationFee),
        },
      })

      // Otherwise, mark the rest of the fields valid/invalid
      if (!draftValidation) {
        setIsAddressValid(!!address.streetAddress1 || overrideValue)
      } else {
        // Handle case: failed to publish, so saved draft. Set any invalid fields to valid since we don't care.
        setIsAddressValid(true)
      }
    },
    [
      address.streetAddress1,
      programDetails.communityName,
      programDetails.director,
      programDetails.directorPhone,
      programDetails.tutorName,
      programDetails.type,
      satisfiesFeeMinimum,
      validateDayOfWeek,
      validateMaxCapacity,
      validateSemesterDate,
      validateTime,
    ]
  )

  const handleCancel = useCallback(() => {
    setIsFieldDisabled(true)
    setFieldValidity(true)
    resetFields()
    setIsCancel(true)
    // Reset previous program type and community
    setPreviousCommunityId(
      !!props.programDetails
        ? props.programDetails.communityId
        : emptyProgramDetails.communityId
    )
    setPreviousProgramType(
      !!props.programDetails
        ? props.programDetails.type
        : emptyProgramDetails.type
    )
    setTutorFormFields([])
  }, [props.programDetails, resetFields, setFieldValidity])

  /**
   * If we have successfully confirmed a cancellation on edit, complete the actions,
   * otherwise keep the state of the form.
   */
  useEffect(() => {
    if (cancelInitiated) {
      setCancelInitiated(false)
      handleCancel()
    }
  }, [cancelInitiated, setCancelInitiated, handleCancel])

  const editLocation = () => {
    setIsAddressModalOpen(true)
  }

  /** Other Objects */
  const daysOfTheWeek: DropdownSelectOptions[] = [
    {
      id: 0,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Sunday',
        'Sunday'
      )}`,
    },
    {
      id: 1,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Monday',
        'Monday'
      )}`,
    },
    {
      id: 2,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Tuesday',
        'Tuesday'
      )}`,
    },
    {
      id: 3,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Wednesday',
        'Wednesday'
      )}`,
    },
    {
      id: 4,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Thursday',
        'Thursday'
      )}`,
    },
    {
      id: 5,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Friday',
        'Friday'
      )}`,
    },
    {
      id: 6,
      label: `${t(
        'Programs.ProgramFormCard.DropDownMenuOption.Saturday',
        'Saturday'
      )}`,
    },
  ]

  /**
   * This variable is used to display buttons based on program status
   * Save Draft button should show when the program current status is archive or draft
   * */
  const isDraftProgram = status === ProgramStatus.Draft
  const isArchiveProgram = status === ProgramStatus.Archived

  const programStatusLabel = {
    [ProgramStatus.Draft]: t('Card.Status.Draft', 'Draft'),
    [ProgramStatus.Published]: t('Card.Status.Published', 'Published'),
    [ProgramStatus.Complete]: t('Card.Status.Complete', 'Complete'),
    [ProgramStatus.Archived]: t('Card.Status.Archived', 'Archived'),
  }

  const errorMessage = t(
    'Programs.ProgramForm.ErrorMessage',
    'Something went wrong. Please make sure you have filled out the required fields.'
  )

  const addTutorField = () => {
    const maxTutorCapacity = 99
    if (tutorFormFields.length < maxTutorCapacity) {
      const dateTime = new Date().getTime()
      setTutorFormFields([
        ...tutorFormFields,
        <TutorFormField
          tutors={tutorSelectionOptions}
          isDisabled={isTutorFieldDisabled}
          key={`tutorFormFieldKey-${dateTime}`}
          tutorFormKey={`tutorFormKey-${dateTime}`}
          tutorFormId={`tutorFormId-${dateTime}`}
          removeTutorField={removeTutorField}
          onTutorChange={onTutorChange}
          tutorName={''}
        />,
      ])
    }
  }

  const handleEdit = () => {
    setIsFieldDisabled(false)
    setLoadProgramOptions(true)
    setLoading(true)
  }

  /** Boolean determinations */
  const isProgramTypeDisabled =
    isFieldDisabled || isEmpty(programDetails.communityName)
  const isFeeFieldDisabled =
    isProgramTypeDisabled || isEmpty(programDetails.type)

  // RETURNS
  if (
    loadingIds.has(fetchCommunitySpecificProgramOptionsLoadingId) ||
    loadingIds.has(fetchProgramOptionsLoadingId)
  ) {
    return <LoadingProgress />
  }

  if (
    (props.programAbility?.can('edit', 'Program') ||
      props.programAbility?.can('create', 'Program')) &&
    loading &&
    !directors &&
    !tutorOptions
  ) {
    return <LoadingProgress />
  }

  const { hasEnrollment } = props

  return (
    <>
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => {
          setIsAddressModalOpen(false)
        }}
        initialAddress={address}
        onAddressConfirm={(value) => {
          setProgramDetails({
            ...programDetails,
            address: value,
          })
          setAddress(value)
        }}
      />
      <Card
        sx={{
          margin: 'auto',
          minHeight: 200,
          borderTop: `12px solid ${theme.palette.program.challenge}`,
          color: theme.palette.primary.main,
          padding: theme.spacing(3, 4),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
          },
          ...(programDetails.type === 'Foundations' && {
            borderTop: `12px solid ${theme.palette.program.foundations}`,
          }),
          ...(programDetails.type === 'Essentials' && {
            borderTop: `12px solid ${theme.palette.program.essentials}`,
          }),
          ...(programDetails.type === 'Scribblers' && {
            borderTop: `12px solid ${theme.palette.program.scribblers}`,
          }),
        }}
      >
        <section id="programFormCardButtons">
          <CardFormHeader
            header={
              <>
                <div className={programStatusLabel[status]} />
                <Typography
                  variant="overline"
                  component="p"
                  sx={{
                    display: 'inline',
                    color: theme.palette.primary.dark,
                  }}
                >
                  {programStatusLabel[status]}
                </Typography>
              </>
            }
            buttons={
              (props.programAbility?.can('edit', 'Program') ||
                props.programAbility?.can('publish', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <ProgramFormCardButtons
                  isFieldDisabled={isFieldDisabled}
                  isDraftProgram={isDraftProgram}
                  handleEdit={handleEdit}
                  handleCancel={props.handleCancel ?? handleCancel}
                  handleActions={handleActions}
                  useAddProgramVariant={useAddProgramVariant}
                  hasEnrollment={hasEnrollment}
                  isArchiveProgram={isArchiveProgram}
                />
              )
            }
            headerContainerProps={{ margin: 0 }}
          />
        </section>
        <ProgramInformationFields
          programInformationDetails={{
            ...programDetails,
            programType,
            firstSemesterStartDate,
            secondSemesterStartDate,
          }}
          programTypes={programTypes ?? []}
          isFieldDisabled={isFieldDisabled}
          minimumSemesterDate={minimumSemesterDate.current}
          maximumSemesterDate={maximumSemesterDate.current}
          handleInputChange={handleInputChange}
          labelForDayOfTheWeek={labelForDayOfTheWeek}
          validFields={validFields}
          isProgramTypeDisabled={isProgramTypeDisabled}
          daysOfTheWeek={daysOfTheWeek}
          filteredTutorOptions={filteredTutorOptions}
          filteredDirectors={filteredDirectors}
          communitiesMap={communitiesMap}
          useAddProgramVariant={useAddProgramVariant}
          tutors={programDetails.tutors}
          onTutorChange={onTutorChange}
          onTutorRemoveField={onTutorFieldRemove}
          isCancel={isCancel}
          tutorFormFields={tutorFormFields}
          addTutorField={addTutorField}
        />

        <FormDivider />

        <ProgramAddressFields
          address={address}
          isAddressValid={isAddressValid}
          isFieldDisabled={isFieldDisabled}
          editLocation={editLocation}
        />

        <FormDivider />

        <ProgramFeeFields
          fees={fees}
          programFeeMinimaMap={programFeeMinimaMap}
          isFeeFieldDisabled={isFeeFieldDisabled}
          handleInputChange={handleInputChange}
          programAbility={props.programAbility}
          validFields={validFields}
          useAddProgramVariant={useAddProgramVariant}
        />
      </Card>
    </>
  )
}

export default ProgramFormCard
