/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface Program2
 */
export interface Program2 {
  /**
   *
   * @type {number}
   * @memberof Program2
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof Program2
   */
  programType: ProgramType
  /**
   *
   * @type {EnrollmentStatus}
   * @memberof Program2
   */
  enrollmentStatus: EnrollmentStatus
  /**
   * The date the status of the program was acquired.
   * @type {Date}
   * @memberof Program2
   */
  enrollmentStatusValidFrom: Date
}

export function Program2FromJSON(json: any): Program2 {
  return Program2FromJSONTyped(json, false)
}

export function Program2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Program2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    enrollmentStatus: EnrollmentStatusFromJSON(json['enrollmentStatus']),
    enrollmentStatusValidFrom: new Date(json['enrollmentStatusValidFrom']),
  }
}

export function Program2ToJSON(value?: Program2 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    enrollmentStatus: EnrollmentStatusToJSON(value.enrollmentStatus),
    enrollmentStatusValidFrom: value.enrollmentStatusValidFrom.toISOString(),
  }
}
