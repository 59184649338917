import React, { useEffect, useState } from 'react'
import CardFormHeader from '../Card/CardFormHeader'
import { EventFormValidFields, EventLocationTypes } from '../Card/EventFormCard'
import Header, { HeaderVariant } from '../Elements/Header'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Divider,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { GridForm } from '../Card/CommunityFormCard'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { EventFormValidationMessageTypes } from './EventEnums'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import ErrorAlert from '../Alerts/ErrorAlert'
import { Address, Event, Timezones } from '../../swagger'
import useEmailMessage from '../../hooks/useEmailMessage'
import useValidationMessages from '../../hooks/useValidationMessages'
import AddressModal from '../Address/AddressModal'
import { extractedErrorObject, timezones } from '../../api/swagger'
import useLoadingContext from '../../hooks/useLoadingContext'
import { timezoneTitleCase } from '../../utils/timeFormatUtilities'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import { CardContent } from '@mui/material'

export interface EventLocationDetails
  extends Pick<
    Event,
    | 'additionalInformation'
    | 'address'
    | 'contactEmail'
    | 'contactName'
    | 'description'
    | 'endTime'
    | 'hostPhone'
    | 'hostName'
    | 'startTime'
    | 'timezone'
  > {
  endDate: string
  startDate: string
  eventLocationType: string
  registration: string
  registrationCloseDate?: string
}

interface EventLocationFieldsProps {
  eventLocationDetails: EventLocationDetails
  eventFieldValidity: EventFormValidFields
  eventStartDateHelperText: string | null | undefined
  eventEndDateHelperText: string | null | undefined
  handleDateChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => void
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isFieldDisabled: boolean
  isStartTimeGreaterThanEndTime: boolean
  onlineEventUrl: string
  textErrorEmail: string
  onAddressConfirmation: (value: Address) => void
  handleRegistrationCloseDateChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => void
  isRegistrationCloseDateLessThanOrEqualToEndDate: boolean
}

const EventLocationCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  color: theme.palette.primary.main,
}))

const CardContentInformation = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}))

const EventDetailsForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTextField-root': {
      width: `calc(100% - ${theme.spacing(1)})`,
    },
  },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 1),
}))

const LocationSection = styled('section')(({ theme }) => ({
  gridColumn: '1 / 3',
  [theme.breakpoints.up('lg')]: {
    width: '75% !important',
  },
}))

export const EventLocationFields: React.FC<EventLocationFieldsProps> = (
  props
) => {
  const { t } = useTranslation()
  const {
    eventLocationDetails,
    eventFieldValidity,
    eventEndDateHelperText,
    eventStartDateHelperText,
    handleDateChange,
    handleInputChange,
    isFieldDisabled,
    isStartTimeGreaterThanEndTime,
    onlineEventUrl,
    textErrorEmail,
    onAddressConfirmation,
    handleRegistrationCloseDateChange,
    isRegistrationCloseDateLessThanOrEqualToEndDate,
  } = props
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    additionalInformation,
    address,
    contactEmail,
    contactName,
    description,
    endTime,
    eventLocationType,
    startDate,
    endDate,
    hostPhone,
    hostName,
    startTime,
    timezone,
    registration,
    registrationCloseDate,
  } = eventLocationDetails
  const { validationMessages } = useEmailMessage()
  const descriptionMaximumCharacters = 2000
  const additionalInformationMaximumCharacters = 2000
  const validationMessageMap = useValidationMessages([
    {
      field: EventFormValidationMessageTypes.Default,
      message: t(
        'Events.EventLocationField.ErrorMessage',
        'Something went wrong. Please make sure you have filled out the required fields.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EventDescription,
      message: t(
        'Events.EventLocationField.ValidationMessage.EventDescription',
        'Event Description cannot be empty.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EventDescriptionMaxCapacity,
      message: t(
        'Events.EventLocationField.ValidationMessage.EventDescriptionMaxCapacity',
        `Event Description cannot exceed {{descriptionMaximumCharacters}} characters.`,
        { descriptionMaximumCharacters }
      ),
    },
    {
      field:
        EventFormValidationMessageTypes.EventAdditionalInformationMaxCapacity,
      message: t(
        'Events.EventLocationField.ValidationMessage.EventAdditionalInformationMaxCapacity',
        'Additional Information cannot exceed {{additionalInformationMaximumCharacters}} characters.',
        { additionalInformationMaximumCharacters }
      ),
    },

    {
      field: EventFormValidationMessageTypes.OnlineEventUrl,
      message: t(
        'Events.EventLocationField.ValidationMessage.OnlineEventUrl',
        'Online Event Url cannot be blank.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.Location,
      message: t(
        'Events.EventLocationField.ValidationMessage.Location',
        'Location cannot be empty.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.StartTime,
      message: t(
        'Events.EventLocationField.ValidationMessage.StartTime',
        'Please enter a valid Start Time.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EndTime,
      message: t(
        'Events.EventLocationField.ValidationMessage.EndTime',
        'Please enter a valid End Time.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.TimeZone,
      message: t(
        'Events.EventLocationField.ValidationMessage.TimeZone',
        'Please select a Time Zone.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.StartTimeGreaterThanEndTime,
      message: t(
        'Events.EventLocationField.ValidationMessage.StartTimeGreaterThanEndTime',
        'End Time must be after Start Time.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.HostName,
      message: t(
        'Events.EventLocationField.ValidationMessage.HostName',
        'Host Name cannot be empty.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EarlyCloseDate,
      message: t(
        'Events.EventLocationField.ValidationMessage.EarlyCloseDate',
        'Early Close Date must be before or on the end date.'
      ),
    },
  ])
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [timeZones, setTimeZones] = useState<Timezones>({} as Timezones)

  const errorMessageFetchTimezones = t(
    'Events.EventFormCard.FetchTimeZones.ErrorMessage',
    'Something went wrong while loading time zones. Please reload the page'
  )

  const eventRegistrationOptions = [
    t('Events.EventLocationField.EventRegistrationOptions.Open', 'Open'),
    t('Events.EventLocationField.EventRegistrationOptions.Closed', 'Closed'),
  ]

  const statusLabel = t('Events.EventLocationField.AriaLabel.Status', 'Status')

  const fetchTimezones = async () => {
    try {
      const fetchedTimezones = await timezones.fetchTimezones({})
      setTimeZones(fetchedTimezones)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message: (e as unknown as Error).message ?? errorMessageFetchTimezones,
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchTimezones,
    loadingId: availableLoadingIds.Events.fetchTimezones,
  })

  useEffect(() => {
    addLoadingIds([availableLoadingIds.Events.fetchTimezones])
  }, [
    addLoadingIds,
    availableLoadingIds.Events.fetchTimezones,
    errorMessageFetchTimezones,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarState,
  ])

  const timeZoneOptions =
    timeZones?.data?.map((it) => {
      return {
        name: timezoneTitleCase(it.name),
        offset: it.offset,
      }
    }) ?? []

  return (
    <>
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => {
          setIsAddressModalOpen(false)
        }}
        initialAddress={address}
        onAddressConfirm={(value) => onAddressConfirmation(value)}
      />

      <EventLocationCard>
        <CardFormHeader
          header={
            <Header
              headerName={t(
                'Events.AddEvent.Description.Header',
                'Description'
              )}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          headerContainerProps={{
            padding: theme.spacing(1, 2, 0),
          }}
        />
        <CardContentInformation>
          <section aria-labelledby="eventDescriptionFormHeader">
            <EventDetailsForm
              noValidate
              autoComplete="off"
              aria-labelledby="eventDescriptionFormHeader"
            >
              <TextField
                id="standard-multiline-static"
                label={t(
                  'Events.EventLocationField.FormField.EventDescription',
                  'Event Description'
                )}
                multiline
                rows={6}
                variant="filled"
                value={description}
                name="description"
                onChange={handleInputChange}
                disabled={isFieldDisabled}
                error={
                  !eventFieldValidity.description.input ||
                  eventFieldValidity.description.afterMax
                }
                helperText={
                  !eventFieldValidity.description.input
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.EventDescription
                      )
                    : eventFieldValidity.description.afterMax
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.EventDescriptionMaxCapacity
                      )
                    : null
                }
              />
            </EventDetailsForm>
            <StyledDivider />
          </section>
        </CardContentInformation>
        <CardFormHeader
          header={
            <Header
              id="additionalInformationFormHeader"
              headerName={t(
                'Events.AddEvent.additionalInformation.Header',
                'Additional Information'
              )}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          headerContainerProps={{ margin: theme.spacing(0, 4) }}
        />
        <CardContentInformation>
          <section aria-labelledby="additionalInformationFormHeader">
            <EventDetailsForm
              noValidate
              autoComplete="off"
              aria-labelledby="additionalInformationFormHeader"
            >
              <TextField
                id="additional-information"
                label={t(
                  'Events.EventLocationField.FormField.additionalInformation',
                  'Event Additional Information'
                )}
                multiline
                rows={6}
                variant="filled"
                value={additionalInformation}
                name="additionalInformation"
                onChange={handleInputChange}
                disabled={isFieldDisabled}
                error={eventFieldValidity.additionalInformation.afterMax}
                helperText={
                  eventFieldValidity.additionalInformation.afterMax
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.EventAdditionalInformationMaxCapacity
                      )
                    : null
                }
              />
            </EventDetailsForm>
            <StyledDivider />
          </section>
        </CardContentInformation>
        <CardFormHeader
          header={
            <Header
              headerName={t('Events.AddEvent.Location.Header', 'Location')}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          buttons={
            !isFieldDisabled &&
            eventLocationType !== EventLocationTypes.Global && (
              <OutlinedButton
                id="editLocation"
                variant={OutlinedButtonVariant.EditLocation}
                onClick={() => setIsAddressModalOpen(true)}
              />
            )
          }
          menuContainerCss={{
            marginTop: theme.spacing(0),
            marginRight: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
              margin: 0,
            },
          }}
          headerContainerProps={{ margin: theme.spacing(0, 4) }}
        />

        {!eventFieldValidity.address.input && (
          <ErrorAlert
            error={
              validationMessageMap.get(
                EventFormValidationMessageTypes.Location
              ) ?? ''
            }
          />
        )}
        <CardContentInformation>
          <section aria-labelledby="eventLocationFormHeader">
            <EventDetailsForm
              noValidate
              autoComplete="off"
              aria-labelledby="eventLocationFormHeader"
            >
              {eventLocationType !== EventLocationTypes.Global && (
                <GridForm>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t(
                      'Events.EventLocationField.FormField.Address',
                      'Street Address'
                    )}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.StreetAddress1',
                        'Street Address 1'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: '1',
                      gridRow: '1',
                    }}
                    value={address?.streetAddress1 || ''}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t(
                      'Events.EventLocationField.FormField.Address2',
                      'Building, Suite, etc.'
                    )}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.StreetAddress2',
                        'Street Address 2'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: '1',
                      gridRow: '2',
                    }}
                    value={address?.streetAddress2 || ''}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t(
                      'Events.EventLocationField.FormField.City',
                      'City'
                    )}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.City',
                        'City'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: '1',
                      gridRow: '3',
                    }}
                    value={address?.city || ''}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t('Events.EventForm.FormField.Country', 'Country')}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.Country',
                        'Country'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: isMobileOrSmaller ? '1' : '2',
                      gridRow: isMobileOrSmaller ? '4' : '1',
                    }}
                    value={address?.countryCode || ''}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t(
                      'Events.EventForm.FormField.State',
                      'State/Province'
                    )}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.State',
                        'State/Province'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: isMobileOrSmaller ? '1' : '2',
                      gridRow: isMobileOrSmaller ? '5' : '2',
                    }}
                    value={address?.state || ''}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={t(
                      'Events.EventLocationField.FormField.PostalCode',
                      'Postal Code'
                    )}
                    inputProps={{
                      'aria-label': t(
                        'Events.EventLocationField.AriaLabel.PostalCode',
                        'Postal Code'
                      ),
                    }}
                    variant="filled"
                    disabled
                    sx={{
                      gridColumn: isMobileOrSmaller ? '1' : '2',
                      gridRow: isMobileOrSmaller ? '6' : '3',
                    }}
                    value={address?.zip || ''}
                  />
                </GridForm>
              )}
              <LocationSection>
                {eventLocationType !== EventLocationTypes.Global && (
                  <StyledDivider />
                )}
                <TextField
                  id="linkField"
                  label={t(
                    'Events.EventLocationField.FormField.MeetingLink',
                    'Meeting Link'
                  )}
                  inputProps={{
                    'aria-label': t(
                      'Events.EventLocationField.AriaLabel.MeetingLink',
                      'Meeting Link'
                    ),
                  }}
                  fullWidth
                  variant="filled"
                  disabled={isFieldDisabled}
                  value={onlineEventUrl}
                  name="onlineEventUrl"
                  onChange={handleInputChange}
                  error={!eventFieldValidity.onlineEventUrl.input}
                  helperText={
                    !eventFieldValidity.onlineEventUrl.input
                      ? validationMessageMap.get(
                          EventFormValidationMessageTypes.OnlineEventUrl
                        )
                      : null
                  }
                />
              </LocationSection>
            </EventDetailsForm>
          </section>
          <StyledDivider />
        </CardContentInformation>
        <CardFormHeader
          header={
            <Header
              id="eventDateAndTimeFormHeader"
              headerName={t(
                'Events.AddEvent.DateAndTime.Header',
                'Date and Time'
              )}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          headerContainerProps={{ margin: theme.spacing(0, 4) }}
        />
        <CardContentInformation>
          <section aria-labelledby="eventDateAndTimeFormHeader">
            <EventDetailsForm
              noValidate
              autoComplete="off"
              aria-labelledby="eventDateAndTimeFormHeader"
              sx={{
                display: 'grid',
              }}
            >
              <DateField
                dateVariant={DateFieldVariant.EventStartDate}
                type="date"
                disabled={isFieldDisabled}
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                }}
                value={startDate}
                name="startDate"
                onChangeForParent={handleDateChange}
                error={
                  !eventFieldValidity.startDate.input ||
                  !eventFieldValidity.startDate.beforeMax ||
                  !eventFieldValidity.startDate.afterMin
                }
                helperTextForParent={eventStartDateHelperText}
              />
              <DateField
                dateVariant={DateFieldVariant.EventEndDate}
                type="date"
                disabled={isFieldDisabled}
                sx={{
                  gridColumn: '1',
                  gridRow: '2',
                }}
                value={endDate}
                name="endDate"
                onChangeForParent={handleDateChange}
                error={!!eventEndDateHelperText}
                helperTextForParent={eventEndDateHelperText}
              />

              <TextField
                id="startTime"
                label={t(
                  'Events.EventLocationField.FormField.State',
                  'Start Time'
                )}
                inputProps={{
                  'aria-label': t(
                    'Events.EventLocationField.AriaLabel.StartTime',
                    'Start Time'
                  ),
                }}
                variant="filled"
                type="time"
                value={startTime}
                name="startTime"
                onChange={handleInputChange}
                disabled={isFieldDisabled}
                sx={{
                  gridColumn: isMobileOrSmaller ? '1' : '2',
                  gridRow: isMobileOrSmaller ? '3' : '1',
                }}
                error={!eventFieldValidity.startTime.input}
                helperText={
                  !eventFieldValidity.startTime.input
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.StartTime
                      )
                    : null
                }
              />

              <TextField
                id="endTime"
                label={t(
                  'Events.EventLocationField.FormField.PostalCode',
                  'End Time'
                )}
                inputProps={{
                  'aria-label': t(
                    'Events.EventLocationField.AriaLabel.EndTime',
                    'End Time'
                  ),
                }}
                variant="filled"
                type="time"
                value={endTime}
                name="endTime"
                onChange={handleInputChange}
                disabled={isFieldDisabled}
                sx={{
                  gridColumn: isMobileOrSmaller ? '1' : '2',
                  gridRow: isMobileOrSmaller ? '4' : '2',
                }}
                error={
                  !eventFieldValidity.endTime.input ||
                  isStartTimeGreaterThanEndTime
                }
                helperText={
                  !eventFieldValidity.endTime.input
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.EndTime
                      )
                    : isStartTimeGreaterThanEndTime
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.StartTimeGreaterThanEndTime
                      )
                    : null
                }
              />
              <TextField
                label={t('Events.EventForm.FormField.TimeZone', 'Time Zone')}
                inputProps={{
                  'aria-label': t(
                    'Events.EventLocationField.AriaLabel.Timezone',
                    'Timezone'
                  ),
                }}
                variant="filled"
                select={!isFieldDisabled}
                disabled={isFieldDisabled}
                sx={{
                  gridColumn: isMobileOrSmaller ? '1' : '3',
                  gridRow: isMobileOrSmaller ? '5' : '1',
                }}
                value={timeZoneOptions.length > 0 ? timezone : ''}
                name="timezone"
                onChange={handleInputChange}
                error={!eventFieldValidity.timezone.input}
                helperText={
                  !eventFieldValidity.timezone.input
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.TimeZone
                      )
                    : null
                }
              >
                {timeZoneOptions.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </EventDetailsForm>
          </section>
          <StyledDivider />
        </CardContentInformation>
        <CardFormHeader
          header={
            <Header
              id="registrationSectionFormHeader"
              headerName={t(
                'Events.EventLocationField.AddEvent.RegistrationSection.Header',
                'Registration'
              )}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          headerContainerProps={{ margin: theme.spacing(0, 4) }}
        />
        <CardContentInformation>
          <section aria-labelledby="registrationSectionFormHeader">
            <GridForm>
              <TextField
                label={statusLabel}
                inputProps={{
                  'aria-label': statusLabel,
                }}
                sx={{ margin: theme.spacing(1, 1) }}
                variant="filled"
                select={!isFieldDisabled}
                value={registration}
                name="registration"
                onChange={handleInputChange}
                disabled={isFieldDisabled}
                error={!eventFieldValidity.registration.input}
                helperText={
                  !eventFieldValidity.registration.input
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.Registration
                      )
                    : null
                }
              >
                {eventRegistrationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <DateField
                id="earlyCloseDateField"
                inputProps={{
                  'aria-label': t(
                    'Events.EventLocationField.AriaLabel.EarlyCloseDate',
                    'Early Close Date'
                  ),
                }}
                sx={{
                  margin: theme.spacing(1, 1),
                  width: '98%',
                  [theme.breakpoints.down('sm')]: {
                    width: '96% !important',
                  },
                }}
                variant="filled"
                type="date"
                InputLabelProps={{ shrink: true }}
                disabled={isFieldDisabled}
                value={registrationCloseDate ?? ''}
                name="registrationCloseDate"
                error={isRegistrationCloseDateLessThanOrEqualToEndDate}
                onChangeForParent={handleRegistrationCloseDateChange}
                helperTextForParent={
                  isRegistrationCloseDateLessThanOrEqualToEndDate
                    ? validationMessageMap.get(
                        EventFormValidationMessageTypes.EarlyCloseDate
                      )
                    : null
                }
                dateVariant={DateFieldVariant.RegistrationCloseDate}
              />
            </GridForm>
          </section>
        </CardContentInformation>
        <StyledDivider />

        <CardFormHeader
          header={
            <Header
              headerName={t(
                'Events.AddEvent.HostAndContact.Header',
                'Host and Contact Information'
              )}
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          headerContainerProps={{ margin: theme.spacing(0, 4) }}
        />
        <CardContentInformation>
          <section aria-labelledby="eventHostAndContactFormHeader">
            <EventDetailsForm
              noValidate
              autoComplete="off"
              aria-labelledby="eventHostAndContactFormHeader"
            >
              <GridForm>
                <TextField
                  label={t(
                    'Events.EventLocationField.FormField.HostName',
                    'Host Name'
                  )}
                  inputProps={{
                    'aria-label': t(
                      'Events.EventLocationField.AriaLabel.HostName',
                      'Host Name'
                    ),
                  }}
                  variant="filled"
                  value={hostName}
                  name="hostName"
                  onChange={handleInputChange}
                  disabled={isFieldDisabled}
                  error={!eventFieldValidity.hostName.input}
                  helperText={
                    !eventFieldValidity.hostName.input
                      ? validationMessageMap.get(
                          EventFormValidationMessageTypes.HostName
                        )
                      : null
                  }
                />
                <TextField
                  label={t(
                    'Events.EventLocationField.FormField.ContactName',
                    'Contact Name'
                  )}
                  inputProps={{
                    'aria-label': t(
                      'Events.EventLocationField.AriaLabel.ContactName',
                      'Contact Name'
                    ),
                  }}
                  variant="filled"
                  value={contactName}
                  name="contactName"
                  onChange={handleInputChange}
                  disabled={isFieldDisabled}
                />
                <TextField
                  label={t(
                    'Events.EventLocationField.FormField.HostPhone',
                    'Host Phone'
                  )}
                  inputProps={{
                    'aria-label': t(
                      'Events.EventLocationField.AriaLabel.HostPhone',
                      'Host Phone'
                    ),
                  }}
                  variant="filled"
                  value={hostPhone}
                  name="hostPhone"
                  onChange={handleInputChange}
                  disabled={isFieldDisabled}
                />
                <TextField
                  id="emailContact"
                  label={t(
                    'Events.EventLocationField.FormField.ContactEmail',
                    'Contact Email'
                  )}
                  inputProps={{
                    'aria-label': t(
                      'Events.EventLocationField.AriaLabel.ContactEmail',
                      'Contact Email'
                    ),
                  }}
                  variant="filled"
                  value={contactEmail ?? ''}
                  name="contactEmail"
                  onChange={handleInputChange}
                  disabled={isFieldDisabled}
                  error={!eventFieldValidity.contactEmail.input}
                  helperText={validationMessages(textErrorEmail)}
                />
              </GridForm>
            </EventDetailsForm>
          </section>
        </CardContentInformation>
      </EventLocationCard>
    </>
  )
}

export default EventLocationFields
