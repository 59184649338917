/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: d3a1e099 (Tue Oct 29 11:09:40 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  PaymentMethodKey,
  PaymentMethodKeyFromJSON,
  PaymentMethodKeyToJSON,
  PaymentStartRequestBodyFor,
  PaymentStartRequestBodyForFromJSON,
  PaymentStartRequestBodyForToJSON,
} from './'

/**
 *
 * @export
 * @interface PaymentStartRequestBody
 */
export interface PaymentStartRequestBody {
  /**
   *
   * @type {PaymentStartRequestBodyFor}
   * @memberof PaymentStartRequestBody
   */
  _for: PaymentStartRequestBodyFor
  /**
   * The amount being paid. For Licensing payment, can be any value where 0 < value <= totalDue. For Enrollment payment, amount must equal the full amount owed. These restrictions are removed if an admin or CSR is adding manual payments for another user.
   * @type {number}
   * @memberof PaymentStartRequestBody
   */
  amount: number
  /**
   * The amount of tax applied to this payment. This value is ignored if this payment is being made by the requester for their own account, and is calculated separately later in the payment flow. This is utilized by an Admin/CSR to manual enter a payment for another user..
   * @type {number}
   * @memberof PaymentStartRequestBody
   */
  tax?: number
  /**
   *
   * @type {PaymentMethodKey}
   * @memberof PaymentStartRequestBody
   */
  method: PaymentMethodKey
  /**
   * A description stored on a payment that can be utilized during a CSR/Admin manual payment entry, typically to describe the reason for a manual payment/credit entry.
   * @type {string}
   * @memberof PaymentStartRequestBody
   */
  desc?: string
  /**
   * Used only for Admin/CSR manual payment entries. The sales order number provided by Acumatica that pertains to this specific payment.
   * @type {string}
   * @memberof PaymentStartRequestBody
   */
  orderId?: string
}

export function PaymentStartRequestBodyFromJSON(
  json: any
): PaymentStartRequestBody {
  return PaymentStartRequestBodyFromJSONTyped(json, false)
}

export function PaymentStartRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentStartRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    _for: PaymentStartRequestBodyForFromJSON(json['for']),
    amount: json['amount'],
    tax: !exists(json, 'tax') ? undefined : json['tax'],
    method: PaymentMethodKeyFromJSON(json['method']),
    desc: !exists(json, 'desc') ? undefined : json['desc'],
    orderId: !exists(json, 'orderId') ? undefined : json['orderId'],
  }
}

export function PaymentStartRequestBodyToJSON(
  value?: PaymentStartRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    for: PaymentStartRequestBodyForToJSON(value._for),
    amount: value.amount,
    tax: value.tax,
    method: PaymentMethodKeyToJSON(value.method),
    desc: value.desc,
    orderId: value.orderId,
  }
}
